import React, { useRef, useCallback } from "react";
import logo from "../../../assets/images/logo/logo.png";
import logoLion from "../../../assets/images/logo/logo_lion.png";
import { useEffect } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

var JsBarcode = require('jsbarcode');

const TabsetProfile = ({ data, cetakLabel, setCetakLabel }) => {
	const resi = useRef(null);

	useEffect(() => {
		if (cetakLabel) {
			const resi = data.getOrder.no_resi?data.getOrder.no_resi:data.getOrder.shipment_id;
			const test = JsBarcode("#barcode1", resi, { displayValue: false,
				width:1.25,
				height:20, })
			if (test) {
				createLabel();
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cetakLabel])

	const createLabel = useCallback(async () => {
		if (resi.current === null) {
			return
		}
		//exportComponentAsPDF(resi)
		const doc = new jsPDF({
			orientation: "portrait",
			unit: "mm",
			format: [105 , 148],
			quality: 4,
			scale: 5,
			//floatPrecision: 'smart'
		});
		const dataCanvas = await html2canvas(document.querySelector("#label"));
		const img = dataCanvas.toDataURL("image/png");  

		const pdfWidth = doc.internal.pageSize.getWidth();
		const pdfHeight = doc.internal.pageSize.getHeight();

		doc.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
		doc.save(data.getOrder.order_id+' - '+data.getOrder.name+'.pdf');
		setCetakLabel(false);		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resi])
	return (
		<>
			<div id="label" ref={resi} className={`cetak-label ${cetakLabel?'':'hide'}`}>
				{cetakLabel ?
					data ?
						<>
							<div className="top">
								<img className="col-4" src={logo} alt=""/>
								<div className="col-8">No Transaksi : {data.getOrder.order_id}</div>
							</div>
							<div className="header">
								<div className="col-12 header_">
									<img className="col-4" src={logoLion} alt=""/>
									<div className="col-8" >{data.getOrder.shipping_vendor} {data.getOrder.shipping_product}</div>
								</div>
								<div className="col-12 header_barcode">
									<svg id="barcode1"></svg>
									<div>No Resi : <b>{data.getOrder.no_resi?data.getOrder.no_resi:data.getOrder.shipment_id}</b></div>
								</div>
							</div>
							<div className="body">
								<div className="col-12 body1">
									<div className="" >Payment <div className="value">Non-COD</div></div>
									<div className="" >Biaya dibayar pembeli <div className="value">-</div></div>
									<div className="" >Berat Paket<div className="value">{data.getOrder.weight} gram</div></div>
									<div className="" >Asuransi <div className="value">Tidak</div></div>
								</div>
								<div className="col-12 body2">
									<div className="col-6" >Penerima 
									<div className="value">
										<div><b>{data.getOrder.name} - {data.getOrder.phone}</b></div>
										<div>{data.getOrder.detail_address}, {data.getOrder.ref_district_name}, {data.getOrder.ref_city_name}, {data.getOrder.ref_province_name}, {data.getOrder.ref_postcode_name}</div>
									</div>
									</div>
									<div className="col-6" >Pengirim 
									<div className="value">
										<div><b>{data.getOrder.store_name} - {data.getOrder.store_phone}</b></div>
										<div>{data.getOrder.store_city}, {data.getOrder.store_province}</div>
									</div>
									</div>
								</div>
								<div className="body3">
									<div className="body3-1" >Pelapak <b>tidak perlu bayar</b> biaya kirim paket kepada kurir. </div>
									<div className="body3-2" >
										<b>Pembeli</b> mohon videokan proses pembukaan paket agar pengajuan komplain jadi lebih mudah. Jangan lupa untuk simpan	label ini untuk memudahkan klaim asuransi.
									</div>
								</div>
								<div className="body4">
									<div className="body4-1" >Detail Barang </div>
									{data.getOrder.orderList.map(e=>{
										return (
										<div className="body4-2" >
											<div>
											{e.products_title} {e.products_variant_title?'('+e.products_variant_title+')':''}<br/>
											<span>SKU : -</span>
											</div>
											<div>
											{e.qty} Barang
											</div>
										</div>
										)
									})}
									
								</div>
							</div>
						</>
						: ''
					: ''
				}
			</div>
		</>
	);
};

export default TabsetProfile;
