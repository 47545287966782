import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Filter from "../common/filter_components/filter";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


const GET_BANNER = gql`
  query getBanners(
    $page: String!
    $status: String!
  ) {
    getBanners(
      page: $page
      status: $status
    ) {
	  id
	  mobile_src
      src
      page
      title
      description
      link
      button_title
	  status
    }
  }
`;

const columns = [
	{
		dataField: 'id',
		text: '#',
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '5%' };
		}
	},
	{
		dataField: 'image',
		text: 'Image',
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<div role="button">
					<img src={`${process.env.REACT_APP_IMAGE_URL}${row.src}`} alt="" width="150px" />
				</div>
			</div>
		),
	},
	{
		dataField: 'page',
		text: 'Page',
		sort: true,
	},
	{
		dataField: 'title',
		text: 'Title',
		sort: true,
	},
	{
		dataField: 'description',
		text: 'Description',
		sort: true,
	},
	{
		dataField: 'status',
		text: 'Status',
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '12%' };
		}
	},
	{
		dataField: '',
		text: '',
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/banner/edit/${row.id}`} >
					<i className="fa fa-edit" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: '5%' };
		}
	}
];
const Transactions_sales = () => {
	const history = useNavigate();
	const sizePerPage = 10;
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState({
		page: '',
		status: '',
	})
	const [defaultSorted, setDefaultSorted] = useState([{
		dataField: 'id',
		order: 'asc'
	}]);
	useEffect(() => {
		if (localStorage.getItem('user') === 'false' || localStorage.getItem('user') === null) {
			history("/", { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	var { data, refetch } = useQuery(GET_BANNER, {
		variables: {
			page: filter && filter.page ? filter.page.value : '',
			status: filter && filter.status ? filter.status.value : '',
		},
	});

	const filterOptions = [
		{
			id: 'page',
			label: 'Page',
			value: filter.page,
			type: 'dropdown',
			options: [
				{
					label: 'Home',
					value: 'home',
				},
				{
					label: 'Shop',
					value: 'shop',
				}
			]
		},
		{
			id: 'status',
			label: 'Status',
			value: filter.status,
			type: 'dropdown',
			options: [
				{
					label: 'Active',
					value: 'active',
				},
				{
					label: 'Inactive',
					value: 'inactive',
				}
			]
		},
	]

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		if (page) {
			setPage(page)
		}
		if (sortField && sortOrder) {
			setDefaultSorted([{
				dataField: sortField,
				order: sortOrder
			}])
		}
	}
	const handleFilterChange = (e) => {
		refetch({
			page: filter && filter.page ? filter.page.value : '',
			status: filter && filter.status ? filter.status.value : '',
		})
		setFilter(e);
	}
	return (
		<Fragment>
			<Breadcrumb title="Banner" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<div className="btn-popup pull-right">
									<Link to="/banner/edit/0" className="btn btn-primary">
										Add Banner
									</Link>
								</div>
								<div className="clearfix"></div>
								<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} />
								<div id="basicScenario" className="product-physical">
									<BootstrapTable
										keyField='id'
										hover
										condensed
										data={data && data.getBanners ? data.getBanners : []}
										columns={columns}
										bordered={false}
										noDataIndication="Table is Empty"
										pagination={paginationFactory(
											{
												sizePerPage: sizePerPage,
												page: page,
												hideSizePerPage: true,
												hidePageListOnlyOnePage: true,
												totalSize: data && data.getBanners ? data.getBanners.length : 0
											})}
										defaultSorted={defaultSorted}
										onTableChange={handleTableChange}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Transactions_sales;
