import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import ListSaldoWithdrawRequest from "./common/list-saldo-withdraw-request";
import { numberFormat } from "../../helpers/custom";

const GET_SALDO = gql`
  query getSaldoStore(
  	$stores_id:Int!
  ){
    getSaldoStore(
		stores_id:$stores_id
	) {
		total
		withdraw
		withdraw_fee
  		total_pendapatan
    }
  }
`;

const List_vendors = () => {
	const sizePerPage = 10;
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	var { data, refetch } = useQuery(GET_SALDO, {
		variables: {
			stores_id: user.stores_id
		}
	});
	return (
		<Fragment>
			<Breadcrumb title="Saldo" />
			<Container fluid={true}>
				<Card>
					<CardBody>
					<div className="col-md-4" style={{  marginBottom: "1rem" }}>
							<div style={{ display: "flex" }}>
								<div className="col-5" style={{ display: "flex", justifyContent: "space-between" }}>
									<span >Pendapatan bersih</span>
									<span >:</span>
								</div>
								<div className="col" style={{ textAlign: "right" }} >{numberFormat(data?.getSaldoStore.total_pendapatan?parseInt(data.getSaldoStore.total_pendapatan):0)}</div>
							</div>
							<div style={{ display: "flex" }}>
								<div className="col-5" style={{ display: "flex", justifyContent: "space-between" }}>
									<span >Total Withdraw</span>
									<span >:</span>
								</div>
								<div className="col" style={{ textAlign: "right" }} >{numberFormat(data?.getSaldoStore.withdraw?parseInt(data.getSaldoStore.withdraw):0)}</div>
							</div>
							<div style={{ display: "flex" }}>
								<div className="col-5" style={{ display: "flex", justifyContent: "space-between" }}>
									<span >Withdraw Fee</span>
									<span >:</span>
								</div>
								<div className="col" style={{ textAlign: "right" }} >{numberFormat(data?.getSaldoStore.withdraw_fee?parseInt(data.getSaldoStore.withdraw_fee):0)}</div>
							</div>
							<div style={{ display: "flex" }}>
								<div className="col-5" style={{ display: "flex", justifyContent: "space-between" }}>
									<span >Saldo</span>
									<span >:</span>
								</div>
								<div className="col" style={{ textAlign: "right" }} ><b>{numberFormat(data?.getSaldoStore.total?parseInt(data.getSaldoStore.total):0)}</b></div>
							</div>
						</div>
						<Link to="/saldo/mutasi" className="btn btn-primary">
							Check Mutasi
						</Link>
					</CardBody>
				</Card>
			</Container>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<ListSaldoWithdrawRequest stores_id={user.stores_id} />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_vendors;
