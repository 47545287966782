import React, { Fragment } from "react";
import TabsetUser from "./common/form-user";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useQuery, gql } from "@apollo/client";

const GET_USER = gql`
  query ($id:Int){
    getUser(id:$id){
    		id
    		email
    		phone
    		name
			privilege
			stores_id
			stores_name
			stores_slug
			status
			created_date
  	}
  }
`;

const Create_vendors = () => {
	const user = JSON.parse(localStorage.getItem('user'));
	var { data } = useQuery(GET_USER, {
		variables: {
			id: parseInt(user.id)
		}
	});
	return (
		<Fragment>
			<Breadcrumb title="Ubah Profil" parent="Profil" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TabsetUser data={data?data.getUser:''} id={user.id} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Create_vendors;
