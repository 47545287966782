import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Media, Table } from "reactstrap";
import designer from "../../../assets/images/dashboard/designer.jpg";
const TabsetProfile = ({ data, slug }) => {
	const [totalTransaksi, setTotalTransaksi] = useState(0);
	const [totalTransaksiPaid, setTotalTransaksiPaid] = useState(0);
	const [totalTransaksiOtw, setTotalTransaksiOtw] = useState(0);
	const [totalTransaksiFinish, setTotalTransaksiFinish] = useState(0);

	useEffect(() => {
		if (data && data.store) {
			const totalTransaksi = data.store.total_transaction_paid + data.store.total_transaction_otw + data.store.total_transaction_finish;
			setTotalTransaksi(totalTransaksi);
			const totalTransaksiPaid = data.store.total_transaction_paid > 0 ? (data.store.total_transaction_paid / totalTransaksi) * 100 : 0;
			setTotalTransaksiPaid(totalTransaksiPaid + '%');
			const totalTransaksiOtw = data.store.total_transaction_otw > 0 ? (data.store.total_transaction_otw / totalTransaksi) * 100 : 0;
			setTotalTransaksiOtw(totalTransaksiOtw + '%');
			const totalTransaksiFinish = data.store.total_transaction_finish > 0 ? (data.store.total_transaction_finish / totalTransaksi) * 100 : 0;
			setTotalTransaksiFinish(totalTransaksiFinish + '%');
		}
	}, [data])

	return (
		<>
			<Col xl="4">
				{data && data.store ?
					<Card>
						<CardBody>
							<div className="profile-details text-center">
								<img
									src={data.store.image ? process.env.REACT_APP_IMAGE_URL + data.store.image : designer}
									alt=""
									className="img-fluid img-90 rounded-circle blur-up lazyloaded"
								/>
								<h5 className="f-w-600 f-16 mb-0">{data.store.name}</h5>
								<span>{data.store.ref_cities_list_name}, {data.store.ref_province_list_name}</span>
							</div>
							<hr />
							<div className="project-status">
								<h5 className="f-w-600 f-16">Rangkuman</h5>
								<h6>
									Jumlah Produk <span className="pull-right">{data.store.total_product}</span>
								</h6>
								<h6>
									Jumlah Transaksi <span className="pull-right">{totalTransaksi}</span>
								</h6>
								<Media>
									<Media body>
										<h6>
											Transaksi Di Bayar <span className="pull-right">{totalTransaksiPaid}</span>
										</h6>
										<div className="progress sm-progress-bar">
											<div
												className="progress-bar bg-secondary"
												role="progressbar"
												style={{ width: `${totalTransaksiPaid}` }}
												aria-valuenow="90"
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
									</Media>
								</Media><Media>
									<Media body>
										<h6>
											Transaksi Sedang Di Kirim <span className="pull-right">{totalTransaksiOtw}</span>
										</h6>
										<div className="progress sm-progress-bar">
											<div
												className="progress-bar bg-secondary"
												role="progressbar"
												style={{ width: `${totalTransaksiOtw}` }}
												aria-valuenow="25"
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
									</Media>
								</Media>
								<Media>
									<Media body>
										<h6>
											Transaksi Selesai <span className="pull-right">{totalTransaksiFinish}</span>
										</h6>
										<div className="progress sm-progress-bar">
											<div
												className="progress-bar bg-secondary"
												role="progressbar"
												style={{ width: `${totalTransaksiFinish}` }}
												aria-valuenow="25"
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
									</Media>
								</Media>
							</div>
						</CardBody>
					</Card>
					: ''}
			</Col>
			<Col xl="8">
				{data && data.store ?
					<Card className="profile-card">
						<CardBody>
							<div>
								<Tabs>
									<TabList className="nav nav-tabs tab-coupon">
										<Tab className="nav-link">
											<User className="me-2" />
											Profil Toko
										</Tab>
									</TabList>

									<TabPanel>
										<div className="tab-pane fade show active">
											<div className="table-responsive profile-table">
												<Table className="table-responsive">
													<tbody>
														<tr>
															<td>Deskripsi:</td>
															<td>{data.store.description}</td>
														</tr>
														<tr>
															<td>Email:</td>
															<td>{data.store.email}</td>
														</tr>
														<tr>
															<td>Telp:</td>
															<td>{data.store.phone}</td>
														</tr>
														<tr>
															<td>Alamat:</td>
															<td>{data.store.address}<br />{data.store.ref_district_name}, {data.store.ref_postcode_name}</td>
														</tr>
														<tr>
															<td>Rekening:</td>
															<td>{data.store.ref_bank_name} - {data.store.no_rekening} a/n {data.store.nama_rekening}</td>
														</tr>
														<tr>
															<td>Tanggal Pembuatan:</td>
															<td>{data.store.created_date}</td>
														</tr>
														<tr>
															<td>
																<Link to={`/toko/form/${data.store.slug}`} className="btn btn-primary">
																	Edit
																</Link>
															</td>
														</tr>
													</tbody>
												</Table>
											</div>
										</div>
									</TabPanel>
								</Tabs>
							</div>

						</CardBody>
					</Card>
					: ''}
			</Col>
		</>
	);
};

export default TabsetProfile;
