import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Filter from "../common/filter_components/filter";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const GET_USERS = gql`
  query getUsers($indexFrom: Int, $limit: Int,$name: String, $email: String, $privilege: String, $stores_name: String, $status: String, $sortBy: String){
    getUsers(indexFrom: $indexFrom, limit: $limit, name: $name, email: $email, privilege: $privilege, stores_name: $stores_name, status: $status, sortBy: $sortBy){
		hasMore
		total
		items{
    		id
    		email
    		phone
    		name
			privilege
			stores_id
			stores_name
			stores_slug
			status
			created_date
		}
  	}
  }
`;

const Users = () => {
	const sizePerPage = 10;
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState({
		name: '',
		phone: '',
		lokasi: '',
		status: '',
	})
	const user = JSON.parse(localStorage.getItem('user'));
	const [sortBy, setSortBy] = useState();
	const [defaultSorted, setDefaultSorted] = useState([{
		dataField: 'id',
		order: 'desc'
	}]);
	var { data, refetch } = useQuery(GET_USERS, {
		variables: {
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			email: filter && filter.email ? filter.email : '',
			name: filter && filter.name ? filter.name : '',
			privilege: filter && filter.privilege ? filter.privilege.value : '',
			stores_name: filter && filter.stores_name ? filter.stores_name : '',
			status: filter && filter.status ? filter.status.value : '',
			sortBy: sortBy,
		}
	});

	const columns = [
		{
			dataField: 'id',
			text: '#',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '5%' };
			}
		},
		{
			dataField: 'name',
			text: 'Nama',
			sort: true,
		},
		{
			dataField: 'email',
			text: 'Email',
			sort: true,
		},
		{
			dataField: 'privilege',
			text: 'Privilege',
			sort: true,
		},
		{
			dataField: 'stores_name',
			text: 'Toko',
			sort: true,
		},
		{
			dataField: 'status',
			text: 'Status',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '12%' };
			}
		},
		{
			dataField: '',
			text: '',
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center">
					<Link to={`/users/form/${row.id}`} role="button">
						<i className="fa fa-edit" title="Ubah" />
					</Link>
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: '5%' };
			}
		}
	];

	const filterOptions = [
		{
			id: 'name',
			label: 'Nama',
			value: filter.name,
			type: 'text',
		},
		{
			id: 'email',
			label: 'Email',
			value: filter.email,
			type: 'text',
		},
		{
			id: 'privilege',
			label: 'Privilege',
			value: filter.privilege,
			type: 'dropdown',
			options: [
				{
					label: 'Admin',
					value: 'Admin',
				},
				{
					label: 'Toko',
					value: 'Stores',
				}
			]
		},
		{
			id: 'stores_name',
			label: 'Store',
			value: filter.stores_name,
			type: 'text',
		},
		{
			id: 'status',
			label: 'Status',
			value: filter.status,
			type: 'dropdown',
			options: [
				{
					label: 'Active',
					value: 'active',
				},
				{
					label: 'Inactive',
					value: 'inactive',
				}
			]
		},
	]

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		if (page) {
			setPage(page)
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + '-' + sortOrder)
			setDefaultSorted([{
				dataField: sortField,
				order: sortOrder
			}])
		}
	}
	const handleFilterChange = (e) => {
		refetch({
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			email: filter && filter.email ? filter.email : '',
			name: filter && filter.name ? filter.name : '',
			privilege: filter && filter.privilege ? filter.privilege.value : '',
			stores_name: filter && filter.stores_name ? filter.stores_name : '',
			status: filter && filter.status ? filter.status.value : '',
			sortBy: sortBy,
		})
		setFilter(e);
	}


	return (
		<Fragment>
			<Breadcrumb title="Admin" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<div className="btn-popup pull-right">
									<Link
										to="/users/form/0"
										className="btn btn-primary"
									>
										Tambah User
									</Link>
								</div>
								<div className="clearfix"></div>
								<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} />
								<div id="basicScenario" className="product-physical">
									<BootstrapTable
										keyField='id'
										hover
										condensed
										remote={{ sort: true, filter: true, pagination: true }}
										data={data && data.getUsers ? data.getUsers.items : []}
										columns={columns}
										bordered={false}
										noDataIndication="Table is Empty"
										pagination={paginationFactory(
											{
												sizePerPage: sizePerPage,
												page: page,
												hideSizePerPage: true,
												hidePageListOnlyOnePage: true,
												totalSize: data && data.getUsers ? data.getUsers.total : 0
											})}
										defaultSorted={defaultSorted}
										onTableChange={handleTableChange}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Users;
