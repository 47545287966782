import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import ListDetailIncome from "./common/listDetailIncome";
import { numberFormat } from "../../../helpers/custom";
import moment from 'moment';

const List_vendors = () => {
	let { periode } = useParams();
	const sizePerPage = 10;
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	
	return (
		<Fragment>
			<Breadcrumb parent="Income" title={`Income - ${moment(periode,'MM/YYYY').format("MMM YY")}`} />
			
			<Container fluid={true}>
				<Card>
					<CardBody>
						<ListDetailIncome periode={periode}/>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_vendors;
