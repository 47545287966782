import React, { useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { numberFormat,orderStatus } from "../../../helpers/custom";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

const GET_SALDO_MUTASI = gql`
  query getSaldoHistoryStore(
    $stores_id: Int!
  ) {
    getSaldoHistoryStore(
		stores_id: $stores_id
    ) {
		total
  		hasMore
  		items{
			date
			notes
			debit
			kredit
		}
    }
  }
`;

const columns = [
	{
		dataField: 'date',
		text: 'Date',
	},
	{
		dataField: 'notes',
		text: 'Order ID / Withdraw ID',
	},
	{
		dataField: 'debit',
		text: 'Debit',
		formatter: (cell, row, rowIndex, extraData) => (
			parseInt(row.debit) >= 0  ? numberFormat(parseInt(row.debit)) : ''
		),
	},
	{
		dataField: 'kredit',
		text: 'Kredit',
		formatter: (cell, row, rowIndex, extraData) => (
			parseInt(row.kredit) >= 0  ? numberFormat(parseInt(row.kredit)) : ''
		),

	},
];

const defaultSorted = [{
	dataField: 'id',
	order: 'desc'
}];

const List_vendors = ({ slug, id,sisa_saldo }) => {
	const is_mobile = window.innerWidth < 750 ? true : false;
	const sizePerPage = 10;
	var { data, refetch } = useQuery(GET_SALDO_MUTASI, {
		variables: {
			stores_id: parseInt(id),
		},
	});

	useEffect(() => {
		if(id){
		refetch({
			stores_id: id,
		})
	}
	}, [id])
	return (
		<>
			<div
				className="category-table order-list order-table coupon-list-delete"
			>
			<h4><b>History Saldo</b></h4>
			<h5>Sisa Saldo : {numberFormat(parseInt(sisa_saldo))}</h5>
				{data ? <BootstrapTable
					keyField='id'
					hover
					condensed
					data={data && data.getSaldoHistoryStore ? data.getSaldoHistoryStore.items : []}
					columns={columns}
					bordered={false}
					noDataIndication="Table is Empty"
					pagination={paginationFactory(
						{
							sizePerPage: sizePerPage,
							hideSizePerPage: true,
							hidePageListOnlyOnePage: true,
							totalSize: data && data.getSaldoHistoryStore ? data.getSaldoHistoryStore.total : 0
						})}
					wrapperClasses="table-responsive"
				/> : ''}
			</div>
		</>
	);
};

export default List_vendors;
