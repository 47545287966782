import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Filter from "../common/filter_components/filter";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const GET_STORE = gql`
  query getStoreRegistrations(
  	$store_name:String
	$email:String
    $phone: String
    $city: String
    $status: String
    $indexFrom: Int!
    $limit: Int!
  ){
    getStoreRegistrations(
		store_name:$store_name
		email: $email
		phone: $phone
		city:$city
		status: $status
		indexFrom: $indexFrom
		limit: $limit
	) {
		hasMore
		total
		items{
			id
			store_name
			user_name
			email
			phone
			store_description
			product_description
			ref_province_id
			ref_province_name
			ref_city_id
			ref_city_name
			ref_district_id
			ref_district_name
			ref_postcode_id
			ref_postcode_name
			detail_address
			geoloc
			reject_date
			reject_reason
			reject_by
			approve_by
			approve_date
			created_date
	  	}
    }
  }
`;

const columns = [
	{
		dataField: 'id',
		text: '#',
		sort: true,
	},
	{
		dataField: 'store_name',
		text: 'Nama Toko',
		sort: true,

	},
	{
		dataField: 'email',
		text: 'Email',
		sort: true,
	},
	{
		dataField: 'ref_city_name',
		text: 'Lokasi',
		sort: true,
	},
	{
		dataField: 'status',
		text: 'Status',
		sort: true,
		formatter: (cell, row, rowIndex, extraData) => (
			row.reject_date?
			'Di Tolak'
			:
			row.approve_date?
			'Di Terima'
			:'Pending'
			
		),
	},
	{
		dataField: '',
		text: '',
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/pendaftaran-toko/${row.id}`} >
					<i className="fa fa-eye" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: '13%' };
		}
	}
];

const List_vendors = () => {
	const sizePerPage = 10;
	let navigate = useNavigate();
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState({
		name: '',
		phone: '',
		city: '',
		status: '',
	})
	const user = JSON.parse(localStorage.getItem('user'));
	const [sortBy, setSortBy] = useState();
	const [defaultSorted, setDefaultSorted] = useState([{
		dataField: 'id',
		order: 'desc'
	}]);
	useEffect(() => {
		if (!user || user.privilege === 'Stores') {
			return navigate("/dashboard", { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	var { data, refetch } = useQuery(GET_STORE, {
		variables: {
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			city: filter && filter.city ? filter.city : '',
			store_name: filter && filter.store_name ? filter.store_name : '',
			email: filter && filter.email ? filter.email : '',
			status: filter && filter.status ? filter.status.value : '',
		}
	});

	const filterOptions = [
		{
			id: 'store_name',
			label: 'Nama Toko',
			value: filter.store_name,
			type: 'text',
		},
		{
			id: 'email',
			label: 'Email',
			value: filter.email,
			type: 'text',
		},
		{
			id: 'city',
			label: 'Lokasi',
			value: filter.city,
			type: 'text',
		},
		{
			id: 'status',
			label: 'Status',
			value: filter.status,
			type: 'dropdown',
			options: [
				{
					label: 'Di Terima',
					value: 'approve',
				},
				{
					label: 'Di Tolak',
					value: 'reject',
				},
				{
					label: 'Pending',
					value: 'pending',
				}
			]
		},
	]

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		if (page) {
			setPage(page)
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + '-' + sortOrder)
			setDefaultSorted([{
				dataField: sortField,
				order: sortOrder
			}])
		}
	}
	const handleFilterChange = (e) => {
		refetch({
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			city: filter && filter.city ? filter.city : '',
			store_name: filter && filter.store_name ? filter.store_name : '',
			email: filter && filter.email ? filter.email : '',
			status: filter && filter.status ? filter.status.value : '',
		})
		setFilter(e);
	}
	return (
		<Fragment>
			<Breadcrumb title="Pendaftaran Toko" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} />
						<div
							id="batchDelete"
							className="category-table store-list order-table coupon-list-delete"
						>
							{data ?
								<BootstrapTable
									keyField='id'
									hover
									condensed
									remote={{ sort: true, pagination: true }}
									data={data && data.getStoreRegistrations ? data.getStoreRegistrations.items : []}
									columns={columns}
									bordered={false}
									noDataIndication="Table is Empty"
									pagination={paginationFactory(
										{
											sizePerPage: sizePerPage,
											page: page,
											hideSizePerPage: true,
											hidePageListOnlyOnePage: true,
											totalSize: data && data.getStoreRegistrations ? data.getStoreRegistrations.total : 0
										})}
									defaultSorted={defaultSorted}
									onTableChange={handleTableChange}
									wrapperClasses="table-responsive"
								/>
								: ''}
						</div>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_vendors;
