import {
    Home,
    Box,
    Tag,
    Archive,
} from 'react-feather';

export const MENUSTOREITEMS = [
    {
        path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Produk', icon: Box, path: '/produk', type: 'link', active: false, privilege:'Store'
    },
    {
        title: 'Order', icon: Box, path: '/order', type: 'link', active: false, privilege:'Store'
    },
    {
        path: '/saldo', title: 'Balance Withdrawal', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
    /*{
        title: 'Coupons', icon: Tag, type: 'sub', active: false, children: [
            { path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
            { path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
        ]
    },*/
    /*{
        title: 'Invoice', path: '/invoice', icon: Archive, type: 'link', active: false
    }*/
]
