import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Media, Table } from "reactstrap";
import { useQuery, gql } from "@apollo/client";

const GET_CUSTOMER = gql`
  query getCustomer(
    $id: Int!
  ) {
    getCustomer(
		id: $id
    ) {
		id
		name
		email
		phone
		address
		city
		verified
		created_date
    }
  }
`;

const TabsetProfile = ({ id }) => {
	const [totalTransaksi, setTotalTransaksi] = useState(0);
	const [totalTransaksiPaid, setTotalTransaksiPaid] = useState(0);
	const [totalTransaksiOtw, setTotalTransaksiOtw] = useState(0);
	const [totalTransaksiFinish, setTotalTransaksiFinish] = useState(0);
	var { data } = useQuery(GET_CUSTOMER, {
		variables: {
			id: parseInt(id),
		},
	});

	useEffect(() => {
		if (data && data.store) {
			const totalTransaksi = data.store.total_transaction_paid + data.store.total_transaction_otw + data.store.total_transaction_finish;
			setTotalTransaksi(totalTransaksi);
			const totalTransaksiPaid = data.store.total_transaction_paid > 0 ? (data.store.total_transaction_paid / totalTransaksi) * 100 : 0;
			setTotalTransaksiPaid(totalTransaksiPaid + '%');
			const totalTransaksiOtw = data.store.total_transaction_otw > 0 ? (data.store.total_transaction_otw / totalTransaksi) * 100 : 0;
			setTotalTransaksiOtw(totalTransaksiOtw + '%');
			const totalTransaksiFinish = data.store.total_transaction_finish > 0 ? (data.store.total_transaction_finish / totalTransaksi) * 100 : 0;
			setTotalTransaksiFinish(totalTransaksiFinish + '%');
		}
	}, [data])

	return (
		<>
			<Col xl="12">
				{data && data.getCustomer ?
					<Card className="profile-card">
						<CardBody>
							<div>
								<Tabs>
									<TabList className="nav nav-tabs tab-coupon">
										<Tab className="nav-link">
											<User className="me-2" />
											Profil Customer
										</Tab>
									</TabList>

									<TabPanel>
										<div className="tab-pane fade show active">
											<div className="table-responsive profile-table">
												<Table className="table-responsive">
													<tbody>
														<tr>
															<td>Nama:</td>
															<td>{data.getCustomer.name}</td>
														</tr>
														<tr>
															<td>Email:</td>
															<td>{data.getCustomer.email}</td>
														</tr>
														<tr>
															<td>Telp:</td>
															<td>{data.getCustomer.phone}</td>
														</tr>
														<tr>
															<td>Tanggal Pembuatan:</td>
															<td>{data.getCustomer.created_date}</td>
														</tr>
														<tr>
															<td>
																<Link to={`/customer/form/${data.getCustomer.id}`} className="btn btn-primary">
																	Edit
																</Link>
															</td>
														</tr>
													</tbody>
												</Table>
											</div>
										</div>
									</TabPanel>
								</Tabs>
							</div>

						</CardBody>
					</Card>
					: ''}
			</Col>
		</>
	);
};

export default TabsetProfile;
