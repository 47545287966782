import React, { Fragment, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TabsetProfile from "./common/detail-customer-info";
import TabsetOrder from "./common/detail-customer-order";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";


const TokoDetail = () => {
	let { id } = useParams();
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	useEffect(() => {
		if (!user || user.privilege === 'Stores') {
		return navigate("/dashboard", { replace: true });
	}
	}, [])
	return (
		<Fragment>
			<Breadcrumb title="Customer Detail" parent="Customer" />
			<Container fluid={true}>
				<Row>
					<TabsetProfile id={id}/>
					<Col xl="12">
						<Card className="toko-detail-card">
							<CardBody>
								<TabsetOrder id={id} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default TokoDetail;
