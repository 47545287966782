import React, { useEffect, useState } from "react";
import Select from 'react-select'

const Breadcrumb = ({ options, filter, handleFilterChange, cssClass }) => {
    const [value, setValue] = useState();
    const [optionsList, setOptionsList] = useState([]);
    useEffect(() => {
        setValue(filter[options.id])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filter)]);

    useEffect(() => {
        if (options && options.options) {
            let options_ = []
            options.options.forEach(e => {
                options_.push({
                    label: e.label,
                    value: e.value,
                })
            })
            setOptionsList(options_)
        }
    }, [options])

    const handleChange = (e) => {
        let filters = filter;
        filter[options.id] = e;
        setValue(e)
        handleFilterChange(filters)
    }
    return (
        <div className={cssClass} >
            <Select
                value={value}
                classNamePrefix="selectFilter"
                placeholder={options.label}
                id={options.id}
                name={options.id}
                onChange={handleChange}
                options={optionsList}
    />
        </div>
    );
};

export default Breadcrumb;
