import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery, gql } from "@apollo/client";

const GET_SUMMARY_TRANSACTION = gql`
  query (
    $stores_id: Int
    $limit: Int
    $type: String
  ) {
    getChartSummaryTransaction(
		stores_id: $stores_id
		limit: $limit
		type: $type
    ) {
		label
        value{
            total
            value
        }
    }
  }
`;

const footer = (tooltipItems) => {
    const context = tooltipItems[0];
    let label = 'Total Orders :' + context.raw.value.total
    return label
        ;
};
export const buyOption = {
    maintainAspectRatio: false,
    locale: 'id',
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
        },

        tooltip: {
            enabled: true,
            callbacks: {
                afterBody: footer
            }
        },
    },
    scales: {
        xAxes: {
            gridLines: {
                color: '#f8f8f8'
            },
        },
        yAxes: {
            gridLines: {
                color: '#f8f8f8'
            },

        },
    },
}

const OrderSuccessCanceled = () => {
    const [limit, setLimit] = useState(6)
    var { data } = useQuery(GET_SUMMARY_TRANSACTION, {
        variables: {
            limit: limit,
            type:'income'
        },
    });

    const buyData = {
        datasets: [
            {
                label: 'Income',
                backgroundColor: "#13c9ca",
                borderColor: "#13c9ca",
                data: data && data.getChartSummaryTransaction ? data.getChartSummaryTransaction : [],
                lineTension: 0.4,
                parsing: {
                    xAxisKey: 'label',
                    yAxisKey: 'value.value'
                }
            },
        ],
    };

    return (
        <div >
            <Line
                data={buyData}
                options={buyOption}
                width={700}
                height={250}
            />
        </div>
    );
};

export default OrderSuccessCanceled;
