import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Filter from "../common/filter_components/filter";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import ModalSubcategory from "./common/modal-subcategory"
import { useQuery, gql } from "@apollo/client";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const GET_SUBCATEGORY = gql`
  query (
    $category: String
    $title: String
    $status: String
  ) {
  	getSubcategory(
		category: $category
		title: $title
		status: $status
	) {
		subcategory{
			id
			title
			products_category_id
			products_category_title
			status
		}
    }
  }
`;

const GET_CATEGORY = gql`
  query (
    $status: String
  ) {
    getCategory(
		status: $status
	) {
		category{
			id
			title
			status
		}
  	}
  }
`;

const Subcategory = () => {
	const sizePerPage = 10;
	const [page, setPage] = useState(1)
	const [open, setOpen] = useState(false);
	const user = JSON.parse(localStorage.getItem('user'));
	const [editData, setEditData] = useState('');

	const [filter, setFilter] = useState({
		category: '',
		title: '',
		status: '',
	})
	const [defaultSorted, setDefaultSorted] = useState([{
		dataField: 'id',
		order: 'desc'
	}]);

	var { data, refetch } = useQuery(GET_SUBCATEGORY, {
		variables: {
			category: filter && filter.category ? filter.category : '',
			title: filter && filter.title ? filter.title : '',
			status: filter && filter.status ? filter.status.value : '',
		},
	});

	var { data: categoryData } = useQuery(GET_CATEGORY, {
		variables: {
			status: 'active',
		},
	});
	
	const columns = [
		{
			dataField: 'id',
			text: '#',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '5%' };
			}
		},
		{
			dataField: 'products_category_title',
			text: 'Kategori',
			sort: true,
		},
		{
			dataField: 'title',
			text: 'Subkategori',
			sort: true,
		},
		{
			dataField: 'status',
			text: 'Status',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '12%' };
			}
		},
		{
			dataField: '',
			text: '',
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center">
					<div role="button">
						<i className="fa fa-edit" title="Ubah" onClick={()=>handleSetEditData(row)} />
					</div>
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: '5%' };
			}
		}
	];
	const onOpenModal = () => {
		setOpen(true);
	};

	const handleSetEditData = (e) => {
		setEditData(e);
		setOpen(true);
	};

	const filterOptions = [
		{
			id: 'category',
			label: 'Category',
			value: filter.category,
			type: 'text',
		},
		{
			id: 'title',
			label: 'Subcategory',
			value: filter.title,
			type: 'text',
		},
		{
			id: 'status',
			label: 'Status',
			value: filter.status,
			type: 'dropdown',
			options: [
				{
					label: 'Active',
					value: 'active',
				},
				{
					label: 'Inactive',
					value: 'inactive',
				}
			]
		},
	]

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		if (page) {
			setPage(page)
		}
		if (sortField && sortOrder) {
			setDefaultSorted([{
				dataField: sortField,
				order: sortOrder
			}])
		}
	}
	const handleFilterChange = (e) => {
		refetch({
			category: filter && filter.category ? filter.category : '',
			title: filter && filter.title ? filter.title : '',
			status: filter && filter.status ? filter.status.value : '',
		})
		setFilter(e);
	}


	return (
		<Fragment>
			<Breadcrumb title="Produk Subkategori" />
			{/* <!-- Container-fluid starts--> */}
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<div className="btn-popup pull-right">
									<Button
										type="button"
										color="primary"
										onClick={onOpenModal}
										data-toggle="modal"
										data-original-title="test"
										data-target="#exampleModal"
									>
										Tambah Subkategori
									</Button>
									<ModalSubcategory open={open} setOpen={setOpen} refetch={refetch} user={user} categoryData={categoryData} editData={editData} />
								</div>
								<div className="clearfix"></div>
								<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} />
								<div id="basicScenario" className="product-physical">
									<BootstrapTable
										keyField='id'
										hover
										condensed
										data={data && data.getSubcategory ? data.getSubcategory.subcategory : []}
										columns={columns}
										bordered={false}
										noDataIndication="Table is Empty"
										pagination={paginationFactory(
											{
												sizePerPage: sizePerPage,
												page: page,
												hideSizePerPage: true,
												hidePageListOnlyOnePage: true,
												totalSize: data && data.getSubcategory ? data.getSubcategory.subcategory.length : 0
											})}
										defaultSorted={defaultSorted}
										onTableChange={handleTableChange}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* <!-- Container-fluid Ends--> */}
		</Fragment>
	);
};

export default Subcategory;
