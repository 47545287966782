/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TabsetProfile from "./common/detail-toko-info";
import TabsetSaldo from "./common/detail-toko-saldo";
import TabsetProduct from "./common/detail-toko-product";
import TabsetOrder from "./common/detail-toko-order";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { useQuery, gql } from "@apollo/client";

const GET_STORE = gql`
  query store(
    $slug: String!
  ) {
    store(
		slug: $slug
    ) {
		id
		name
		slug
		description
		pic
		email
		phone
		address
		ref_cities_list_id
		ref_cities_list_name
		ref_province_list_id
		ref_province_list_name
		ref_district_id
		ref_district_name
		ref_postcode_id
		ref_postcode_name
		image
		created_date
		total_product
		total_transaction_paid
		total_transaction_otw
		total_transaction_finish
		nama_rekening
		no_rekening
		ref_bank_id
		ref_bank_name
		cabang_bank
		sisa_saldo
    }
  }
`;


const TokoDetail = () => {
	let { slug } = useParams();
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));

	var { data } = useQuery(GET_STORE, {
		variables: {
			slug: slug,
		},
	});
	useEffect(() => {
		if (!user || user.privilege === 'Stores') {
		return navigate("/dashboard", { replace: true });
	}
	}, [])

	return (
		<Fragment>
			<Breadcrumb title="Detail Toko" parent="Toko" />
			<Container fluid={true}>
				<Row>
					<TabsetProfile slug={slug} data={data}/>
					{data?
					<Col xl="12">
						<Card className="toko-detail-card">
							<CardBody>
								<TabsetSaldo slug={slug} id={data?data.store.id:null} sisa_saldo={data?data.store.sisa_saldo:0}/>
							</CardBody>
						</Card>
					</Col>:''}
					<Col xl="12">
						<Card className="toko-detail-card">
							<CardBody>
								<TabsetProduct slug={slug}  />
							</CardBody>
						</Card>
					</Col>
					<Col xl="12">
						<Card className="toko-detail-card">
							<CardBody>
								<TabsetOrder slug={slug} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default TokoDetail;
