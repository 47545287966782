import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import ListSaldoMutasi from "./common/list-saldo-mutasi";

const GET_SALDO = gql`
  query getSaldoStore(
  	$stores_id:Int!
  ){
    getSaldoStore(
		stores_id:$stores_id
	) {
		total
    }
  }
`;

const List_vendors = () => {
	const sizePerPage = 10;
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	var { data, refetch } = useQuery(GET_SALDO, {
		variables: {
			stores_id: user.stores_id
		}
	});
	return (
		<Fragment>
			<Breadcrumb title='Mutasi' parent="Saldo" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<ListSaldoMutasi stores_id={user.stores_id} />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_vendors;
