import React, { Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Card, CardBody, Container } from "reactstrap";
import ChartIncome from "./common/chartIncome";
import ListIncome from "./common/listIncome";

const List_vendors = () => {
	return (
		<Fragment>
			<Breadcrumb title="Expense" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<ChartIncome />
					</CardBody>
				</Card>
			</Container>
			<Container fluid={true}>
				<Card>
					<CardBody>
						<ListIncome />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_vendors;
