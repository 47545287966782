import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ModalFormWithdrawFee from "../withdraw-fee/common/modal-form-withdraw-fee";

const GET_DATA = gql`
  query getSettingWithdrawFees{
    getSettingWithdrawFees {
		total
		hasMore
		items{
			id
				value
				created_date
				start_date
				end_date
	  	}
    }
  }
`;


const defaultSorted = [{
	dataField: 'name',
	order: 'desc'
}];

const List_vendors = () => {
	const sizePerPage = 10;
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	const [open, setOpen] = useState(null);

	useEffect(() => {
		if (!user || user.privilege === 'Stores') {
			return navigate("/dashboard", { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	var { data, refetch } = useQuery(GET_DATA);

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		/*refetch({
			//indexFrom: (page*sizePerPage) - sizePerPage,
			//limit: sizePerPage,
			//city: filters && filters.ref_cities_list_name ? filters.ref_cities_list_name.filterVal : '',
			//name: filters && filters.name ? filters.name.filterVal : '',
			//price: filters && filters.price? filters.price.filterVal: '',
			//status: filters && filters.status? filters.status.filterVal: '',
		})*/
	}

	const columns = [
		{
			dataField: 'id',
			text: '#',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '15%' };
			}
		},
		{
			dataField: 'value',
			text: 'Value',
			filter: textFilter(),
			sort: true,
		},
		{
			dataField: 'start_date',
			text: 'Start',
			filter: textFilter(),
			sort: true,
		},
		{
			dataField: 'end_date',
			text: 'End',
			filter: textFilter(),
			sort: true,
		},
		{
			dataField: '',
			text: '',
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center text-danger">
					<i className="fa fa-edit" title="Edit" role="button" onClick={() => setOpen(row.id)} />
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: '13%' };
			}
		}
	];
	return (
		<Fragment>
			<Breadcrumb title="Service Fee" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<div className="btn-popup pull-right">
							
				<button className="btn btn-primary" onClick={() => setOpen('new')}>
					Tambah
				</button>
						</div>
						<div className="clearfix"></div>
						<div
							id="batchDelete"
							className="category-table store-list order-table coupon-list-delete"
						>
							{data ?
								<BootstrapTable
									keyField='id'
									hover
									condensed
									remote={{ sort: true, filter: true, pagination: true }}
									data={data && data.getSettingWithdrawFees ? data.getSettingWithdrawFees.items : []}
									columns={columns}
									bordered={false}
									noDataIndication="Table is Empty"
									pagination={paginationFactory(
										{
											sizePerPage: sizePerPage,
											hideSizePerPage: true,
											hidePageListOnlyOnePage: true,
											totalSize: data && data.getSettingWithdrawFees ? data.getSettingWithdrawFees.total : 0
										})}
									filter={filterFactory()}
									filterPosition="top"
									defaultSorted={defaultSorted}
									onTableChange={handleTableChange}
									wrapperClasses="table-responsive"
								/>
								: ''}
						</div>
					</CardBody>
				</Card>
			</Container>
			<ModalFormWithdrawFee open={open} setOpen={setOpen} refetch={refetch} user={user} />
		</Fragment>
	);
};

export default List_vendors;
