import React, { useEffect, useState, useRef } from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

const Breadcrumb = ({ options, filter, handleFilterChange, cssClass, classes }) => {
    const myRef = useRef();
    const [value,setValue] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect((e)=>{
        if(filter[options.id] && filter[options.id].start && filter[options.id].end){
            setStartDate(filter[options.id].start)
            setEndDate(filter[options.id].end)
            setValue(filter[options.id].start +' - ' +filter[options.id].end);
        }else{
            setStartDate()
            setEndDate()
            setValue('dd/mm/yyyy - dd/mm/yyyy');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(filter)])

    function rearrangeDatePickerPosition() {
        const dateRangePicker = document.getElementsByClassName('daterangepicker');
        for (let i = 0; i < dateRangePicker.length; i++) {
            const top = dateRangePicker[i].style['top'];
            if (top) {
                dateRangePicker[i].style.top = (parseInt(top.replace('px', '')) - 76) + 'px';
            }
        };
    }
    const handleShowDatePicker = (e) => {
        //hideDate();
        rearrangeDatePickerPosition();
    }
    const handleShowCalendarDatePicker = (e) => {
        //hideDate();
        rearrangeDatePickerPosition();
    }
    const handleApply = (event, picker) => {
        let filters = filter;
        const start_date = moment(picker.startDate).format('MM/YYYY');
        const end_date = moment(picker.endDate).format('MM/YYYY');
        let values = { start: start_date, end: end_date }
        filter[options.id] = values;
        setStartDate(start_date)
        setEndDate(end_date)
        setValue(start_date +' - ' +end_date);
        handleFilterChange(filters)
    }

    return (
        <div className={`${cssClass}`}>
            <DateRangePicker
                ref={myRef}
                initialSettings={{
                    autoUpdateInput: false,
                    alwaysShowCalendars: true,
                    opens: "center",
                    startDate: startDate,
                    endDate: endDate,
                }}
                onShow={handleShowDatePicker}
                onShowCalendar={handleShowCalendarDatePicker}
                onApply={handleApply}
            >
                <input type="text" className="form-control" placeholder="mm/yyyy - mm/yyyy" value={value} />
            </DateRangePicker>
        </div>
    );
};

export default Breadcrumb;
