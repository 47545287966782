import React from "react";
import { Home } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Breadcrumb = ({title,parent}) => {
	const navigate = useNavigate();
	return (
		<Container fluid={true}>
			<div className="page-header">
				<Row>
					<Col lg="6">
						<div className="page-header-left">
							<h3>
								{title}
							</h3>
						</div>
					</Col>
					<Col lg="6">
						<ol className="breadcrumb pull-right">
							<li className="breadcrumb-item">
								<Link to="/dashboard">
									<Home />
								</Link>
							</li>
							{parent?<li className="breadcrumb-item">{parent}</li>:''}
							{title?<li className="breadcrumb-item active">{title}</li>:''}
						</ol>
					</Col>
					{parent?
					<Col lg="6">
								<div className="back-btn" role="button" onClick={() => navigate(-1) || navigate('/')}>
									<i className="fa fa-angle-left">&nbsp;Back</i> 
								</div>
					</Col>:''}
				</Row>
			</div>
		</Container>
	);
};

export default Breadcrumb;
