import React, { Fragment, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import {  useNavigate, useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useMutation, gql } from '@apollo/client';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GET_Login = gql`
  mutation ($input: LoginUserInput) {
    loginUser(input: $input) {
        success,
        message,
        data{
			id
			email
			phone
			name
			privilege
			stores_id
			stores_name
			stores_slug
			status
			created_date
        }
    }
  }
`;


const LoginTabset = ({isPublic}) => {
	const history = useNavigate();
    const [addTodo] = useMutation(GET_Login);

	const clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove("show");
		event.target.classList.add("show");
	};

	const routeChange = () => {
		history(`${process.env.PUBLIC_URL}/dashboard`);
	};

    useEffect(() => {
        if (localStorage.getItem('user') !== 'false' && localStorage.getItem('user') !== null) {
            history("/dashboard");
        }
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const inputData = {
            email: event.target.email.value,
            password: event.target.password.value,
        };
        var valid = 0;
        if (!inputData.email) {
            toast.error("Please enter your Email !");
            valid += 1;
        }
        if (!inputData.password) {
            toast.error("Please enter your Password !");
            valid += 1;
        }
        if (valid > 0) {
            return false;
        }

        const result = await addTodo({ variables: { input: inputData, isPublic } });
        if (!result.data.loginUser.success) {
            toast.error(result.data.loginUser.message);
        } else {
            localStorage.setItem('user', JSON.stringify(result.data.loginUser.data[0]))
            toast.success(result.data.loginUser.message);
            history("/dashboard");
        }
    }
	return (
		<div>
			<Fragment>
				<Tabs>
					<TabList className="nav nav-tabs tab-coupon">
						<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<User />
							Login
						</Tab>
						{/*<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<Unlock />
							Register
						</Tab>*/}
					</TabList>

					<TabPanel>
						<Form className="form-horizontal auth-form" onSubmit={handleSubmit}>
							<FormGroup>
								<Input
									required=""
									name="email"
									type="email"
									className="form-control"
									placeholder="Username"
									id="email"
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="password"
									id="password"
									type="password"
									className="form-control"
									placeholder="Password"
								/>
							</FormGroup>
							<div className="form-terms">
								<div className="custom-control custom-checkbox me-sm-2">
									<Label className="d-block">
										<span className="pull-right">
											<a href="/forgotpassword" className="btn btn-default forgot-pass p-0">
												lost your password
											</a>
										</span>
									</Label>
								</div>
							</div>
							<div className="form-button">
								<Button
									color="primary"
									type="submit"
								>
									Login
								</Button>
							</div>
						</Form>
					</TabPanel>
				</Tabs>
			</Fragment>
		</div>
	);
};

export default LoginTabset;
