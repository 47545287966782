/* eslint-disable eqeqeq */
import React, { Fragment } from "react";
import TabsetUser from "./common/form-customer";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";

const Create_vendors = () => {
	let { id } = useParams();
	const title = id == 0 ? 'Tambah Customer' : 'Ubah Customer' ;
	return (
		<Fragment>
			<Breadcrumb title={title} parent="Customer" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TabsetUser id={id} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Create_vendors;
