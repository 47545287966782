import React, { Fragment } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { numberFormat,orderStatus } from "../../../helpers/custom";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

const GET_STORE_ORDER = gql`
  query  getOrders(
	$store:String
	$custName:String
	$indexFrom:Int
	$limit:Int
	$status:String
	$city:String
	$total:String
	$sortBy:String
  ){
    getOrders(
		store: $store
		custName: $custName
		indexFrom: $indexFrom
		limit: $limit
		status: $status
		city: $city
		total: $total
		sortBy: $sortBy
	) {
		hasMore
		total
		items{
			id
			customers_id
			store_slug
			store_name
			name
			phone
			address
			detail_address
			ref_city_id
			ref_city_name
			ref_province_id
			ref_province_name
			postcode
			subtotal
			discount
			grandtotal
			purchases_date
			created_date
			payment
			status
			orderList {
				id
				customers_purchases_history_id
				products_id
				products_title
				products_img{
					id
					alt
					src
				}
				products_variant_id
				products_variant_title
				products_variant_img{
					id
					alt
					src
				}
				qty
				price
				discount
				total
	  }
	  	}
    }
  }
`;


const columns = [
	{
		dataField: 'id',
		text: '#',
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '5%' };
		}
	},
	{
		dataField: 'name',
		text: 'Nama',
		filter: textFilter(),
		sort: true,
	},
	{
		dataField: 'ref_city_name',
		text: 'Kota',
		formatter: (cell, row, rowIndex, extraData) => (
			row.ref_city_name + ', ' + row.ref_province_name
		),
		filter: textFilter(),
		sort: true,

	},
	{
		dataField: 'status',
		text: 'Status',
		filter: selectFilter({
			options: orderStatus,
		}),
		formatter: (cell, row, rowIndex, extraData) => (
			extraData[cell]
		),
		formatExtraData: orderStatus,
		sort: true,
	},
	{
		dataField: 'grandtotal',
		text: 'Total',
		filter: textFilter(),
		sort: true,
		formatter: (cell, row, rowIndex, extraData) => (
			numberFormat(parseInt(row.grandtotal))
		),
		headerStyle: (colum, colIndex) => {
			return { width: '12%' };
		}
	},
	{
		dataField: 'created_date',
		text: 'Tanggal Order',
		filter: dateFilter(),
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '15%' };
		}
	},
	{
		dataField: '',
		text: '',
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/produk/${row.slug}`} >
					<i className="fa fa-eye" title="Lihat" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: '5%' };
		}
	}
];

const columns_mobile = [
	{
		dataField: 'id',
		text: '#',
		headerStyle: (colum, colIndex) => {
			return { width: '13%' };
		}
	},
	{
		dataField: 'name',
		text: 'Nama',
		filter: textFilter(),
		sort: true,
		formatter: (cell, row, rowIndex, extraData) => (
			<>
				<div><b>{row.name}</b></div>
				<div>{numberFormat(parseInt(row.grandtotal))}</div>
			</>
		),
	},
	{
		dataField: 'created_date',
		text: 'Tanggal Order',
		filter: textFilter(),
		sort: true,

	},
	{
		dataField: 'status',
		text: 'Status',
		filter: selectFilter({
			options: orderStatus,
		}),
		formatter: (cell, row, rowIndex, extraData) => (
			extraData[cell]
		),		
		formatExtraData: orderStatus,
		sort: true,
	},
	{
		dataField: '',
		text: '',
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/order/${row.id}`} >
					<i className="fa fa-eye" title="Lihat" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: '5%' };
		}
	}
];

const defaultSorted = [{
	dataField: 'id',
	order: 'desc'
}];

const List_vendors = ({ slug }) => {
	const is_mobile = window.innerWidth < 750 ? true : false;
	const sizePerPage = 10;
	var { data, refetch } = useQuery(GET_STORE_ORDER, {
		variables: {
			store: slug,
			indexFrom: 0,
			limit: sizePerPage,
		}
	});

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		refetch({
			store: slug,
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			custName: filters && filters.name ? filters.name.filterVal : '',
			city: filters && filters.ref_city_name ? filters.ref_city_name.filterVal : '',
			total: filters && filters.grandtotal ? filters.grandtotal.filterVal : '',
			status: filters && filters.status ? filters.status.filterVal : '',
			sortBy: sortField + '-' + sortOrder
		})
	}
	return (
		<>
			<div
				className="category-table order-list order-table coupon-list-delete"
			>
				<h4><b>Order</b></h4>
				{data ? <BootstrapTable
					keyField='id'
					hover
					condensed
					remote={{ sort: true, filter: true, pagination: true }}
					data={data && data.getOrders ? data.getOrders.items : []}
					columns={is_mobile ? columns_mobile : columns}
					bordered={false}
					noDataIndication="Table is Empty"
					pagination={paginationFactory(
						{
							sizePerPage: sizePerPage,
							hideSizePerPage: true,
							hidePageListOnlyOnePage: true,
							totalSize: data && data.getOrders ? data.getOrders.total : 0
						})}
					filter={filterFactory()}
					filterPosition="top"
					defaultSorted={defaultSorted}
					wrapperClasses="table-responsive"
					onTableChange={handleTableChange}
				/> : ''}
			</div>
		</>
	);
};

export default List_vendors;
