import React, { useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Card, CardBody } from "reactstrap";
import TableOrderNew from "./tableOrderNew";
import TableOrderSent from "./tableOrderSent";
import TableOrderSuccess from "./tableOrderSuccess";
import TableOrderComplaint from "./tableOrderComplaint";
import TableOrderCanceled from "./tableOrderCanceled";

const TabsetPage = ({user}) => {
    const [tabIndex, setTabIndex] = useState(1);

    return (
        <Card className=" o-hidden widget-cards">
            <CardBody>
                <h4 className="fw-bold font-black">List Order</h4>
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList className="nav nav-tabs tab-coupon">
                        <Tab className="nav-link">New</Tab>
                        <Tab className="nav-link">Sent</Tab>
                        <Tab className="nav-link">Success</Tab>
                        <Tab className="nav-link">Complaint</Tab>
                        <Tab className="nav-link">Canceled</Tab>
                    </TabList>
                    <TabPanel>
                        <TableOrderNew user={user} loadData={tabIndex===0?'load':''} />
                    </TabPanel>
                    <TabPanel>
                        <TableOrderSent user={user}  loadData={tabIndex===1?'load':''} />
                    </TabPanel>
                    <TabPanel>
                        <TableOrderSuccess user={user}  loadData={tabIndex===2?'load':''} />
                    </TabPanel>
                    <TabPanel>
                        <TableOrderComplaint user={user}  loadData={tabIndex===3?'load':''} />
                    </TabPanel>
                    <TabPanel>
                        <TableOrderCanceled user={user}  loadData={tabIndex===4?'load':''} />
                    </TabPanel>
                </Tabs>
            </CardBody>
        </Card>
    );
};

export default TabsetPage;
