/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row, Button, FormFeedback, FormText } from "reactstrap";
import imgUser from "../../assets/images/user.png";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useParams, useNavigate, Link } from "react-router-dom";
import ModalVariant from "./common/modal-variant"
import ModalCategory from "./common/modal-category"
import ModalSubcategory from "./common/modal-subcategory"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./add-product.css";
import { numberFormat } from "../../helpers/custom";
import Select from 'react-select'

const GET_PRODUCT = gql`
  query (
    $slug: String!
  ) {
	product(
		slug: $slug
	) {
		id
        title
        description
        brand
        ref_products_category_id
        category
        ref_products_subcategory_id
		subcategory
		tipe
		po_days
        price
        new
        sale
        stock
        discount
        slug
		sold
		sisa
		status
		weight
		width
		height
		length
        variants {
          	id
			products_id
          	title
          	stock
          	price
          	image
          	weight
			width
			height
			length
			status
        }
        images {
          image_id
          id
          alt
          src
        }
        store {
          id
          name
          slug
          ref_cities_list_name
        }
	},
  }
`;

const GET_CATEGORY = gql`
  query (
    $status: String
  ) {
    getCategory(
		status: $status
	) {
		category{
			id
			title
			status
		}
  	}
  }
`;

const GET_SUBCATEGORY = gql`
  query (
    $status: String
  ) {
  	getSubcategory(
		status: $status
	) {
		subcategory{
			id
			title
			products_category_id
			status
		}
    }
  }
`;

const GET_STORE = gql`
  query ($status:String){
    stores(status:$status) {
		hasMore
		total
		items{
			id
			name
			slug
			pic
			email
			phone
			address
			ref_cities_list_id
			ref_cities_list_name
			ref_province_list_id
			ref_province_list_name
			image
	  	}
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($input: ProductInput) {
    createProduct(input: $input) {
        success,
        message,
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: ProductInput) {
    updateProduct(input: $input ) {
        success,
        message,
    }
  }
`;
const Add_product = () => {
	const is_mobile = window.innerWidth < 750 ? true : false;
	let navigate = useNavigate();
	let { slug } = useParams();
	const user = JSON.parse(localStorage.getItem('user'));

	const [productsId, setProductsId] = useState();
	const [haveVariant, setHaveVariant] = useState(false);
	const [variant, setVariant] = useState([]);
	const [openModalVariant, setOpenModalVariant] = useState(false);
	const [openModalCategory, setOpenModalCategory] = useState(false);
	const [openModalSubcategory, setOpenModalSubcategory] = useState(false);
	const [picture, setPicture] = useState([]);
	const [editVariant, setEditVariant] = useState('');
	const [categoryList, setCategoryList] = useState([]);
	const [subcategoryList, setSubcategoryList] = useState([]);
	const [storeList, setStoreList] = useState([]);
	const [priceRange, setPriceRange] = useState(0);
	const [totalStock, setTotalStock] = useState(0);

	const [addTodo] = useMutation(CREATE_PRODUCT);
	const [updateTodo] = useMutation(UPDATE_PRODUCT);
	const [values, setValues] = useState([{
		title: '',
		description: '',
		ref_products_category_id: '',
		ref_products_subcategory_id: '',
		price: '',
		stock: '',
		weight: '',
		variant: '',
		po_days: '',
		tipe: '',
	}])
	const [invalid, setInvalid] = useState([{
		title: '',
		ref_products_category_id: '',
		ref_products_subcategory_id: '',
		price: '',
		stock: '',
		weight: '',
		po_days: '',
		tipe: '',
	}])

	const [file, setFile] = useState([
		{ file: '' },
		{ file: '' },
		{ file: '' },
		{ file: '' },
		{ file: '' },
	]);

	const [dummyimgs, setDummyimgs] = useState([
		{ img: '' },
		{ img: '' },
		{ img: '' },
		{ img: '' },
		{ img: '' },
	]);
	const [removeImage, setRemoveImage] = useState([]);

	var { data: productsData } = useQuery(GET_PRODUCT, {
		variables: {
			slug: slug ? slug : '',
		},
	});

	useEffect(() => {
		if (productsData && productsData.product) {
			const tmp_data = {
				id: productsData.product.id,
				title: productsData.product.title,
				description: productsData.product.description.replace(/<br \/>/g, '\n'),
				ref_products_category_id: {
					value: productsData.product.ref_products_category_id,
					label: productsData.product.category
				},
				ref_products_subcategory_id: {
					value: productsData.product.ref_products_subcategory_id,
					label: productsData.product.subcategory
				},
				price: productsData.product.price,
				tipe: productsData.product.tipe,
				po_days: productsData.product.po_days,
				status: productsData.product.status,
				stock: productsData.product.stock,
				weight: productsData.product.weight,
				width: productsData.product.width,
				height: productsData.product.height,
				length: productsData.product.length,
				stores_id: {
					value: productsData.product.store[0].id,
					label: productsData.product.store[0].name
				},
			}
			console.log(tmp_data)
			setValues([tmp_data])
			setProductsId(productsData.product.id)
		}
		if (productsData && productsData.product && productsData.product.variants.length > 0) {
			setHaveVariant(true);
			setVariant(productsData.product.variants)
			const pictures = picture;
			productsData.product.variants.forEach(async (e, key) => {
				const defaultType = 'image/jpeg';
				const response = await fetch(process.env.REACT_APP_IMAGE_URL + e.image)
				const data = await response.blob()
				const test = new File([data], process.env.REACT_APP_IMAGE_URL + e.image, {
					type: data.type || defaultType,
				})
				pictures[key] = test;
				setPicture(pictures)
			})
		}

		const fetchImageData = async () => {
			try {
				const files = [...file];
				let dummyimgs_ = [...dummyimgs];
				await productsData.product.images.forEach(async (e, key) => {
					if (e.id > 0) {
						const defaultType = 'image/jpeg';
						const response = await fetch(process.env.REACT_APP_IMAGE_URL + e.src)
						const data = await response.blob()
						const test = new File([data], process.env.REACT_APP_IMAGE_URL + e.src, {
							type: data.type || defaultType,
						})
						files[key] = {
							file: '',
							uid: parseInt(user.id),
							id: parseInt(e.id)
						};
						dummyimgs_[key] = { img: process.env.REACT_APP_IMAGE_URL + e.src };
						setDummyimgs(dummyimgs_);
						setFile(files);
					}
				})
			} catch (error) {
				console.log("error", error);
			}

		}

		if (productsData && productsData.product && productsData.product.images.length > 0) {
			fetchImageData();

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productsData])


	var { data: categoryData, refetch: refetchCategoryData } = useQuery(GET_CATEGORY, {
		variables: {
			status: 'active',
		},
	});

	useEffect(() => {
		if (categoryData && categoryData.getCategory) {
			let category = []
			categoryData.getCategory.category.forEach(e => {
				category.push({
					label: e.title,
					value: e.id,
				})
			})
			setCategoryList(category)
		}
	}, [categoryData])

	var { data: subcategoryData, refetch: refetchSubcategoryData } = useQuery(GET_SUBCATEGORY, {
		variables: {
			status: 'active',
		},
	});

	useEffect(() => {
		if (subcategoryData && subcategoryData.getSubcategory) {
			let subcategory = []
			subcategoryData.getSubcategory.subcategory.forEach(e => {
				subcategory.push({
					label: e.title,
					value: e.id,
				})
			})
			setSubcategoryList(subcategory)
		}
	}, [subcategoryData])

	var { data: storeData, refetch: refetchStoreData } = useQuery(GET_STORE, {
		variables: {
			status: 'active',
		},
	});

	useEffect(() => {
		if (storeData && storeData.stores) {
			let stores = []
			storeData.stores.items.forEach(e => {
				stores.push({
					label: e.name,
					value: e.id,
				})
			})
			setStoreList(stores)
		}
	}, [storeData])

	useEffect(() => {
		if (variant) {
			let lowest_price = 0;
			let highest_price = 0;
			let stock = 0;
			variant.forEach(e => {
				if (lowest_price === 0) {
					lowest_price = e.price;
				}
				if (lowest_price >= e.price) {
					lowest_price = e.price;
				}
				if (highest_price <= e.price) {
					highest_price = e.price;
				}
				stock += parseInt(e.stock)
			});
			let price_range = '';
			if (lowest_price === highest_price) {
				price_range = numberFormat(parseInt(highest_price));
			} else {
				price_range = numberFormat(parseInt(lowest_price)) + ' - ' + numberFormat(parseInt(highest_price));
			}
			setPriceRange(price_range)
			setTotalStock(stock)
		}
	}, [variant, JSON.stringify(variant)])

	const handleChange = (event) => {
		let tmp_data = [...values];
		let tmp_invalid_data = [...invalid];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_invalid_data[0][name] = value ? '' : 'invalid';
		tmp_data[0][name] = value;
		setValues(tmp_data);
		if (name === 'ref_products_category_id') {
			handleChangeCategory();
		}
		if (name === 'ref_products_subcategory_id') {
			handleChangeSubcategory();
		}
	};

	const handleChangeSelect = (type, event) => {
		let tmp_data = [...values];
		let tmp_invalid_data = [...invalid];
		tmp_invalid_data[0][type] = event.value ? '' : 'invalid';
		tmp_data[0][type] = event;
		setValues(tmp_data);
		if (type === 'ref_products_category_id') {
			handleChangeCategory();
		}
		if (type === 'ref_products_subcategory_id') {
			handleChangeSubcategory();
		}
	};
	const handleChangeCategory = () => {
		let tmp_data = [...values];
		if (values[0].ref_products_category_id && subcategoryData) {
			const return_data = subcategoryData.getSubcategory.subcategory.filter(e => {
				return e.products_category_id == values[0].ref_products_category_id.value;
			})
			let subcategory = [];
			const return_data2 = return_data.filter(e => {
				return e.id == values[0].ref_products_subcategory_id.value;
			})
			if (return_data2.length === 0) {
				tmp_data[0]['ref_products_subcategory_id'] = '';
			}
			return_data.forEach(e => {
				subcategory.push({
					label: e.title,
					value: e.id,
				})
			})
			setSubcategoryList(subcategory);
		} else {
			tmp_data[0]['ref_products_category_id'] = '';
			tmp_data[0]['ref_products_subcategory_id'] = '';
			let subcategory = []
			subcategoryData.getSubcategory.subcategory.forEach(e => {
				subcategory.push({
					label: e.title,
					value: e.id,
				})
			})
			setSubcategoryList(subcategory);
		}
		setValues(tmp_data);
	};

	const handleChangeSubcategory = () => {
		if (values[0].ref_products_subcategory_id && categoryData) {

			const return_data1 = subcategoryData.getSubcategory.subcategory.filter(e => {
				return e.id == values[0].ref_products_subcategory_id.value;
			})
			const return_data2 = categoryData.getCategory.category.filter(e => {
				return e.id == return_data1[0].products_category_id;
			})
			let tmp_data = [...values];
			let subcategory = [];
			return_data2.forEach(e => {
				subcategory.push({
					label: e.title,
					value: e.id,
				})
			})
			tmp_data[0]['ref_products_category_id'] = subcategory[0];

			setValues(tmp_data);
			handleChangeCategory();
		}
	};

	//	image upload
	const _handleImgChange = (e, i) => {
		e.preventDefault();
		let reader = new FileReader();
		const image = e.target.files[0];
		if (image) {
			let tmp_data = [...file];
			reader.onload = () => {
				dummyimgs[i].img = reader.result;
				tmp_data[i].file = image;
				tmp_data[i].uid = parseInt(user.id);
				setFile(tmp_data);
				setDummyimgs(dummyimgs);
			};
			reader.readAsDataURL(image);
		}
	};

	const _handleImgRemove = (i) => {
		let removeImages = [];
		let tmp_data = [...file];
		removeImages.push(dummyimgs[i].img);
		tmp_data[i] = { file: '' };
		setFile(tmp_data);
		dummyimgs[i].img = '';
		setDummyimgs(dummyimgs);
		setRemoveImage(removeImages)
	};

	const onChangeVariant = (e) => {
		setHaveVariant(e.target.checked);
	}

	const handleRemoveVariant = (event) => {
		const variants = variant.filter((e, key) => {
			return key !== event;
		})
		const pictures = picture.filter((e, key) => {
			return key !== event;
		})
		setVariant(variants);
		setPicture(pictures);
	};

	const handleEditVariant = (event) => {
		setEditVariant(event + 1);
		setOpenModalVariant(true);
	};

	const handleValidSubmit = async (event) => {
		event.preventDefault();
		const inputData = [...values];
		let invalidData = [...invalid];
		let valid = 0;
		if (user.privilege !== 'Stores') {
			if (!inputData[0]['stores_id']) {
				valid += 1;
				invalidData[0]['stores_id'] = 'invalid';
			} else {
				invalidData[0]['stores_id'] = '';
			}
		}

		if (!inputData[0]['title']) {
			valid += 1;
			invalidData[0]['title'] = 'invalid';
		} else {
			invalidData[0]['title'] = '';
		}

		if (!inputData[0]['ref_products_category_id']) {
			valid += 1;
			invalidData[0]['ref_products_category_id'] = 'invalid';
		} else {
			invalidData[0]['ref_products_category_id'] = '';
		}

		if (!inputData[0]['ref_products_subcategory_id']) {
			valid += 1;
			invalidData[0]['ref_products_subcategory_id'] = 'invalid';
		} else {
			invalidData[0]['ref_products_subcategory_id'] = '';
		}

		if (!inputData[0]['tipe']) {
			valid += 1;
			invalidData[0]['tipe'] = 'invalid';
		} else {
			invalidData[0]['tipe'] = '';
		}

		if (inputData[0]['tipe'] === 'Pre-order') {
			if (!inputData[0]['po_days']) {
				valid += 1;
				invalidData[0]['po_days'] = 'invalid';
			} else {
				invalidData[0]['po_days'] = '';
			}
		}

		if (!haveVariant) {
			if (!inputData[0]['price']) {
				valid += 1;
				invalidData[0]['price'] = 'invalid';
			} else {
				invalidData[0]['price'] = '';
			}

			if (!inputData[0]['stock']) {
				valid += 1;
				invalidData[0]['stock'] = 'invalid';
			} else {
				invalidData[0]['stock'] = '';
			}

			if (!inputData[0]['weight']) {
				valid += 1;
				invalidData[0]['weight'] = 'invalid';
			} else {
				invalidData[0]['weight'] = '';
			}


			if ((!inputData[0]['length'] || inputData[0]['length'] == 0 || !Number.isInteger(parseInt(inputData[0]['length'])))) {
				valid += 1;
				invalidData[0]['length'] = 'invalid';
			} else {
				invalidData[0]['length'] = '';
			}

			if ((!inputData[0]['width'] || inputData[0]['width'] == 0 || !Number.isInteger(parseInt(inputData[0]['width'])))) {
				valid += 1;
				invalidData[0]['width'] = 'invalid';
			} else {
				invalidData[0]['width'] = '';
			}

			if ((!inputData[0]['height'] || inputData[0]['height'] == 0 || !Number.isInteger(parseInt(inputData[0]['height'])))
			) {
				valid += 1;
				invalidData[0]['height'] = 'invalid';
			} else {
				invalidData[0]['height'] = '';
			}

			if ((!inputData[0]['length'] || inputData[0]['length'] == 0 || !Number.isInteger(parseInt(inputData[0]['length']))) ||
				(!inputData[0]['width'] || inputData[0]['width'] == 0 || !Number.isInteger(parseInt(inputData[0]['width']))) ||
				(!inputData[0]['height'] || inputData[0]['height'] == 0 || !Number.isInteger(parseInt(inputData[0]['height'])))
			) {
				valid += 1;
				invalidData[0]['volume'] = 'invalid';
			} else {
				invalidData[0]['volume'] = '';
			}

		}

		const filter_file = file.filter(e => {
			return e.file !== '';
		})

		if (!inputData[0].id) {
			if (filter_file.length === 0) {
				valid += 1;
				invalidData[0]['file'] = 'invalid';
			} else {
				invalidData[0]['file'] = '';
			}
		}

		if (haveVariant) {
			if (variant.length == 0) {
				valid += 1;
				invalidData[0]['variant'] = 'invalid';
			} else {
				invalidData[0]['variant'] = '';
			}
		}
		if (valid > 0) {
			setInvalid(invalidData);
			return false;
		}
		const ref_products_category_id = inputData[0].ref_products_category_id;
		const ref_products_subcategory_id = inputData[0].ref_products_subcategory_id;
		const stores_id = inputData[0].stores_id;
		inputData[0].po_days = inputData[0].po_days ? parseInt(inputData[0].po_days) : null;
		inputData[0].ref_products_category_id = parseInt(inputData[0].ref_products_category_id.value);
		inputData[0].ref_products_subcategory_id = parseInt(inputData[0].ref_products_subcategory_id.value);
		inputData[0].stock = parseInt(inputData[0].stock ? inputData[0].stock : 0);
		inputData[0].price = parseInt(inputData[0].price ? inputData[0].price : 0);
		inputData[0].weight = parseInt(inputData[0].weight ? inputData[0].weight : 0);
		inputData[0].width = parseInt(inputData[0].width ? inputData[0].width : 0);
		inputData[0].height = parseInt(inputData[0].height ? inputData[0].height : 0);
		inputData[0].length = parseInt(inputData[0].length ? inputData[0].length : 0);
		inputData[0].file = file;
		inputData[0].variant = variant ? variant : [];
		inputData[0].stores_id = user.stores_id ? parseInt(user.stores_id) : parseInt(inputData[0].stores_id.value);
		inputData[0].uid = parseInt(user.id);
		inputData[0].description = inputData[0].description ? inputData[0].description.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';

		inputData[0].file.forEach((e, key) => {
			if (e.length === 0) {
				delete (inputData[0].file[key])
			} else {
				if (!e.file) {
					delete (inputData[0].file[key].file)
				}
			}
		})
		inputData[0].variant.forEach((e, key) => {
			inputData[0].variant[key].stock = parseInt(inputData[0].variant[key].stock);
			inputData[0].variant[key].price = parseInt(inputData[0].variant[key].price);
			inputData[0].variant[key].weight = parseInt(inputData[0].variant[key].weight);
			inputData[0].variant[key].width = parseInt(inputData[0].variant[key].width);
			inputData[0].variant[key].height = parseInt(inputData[0].variant[key].height);
			inputData[0].variant[key].length = parseInt(inputData[0].variant[key].length);
			delete (inputData[0].variant[key].__typename);
		})
		if (inputData[0].id > 0) {
			const result = await updateTodo({ variables: { input: inputData[0] } });
			if (result.data.updateProduct.success) {
				toast.success(result.data.updateProduct.message)
				return navigate("/produk");
			} else {
				inputData[0].ref_products_category_id = ref_products_category_id;
				inputData[0].ref_products_subcategory_id = ref_products_subcategory_id;
				inputData[0].stores_id = stores_id;
				toast.error(result.data.updateProduct.message)
			}
		} else {
			delete (inputData[0].id);
			const result = await addTodo({ variables: { input: inputData[0] } });
			if (result.data.createProduct.success) {
				toast.success(result.data.createProduct.message)
				return navigate("/produk", { replace: true });
			} else {
				inputData[0].ref_products_category_id = ref_products_category_id;
				inputData[0].ref_products_subcategory_id = ref_products_subcategory_id;
				inputData[0].stores_id = stores_id;
				toast.error(result.data.createProduct.message)
			}
		}

	};

	return (
		<Fragment>
			<Breadcrumb title="Add Product" parent="Product" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5>Product Info</h5>
							</CardHeader>
							<CardBody>
								<Row className="product-adding">
									<Col xl="12">
										<Form
											className="needs-validation add-product-form"
											onSubmit={handleValidSubmit}
										>
											<div className="form form-label-center">
												{user.privilege === 'Stores' ? '' :
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-4 mb-0">
															Toko :
														</Label>
														<div className="col-xl-8 col-sm-7">
															<div className="input-group">
																<Select
																	className={`form-select ${invalid[0].stores_id ? 'is-invalid' : ''}`}
																	classNamePrefix="select"
																	name="stores_id"
																	id="stores_id"
																	type="select"
																	value={values[0].stores_id}
																	onChange={(e) => handleChangeSelect('stores_id', e)}
																	invalid={invalid[0].stores_id ? 'invalid' : ''}
																	placeholder="Pilih Salah Satu"
																	options={storeList}
																/>
																<div className={`invalid-feedback ${invalid[0].stores_id ? 'is-invalid' : 'hide'}`}>
																	This field is required
																</div>
															</div>
														</div>
														<div className="valid-feedback">Looks good!</div>
													</FormGroup>}
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Nama Produk :
													</Label>
													<div className="col-xl-8 col-sm-7">
														<Input
															className="form-control"
															name="title"
															id="title"
															type="text"
															value={values[0].title}
															onChange={handleChange}
															invalid={invalid[0].title ? 'invalid' : ''}
														/>
														<FormFeedback>
															This field is required
														</FormFeedback>
													</div>
													<div className="valid-feedback">Looks good!</div>
												</FormGroup>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Kategori :
													</Label>
													<div className="col-xl-8 col-sm-7">
														<div className="input-group">
															<Select
																className={`form-select ${invalid[0].ref_products_category_id ? 'is-invalid' : ''}`}
																classNamePrefix="select"
																name="ref_products_category_id"
																id="ref_products_category_id"
																type="select"
																value={values[0].ref_products_category_id}
																onChange={(e) => handleChangeSelect('ref_products_category_id', e)}
																invalid={invalid[0].ref_products_category_id ? 'invalid' : ''}
																placeholder="Pilih Salah Satu"
																options={categoryList}
															/>
															{user.privilege === 'Admin' ?
																<div className="input-group-prepend">
																	<div className="input-group-text" title="Tambah Kategori" role="button" onClick={() => setOpenModalCategory(true)}>+</div>
																</div> : ''}
															<div className={`invalid-feedback ${invalid[0].ref_products_category_id ? 'is-invalid' : 'hide'}`}>
																This field is required
															</div>
														</div>
													</div>
													<div className="valid-feedback">Looks good!</div>
												</FormGroup>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Sub Kategori :
													</Label>
													<div className="col-xl-8 col-sm-7">
														<div className="input-group">
															<Select
																className={`form-select ${invalid[0].ref_products_subcategory_id ? 'is-invalid' : ''}`}
																classNamePrefix="select"
																name="ref_products_subcategory_id"
																id="ref_products_subcategory_id"
																type="select"
																value={values[0].ref_products_subcategory_id}
																onChange={(e) => handleChangeSelect('ref_products_subcategory_id', e)}
																invalid={invalid[0].ref_products_subcategory_id ? 'invalid' : ''}
																placeholder="Pilih Salah Satu"
																options={subcategoryList}
															/>
															<div className="input-group-prepend">
																<div className="input-group-text" title="Tambah subkategori" role="button" onClick={() => setOpenModalSubcategory(true)}>+</div>
															</div>
															<div className={`invalid-feedback ${invalid[0].ref_products_subcategory_id ? 'is-invalid' : 'hide'}`}>
																This field is required
															</div>

														</div>
													</div>
													<div className="valid-feedback">Looks good!</div>
												</FormGroup>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4">
														Tipe :
													</Label>
													<div className="col-xl-8 col-sm-7 description-sm">
														<Input
															className="form-control"
															id="tipe"
															name="tipe"
															type="select"
															required=""
															onChange={(e) => handleChange(e)}
															value={values[0].tipe}
															invalid={invalid[0].tipe ? 'invalid' : ''}
														>
															<option value="">
																Pilih salah satu
															</option>
															<option value="Produk">
																Produk
															</option>
															<option value="Pre-order">
																Pre-Order
															</option>
														</Input>
														<div className={`invalid-feedback ${invalid[0].tipe ? 'is-invalid' : 'hide'}`}>
															This field is required
														</div>
													</div>
												</FormGroup>
												{values[0].tipe === 'Pre-order' ?
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-4">
															Waktu Preorder :
														</Label>
														<div className="col-xl-8 col-sm-7 description-sm">
															<Input
																className="form-control mb-0"
																name="po_days"
																id="po_days"
																type="number"
																value={values[0].po_days}
																onChange={handleChange}
																invalid={invalid[0].po_days ? 'invalid' : ''}
															/>
															<FormText>
																Lama PO dalam Hari
															</FormText>
															<FormFeedback>
																This field is required
															</FormFeedback>
														</div>
													</FormGroup> : ''}
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4">
														Ada Varian? :
													</Label>
													<div className="col-xl-8 col-sm-7 description-sm">
														<Input
															type="checkbox"
															id="haveVariant"
															name="haveVariant"
															checked={haveVariant}
															onChange={onChangeVariant} />
													</div>
												</FormGroup>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Stok :
													</Label>
													<div className="col-xl-8 col-sm-7">
														{haveVariant ?
															totalStock
															:
															<>
																<Input
																	className="form-control mb-0"
																	name="stock"
																	id="stock"
																	type="number"
																	value={values[0].stock}
																	onChange={handleChange}
																	invalid={invalid[0].stock ? 'invalid' : ''}
																/>
																<FormFeedback>
																	This field is required
																</FormFeedback>
															</>
														}
													</div>
												</FormGroup>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4 mb-0">
														Harga :
													</Label>
													<div className="col-xl-8 col-sm-7">
														{haveVariant ?
															priceRange
															:
															<>
																<Input
																	className="form-control mb-0"
																	name="price"
																	id="price"
																	type="number"
																	value={values[0].price}
																	onChange={handleChange}
																	invalid={invalid[0].price ? 'invalid' : ''}
																/>
																<FormFeedback>
																	This field is required
																</FormFeedback>
															</>
														}
													</div>
													<div className="valid-feedback">Looks good!</div>
												</FormGroup>
												{haveVariant ? '' :
													<>
														<FormGroup className="form-group mb-3 row">
															<Label className="col-xl-3 col-sm-4 mb-0">
																Berat :
															</Label>
															<div className="col-xl-8 col-sm-7">
																<Input
																	className="form-control mb-0"
																	name="weight"
																	id="weight"
																	type="number"
																	value={values[0].weight}
																	onChange={handleChange}
																	invalid={invalid[0].weight ? 'invalid' : ''}
																/>
																<FormText>
																	Berat dalam Gram
																</FormText>
																<FormFeedback>
																	This field is required
																</FormFeedback>
															</div>
															<div className="valid-feedback">Looks good!</div>
														</FormGroup>
													</>
												}
												{haveVariant ?
													''
													:
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-4 mb-0">
															Volume :
														</Label>
														<div className="col-xl-8 col-sm-7">
															<>
																<div className="d-flex align-items-center">
																	P&nbsp;<Input
																		className="form-control w-25 mb-0"
																		name="length"
																		id="length"
																		type="number"
																		value={values[0].length}
																		onChange={handleChange}
																		invalid={invalid[0].length ? 'invalid' : ''}
																	/>
																	&nbsp;&nbsp;x &nbsp;&nbsp;L&nbsp;<Input
																		className="form-control w-25 mb-0"
																		name="width"
																		id="width"
																		type="number"
																		value={values[0].width}
																		onChange={handleChange}
																		invalid={invalid[0].width ? 'invalid' : ''}
																	/>
																	&nbsp;&nbsp;x &nbsp;&nbsp;T&nbsp;<Input
																		className="form-control w-25 mb-0"
																		name="height"
																		id="height"
																		type="number"
																		value={values[0].height}
																		onChange={handleChange}
																		invalid={invalid[0].height ? 'invalid' : ''}
																	/>
																</div>
																<FormText>
																	Volume dalam Cm
																</FormText>
																<FormFeedback className={invalid[0].volume ? "d-block" : ''}>
																	This field is required
																</FormFeedback>
															</>
														</div>
														<div className="valid-feedback">Looks good!</div>
													</FormGroup>
												}
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4">
														Deskripsi :
													</Label>
													<div className="col-xl-8 col-sm-7 description-sm">
														<Input
															type="textarea"
															name="description"
															id="description"
															value={values[0].description}
															onChange={handleChange}
															rows="5"
														/>
													</div>
												</FormGroup>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4">
														Gambar :
													</Label>
													<div className="col-xl-8 col-sm-7 description-sm">
														<div className="add-product">
															<ul className="file-upload-product">
																{dummyimgs.map((res, i) => {
																	return (
																		<li key={i}>
																			<div className="box-input-file">
																				<Input
																					className="upload"
																					type="file"
																					onChange={(e) => _handleImgChange(e, i)}
																				/>
																				<img
																					alt=""
																					src={res.img ? res.img : imgUser}
																					style={{ width: 100, height: 100 }}
																				/>
																				{res.img ? <span className="removeBtn" role="button" onClick={() => _handleImgRemove(i)}><i className="fa fa-remove" /></span> : ''}
																			</div>
																		</li>
																	);
																})}
															</ul>
														</div>
														<FormText>Image Size 1616px × 1144px</FormText>
														{invalid[0].file === 'invalid' ? <div className="form-text text-danger">
															Minimal 1 Gambar.
														</div> : ''}
													</div>
												</FormGroup>
												<FormGroup className="form-group mb-3 row">
													<Label className="col-xl-3 col-sm-4">
														Status :
													</Label>
													<div className="col-xl-8 col-sm-7 description-sm">
														<Input
															className="form-control"
															id="status"
															name="status"
															type="select"
															required=""
															onChange={(e) => handleChange(e)}
															value={values[0].status}
															invalid={invalid[0].status ? 'invalid' : ''}
														>
															<option value="Active">
																Active
															</option>
															<option value="Inactive">
																Inactive
															</option>
														</Input>
													</div>
												</FormGroup>
												{haveVariant ?
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-4">
															Daftar Varian :
														</Label>
														<div className="col-xl-8 col-sm-7 description-sm">
															<div onClick={() => setOpenModalVariant(true)} className="btn btn-secondary">
																Tambah Varian
															</div>
															<div className="table-responsive ">
																<table className="table table-bordernone mb-0">
																	<thead>
																		{is_mobile ?
																			<tr>
																				<th scope="col" className="col-1"></th>
																				<th scope="col"></th>
																				<th scope="col" className="col-1">Status</th>
																				<th scope="col"></th>
																			</tr>
																			:
																			<tr>
																				<th scope="col" className="col-1"></th>
																				<th scope="col">Nama</th>
																				<th scope="col" className="col-1">Stock</th>
																				<th scope="col" className="col-3">Harga</th>
																				<th scope="col" className="col-1">Berat</th>
																				<th scope="col" className="col-1">Status</th>
																				<th scope="col"></th>
																			</tr>
																		}
																	</thead>
																	<tbody>
																		{variant.map((e, key) => {
																			return (
																				is_mobile ?
																					<tr>
																						<th scope="col"><img src={`${process.env.REACT_APP_IMAGE_URL}${e.image}`} style={{ height: "100px" }} alt="" /></th>
																						<td >
																							Nama : {e.title}<br />
																							Stok : {e.stock}<br />
																							Harga : {numberFormat(parseInt(e.price))}<br />
																							Berat : {e.weight}
																						</td>
																						<td >{e.status}</td>
																						<th scope="col" className="col-1">
																							<div className=" text-center" role="button" onClick={() => handleEditVariant(key)}>
																								<i className="fa fa-edit" />
																							</div>
																							<div className=" text-center" role="button" onClick={() => handleRemoveVariant(key)}>
																								<i className="fa fa-trash" />
																							</div>
																						</th>
																					</tr>
																					:
																					<tr>
																						<th scope="col">
																							<img src={
																								(e.image_ ?
																									(e.image_[0] ? URL.createObjectURL(e.image_[0])
																										: URL.createObjectURL(e.image_)) : 
																										process.env.REACT_APP_IMAGE_URL + e.image
																									)}
																								style={{ height: "100px" }} alt="" />
																						</th>
																						<td >{e.title}</td>
																						<td >{e.stock}</td>
																						<td >{numberFormat(parseInt(e.price))}</td>
																						<td >{e.weight}</td>
																						<td >{e.status}</td>
																						<th scope="col" className="col-1">
																							<div className=" text-center" role="button" onClick={() => handleEditVariant(key)}>
																								<i className="fa fa-edit" />
																							</div>
																						</th>
																					</tr>
																			)
																		})}
																	</tbody>
																</table>
																<FormFeedback className={invalid[0].variant ? "d-block" : ''}>
																	This field is required
																</FormFeedback>

															</div>
														</div>
													</FormGroup> : ''}
											</div>
											<div className="offset-xl-3 offset-sm-4">
												<Button type="submit" color="primary">
													Simpan
												</Button>
												<Link to="/produk" className="btn btn-light">
													Batal
												</Link>
											</div>
										</Form>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<ModalVariant open={openModalVariant} product_slug={slug} products_id={productsId} user={user} setOpen={setOpenModalVariant} variant={variant} setVariant={setVariant} picture={picture} setPicture={setPicture} editVariant={editVariant} setEditVariant={setEditVariant} />
				<ModalCategory open={openModalCategory} setOpen={setOpenModalCategory} refetch={refetchCategoryData} user={user} />
				<ModalSubcategory open={openModalSubcategory} setOpen={setOpenModalSubcategory} refetch={refetchSubcategoryData} user={user} categoryData={categoryData} />
			</Container>
		</Fragment>
	);
};

export default Add_product;
