import React from "react";
import TotalTransactionGross from "./totalTransactionGross";
import TotalMidtransFee from "./totalMidtransFee";
import TotalWithdrawFee from "./totalWithdrawFee";
import TotalServiceFee from "./totalServiceFee";
import TotalShippingCost from "./totalShippingCost";
import { Col } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import moment from 'moment';

const GET_SUMMARY_TRANSACTION = gql`
  query (
    $stores_id: Int
    $indexFrom: Int
    $limit: Int
    $start_date: String
    $end_date: String
  ) {
    getSummaryTransactionPerMonth(
		stores_id: $stores_id
		indexFrom: $indexFrom
		limit: $limit
		start_date: $start_date
		end_date: $end_date
    ) {
		total
  		hasMore
  		items{
			periode
			total_transaksi
			subtotal
			shipping_cost
			service_fee
			shipping_fee
			midtrans_fee
			withdraw_fee
		}
    }
  }
`;
const Dashboard = ({user, startDate, endDate}) => {
	const privilege = user.privilege;
  var { data } = useQuery(GET_SUMMARY_TRANSACTION, {
    variables: {
      stores_id: user.stores_id?user.stores_id:null,
      limit: 1,
      indexFrom: 0,
      start_date: moment(startDate).format('MM/DD/YYYY'),
      end_date: moment(endDate).format('MM/DD/YYYY'),
    },
  });

/*const data = {
  getSummaryTransactionPerMonth: {
      total: 5,
      hasMore: true,
      items: [
        {
          "periode": "02-2024",
          "total_transaksi": 3,
          "subtotal": 450000,
          "shipping_cost": 25000,
          "service_fee": 45000,
          "shipping_fee": 0,
          "midtrans_fee": 0,
          "__typename": "SummaryTransactionPerMonth"
      },
      {
          "periode": "01-2024",
          "total_transaksi": 1,
          "subtotal": 50000,
          "shipping_cost": 7500,
          "service_fee": 5000,
          "shipping_fee": 0,
          "midtrans_fee": 0,
          "__typename": "SummaryTransactionPerMonth"
      }
      ],
      __typename: "SummaryTransactionPerMonthResp"
  
}}
*/

  return (
    <>
      <Col xl="6 xl-50" md="6">
        <TotalTransactionGross data={data && data.getSummaryTransactionPerMonth.items} />
      </Col>
      {privilege!=='Stores'?
      <Col xl="6 xl-50" md="6">
        {/*<TotalMidtransFee data={data && data.getSummaryTransactionPerMonth.items} />*/}
        <TotalWithdrawFee data={data && data.getSummaryTransactionPerMonth.items} />
      </Col>:''}
      {privilege!=='Stores'?
      <Col xl="6 xl-50" md="6">
        <TotalShippingCost data={data && data.getSummaryTransactionPerMonth.items} />
      </Col>:''}
      <Col xl="6 xl-50" md="6">
        <TotalServiceFee data={data && data.getSummaryTransactionPerMonth.items} />
      </Col>
    </>
  );
};

export default Dashboard;
