import React, { useEffect, useState } from "react";
import { Form, FormGroup, FormFeedback, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CREATE_CATEGORY = gql`
  mutation createCategory($input: CategoryInput) {
    createCategory(input: $input) {
        success,
        message,
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation updateCategory($input: CategoryInput) {
    updateCategory(input: $input) {
        success,
        message,
    }
  }
`;
const ModalCategory = ({ open, setOpen, refetch, user, editData }) => {
	const [values, setValues] = useState([{
		title: '',
	}]);
	const [invalid, setInvalid] = useState([{
		title: '',
	}]);
	const [addTodo] = useMutation(CREATE_CATEGORY);
	const [updateTodo] = useMutation(UPDATE_CATEGORY);

	useEffect(() => {
		setValues([{
			title: '',
		}]);
	}, []);

	useEffect(() => {
		if (editData) {
			setValues([{
				id: editData.id,
				title: editData.title,
				status: editData.status,
			}]);
		}
	}, [editData]);

	const handleChange = (event) => {
		let tmp_data = [...values];
		let tmp_invalid_data = [...invalid];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_invalid_data[0][name] = value ? '' : 'invalid';
		tmp_data[0][name] = value;
		setValues(tmp_data);
	}

	const onCloseModal = () => {
		setOpen(false);
	};

	const handleSave = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;
		if (!inputData[0]['title']) {
			valid += 1;
			invalidData[0]['title'] = 'invalid';
		} else {
			invalidData[0]['title'] = '';
		}

		if (valid > 0) {
			setInvalid(invalidData);
			return false;
		}
		inputData[0].uid = user.id;
		//setOpen(false);
		if (inputData[0].id > 0) {
			const result = await updateTodo({ variables: { input: inputData[0] } });
			if (result.data.updateCategory.success) {
				refetch()
				toast.success(result.data.updateCategory.message);
			} else {
				toast.error(result.data.updateCategory.message);
			}
		} else {
			const result = await addTodo({ variables: { input: inputData[0] } });
			if (result.data.createCategory.success) {
				refetch()
				toast.success(result.data.createCategory.message);
			} else {
				toast.error(result.data.createCategory.message);
			}
		}
		setValues([{
			title: '',
		}]);
		setInvalid([{
			title: '',
		}]);
		setOpen(false);

	};

	return (
		<Modal isOpen={open} toggle={onCloseModal}>
			<ModalHeader toggle={onCloseModal}>
				<h5
					className="modal-title f-w-600"
					id="exampleModalLabel2"
				>
					Tambah Kategori
				</h5>
			</ModalHeader>
			<Form onSubmit={handleSave} >
				<ModalBody>
					<FormGroup>
						<Label
							htmlFor="message-text"
							className="col-form-label"
						>
							Kategori :
						</Label>
						<Input
							className="form-control"
							id="title"
							name="title"
							type="text"
							value={values[0].title}
							onChange={handleChange}
							invalid={invalid[0].title ? 'invalid' : ''}
						/>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>
					{user.privilege !== 'Stores' ?
						<FormGroup>
							<Label
								htmlFor="message-text"
								className="col-form-label"
							>
								Status :
							</Label>
							<Input
								id="status"
								name="status"
								type="select"
								onChange={(e) => handleChange(e)}
								value={values[0].status}
							>
								<option value="active">
									Active
								</option>
								<option value="inactive">
									Inactive
								</option>
							</Input>
							<FormFeedback>
								This field is required
							</FormFeedback>
						</FormGroup>
						: ''}
				</ModalBody>
				<ModalFooter>
					<Button
						type="submit"
						color="primary"
					>
						Simpan
					</Button>
					<Button
						type="button"
						color="secondary"
						onClick={() => onCloseModal("VaryingMdo")}
					>
						Tutup
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export default ModalCategory;
