import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import Filter from "../../common/filter_components/filter";
import { numberFormat, orderStatus, orderStatusOptions } from "../../../helpers/custom";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const GET_STORE_ORDER = gql`
  query  getOrders(
	$cid:Int!
	$store:String
	$custName:String
	$indexFrom:Int
	$limit:Int
	$status:String
	$city:String
	$total:String
	$sortBy:String
	$store_name:String
	$order_id:String
	$start_date:String,
	$end_date:String
  ){
    getOrders(
		cid: $cid
		store: $store
		custName: $custName
		indexFrom: $indexFrom
		limit: $limit
		status: $status
		city: $city
		total: $total
		sortBy: $sortBy
		store_name: $store_name
		order_id: $order_id
		start_date: $start_date
		end_date: $end_date
	) {
		hasMore
		total
		items{
			id
			customers_id
			store_slug
			store_name
			name
			phone
			address
			detail_address
			ref_city_id
			ref_city_name
			ref_province_id
			ref_province_name
			postcode
			shipping_cost
			subtotal
			discount
			grandtotal
			purchases_date
			created_date
			payment
			status
			invoice_no
			orderList {
				id
				customers_purchases_history_id
				products_id
				products_title
				products_img{
					id
					alt
					src
				}
				products_variant_id
				products_variant_title
				products_variant_img{
					id
					alt
					src
				}
				qty
				price
				discount
				total
	  }
	  	}
    }
  }
`;


const columns = [
	{
		dataField: 'id',
		text: '#',
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '5%' };
		}
	},
	{
		dataField: 'invoice_no',
		text: 'Payment ID',
		sort: true,
	},
	{
		dataField: 'name',
		text: 'Nama',
		sort: true,
	},
	{
		dataField: 'store_name',
		text: 'Toko',
		sort: true,

	},
	{
		dataField: 'status',
		text: 'Status',
		formatter: (cell, row, rowIndex, extraData) => (
			extraData[cell]
		),
		formatExtraData: orderStatus,
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '12%' };
		}
	},
	{
		dataField: 'grandtotal',
		text: 'Total',
		sort: true,
		formatter: (cell, row, rowIndex, extraData) => (
			numberFormat(parseInt(row.subtotal)+parseInt(row.shipping_cost))
		),
		headerStyle: (colum, colIndex) => {
			return { width: '12%' };
		}
	},
	{
		dataField: 'created_date',
		text: 'Tanggal Order',
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '15%' };
		}
	},
	{
		dataField: '',
		text: '',
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/order/${row.id}`} >
					<i className="fa fa-eye" title="Lihat" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: '5%' };
		}
	}
];

const columns_mobile = [
	{
		dataField: 'id',
		text: '#',
		headerStyle: (colum, colIndex) => {
			return { width: '13%' };
		}
	},
	{
		dataField: 'invoice_no',
		text: 'Payment ID',
		sort: true,
	},
	{
		dataField: 'name',
		text: 'Nama',
		sort: true,
		formatter: (cell, row, rowIndex, extraData) => (
			<>
				<div><b>{row.name}</b></div>
				<div>{numberFormat(parseInt(row.grandtotal))}</div>
			</>
		),
	},
	{
		dataField: 'created_date',
		text: 'Tanggal Order',
		sort: true,

	},
	{
		dataField: 'status',
		text: 'Status',
		formatter: (cell, row, rowIndex, extraData) => (
			extraData[cell]
		),
		formatExtraData: orderStatus,
		sort: true,
	},
	{
		dataField: '',
		text: '',
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/order/${row.id}`} >
					<i className="fa fa-eye" title="Lihat" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: '5%' };
		}
	}
];

const List_vendors = ({ id }) => {
	const is_mobile = window.innerWidth < 750 ? true : false;
	const sizePerPage = 10;
	const user = JSON.parse(localStorage.getItem('user'));
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState({
		order_id: '',
		custName: '',
		store_name: '',
		total: '',
		date: { start: '', end: '' },
		status: '',
	})
	const [sortBy, setSortBy] = useState();
	const [defaultSorted, setDefaultSorted] = useState([{
		dataField: 'id',
		order: 'desc'
	}]);

	var { data, refetch } = useQuery(GET_STORE_ORDER, {
		variables: {
			cid: parseInt(id),
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			order_id: filter && filter.order_id ? filter.order_id : '',
			custName: filter && filter.custName ? filter.custName : '',
			store_name: filter && filter.store_name ? filter.store_name : (user.stores_name ? user.stores_name : ''),
			total: filter && filter.total ? filter.total : '',
			status: filter && filter.status ? filter.status.value : '',
			start_date: filter && filter.date ? filter.date.start : '',
			end_date: filter && filter.date ? filter.date.end : '',
			sortBy: sortBy,
		},
	});

	const handleTableChange = async (e, { filters, page: pages, sizePerPage, sortField, sortOrder, data }) => {
		if (pages) {
			setPage(pages)
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + '-' + sortOrder)
			setDefaultSorted([{
				dataField: sortField,
				order: sortOrder
			}])
		}
	}

	const handleFilterChange = (e) => {
		refetch({
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			order_id: filter && filter.order_id ? filter.order_id : '',
			custName: filter && filter.custName ? filter.custName : '',
			store_name: filter && filter.store_name ? filter.store_name : (user.stores_name ? user.stores_name : ''),
			total: filter && filter.total ? filter.total : '',
			status: filter && filter.status ? filter.status.value : '',
			start_date: filter && filter.date ? filter.date.start : '',
			end_date: filter && filter.date ? filter.date.end : '',
			sortBy: sortBy,
		})
		setFilter(e);
	}

	const filterOptions = [
		{
			id: 'order_id',
			label: 'Payment ID',
			value: filter.order_id,
			type: 'text',
		},
		{
			id: 'custName',
			label: 'Nama',
			value: filter.custName,
			type: 'text',
		},
		{
			id: 'store_name',
			label: 'Toko',
			value: filter.store_name,
			type: 'text',
		},
		{
			id: 'total',
			label: 'Total',
			value: filter.total,
			type: 'text',
		},
		{
			id: 'date',
			label: 'Date',
			value: filter.date,
			type: 'date',
			className: 'first-column',
		},
		{
			id: 'status',
			label: 'Status',
			value: filter.status,
			type: 'dropdown',
			options: orderStatusOptions
		},
	]

	/*const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		refetch({
			cid: parseInt(id),
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			custName: filters && filters.name ? filters.name.filterVal : '',
			city: filters && filters.ref_city_name ? filters.ref_city_name.filterVal : '',
			total: filters && filters.grandtotal ? filters.grandtotal.filterVal : '',
			status: filters && filters.status ? filters.status.filterVal : '',
			sortBy: sortField + '' + sortOrder
		})
	}*/

	return (
		<>
			<h5 className="f-w-600 f-16">Customer Order</h5>
			<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} />
			<div
				className="category-table order-list order-table coupon-list-delete"
			>

				{data ? <BootstrapTable
					keyField='id'
					hover
					condensed
					remote={{ sort: true, pagination: true }}
					data={data && data.getOrders ? data.getOrders.items : []}
					columns={is_mobile ? columns_mobile : columns}
					bordered={false}
					noDataIndication="Table is Empty"
					pagination={paginationFactory(
						{
							sizePerPage: sizePerPage,
							page: page,
							hideSizePerPage: true,
							hidePageListOnlyOnePage: true,
							totalSize: data && data.getOrders ? data.getOrders.total : 0
						})}
					defaultSorted={defaultSorted}
					wrapperClasses="table-responsive"
					onTableChange={handleTableChange}
				/> : ''}
			</div>
		</>
	);
};

export default List_vendors;
