import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import moment from "moment/moment";
import Chat from "./common/chat";

const GET_CHAT_LIST = gql`
  query getWhatsappContact{
    getWhatsappContact{
		name
  		wa_id
		messages
  		last_message_date
  		unread
    }
  }
`;

const List_vendors = () => {
	const sizePerPage = 10;
	const [waId, setWaId] = useState('');
	const [newChat, setNewChat] = useState(false);
	const [selected, setSelected] = useState('');
	const [isMobile, setIsMobile] = useState(false);
	const [isMobileShowChat, setIsMobileShowChat] = useState(false);

	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	var { data, refetch } = useQuery(GET_CHAT_LIST);

	useEffect(() => {
		if (!user || user.privilege === 'Stores') {
			return navigate("/dashboard", { replace: true });
		}
		if (window.innerWidth < 750) {
		setIsMobile(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect( () => {
		  const intervalID = setInterval(async () => {
			await refetch()
		  }, 3000);
	  return () => clearInterval(intervalID);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	useEffect( () => {
		if(waId){
			setNewChat(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	const handleChatChange = (wa_id) => {
		setWaId(wa_id);
		setSelected(wa_id);
		setIsMobileShowChat(true);
	}
	
	return (
		<Fragment>
			<Breadcrumb title="Whatsapp" />
			<Container fluid={true}>
				<Card>
					{data ?
						<div className="whatsapp">
							<div className="col-12 col-md-3 whatsapp-contact">
								{data.getWhatsappContact && data.getWhatsappContact.map(e => {
									return (
										<div className={`whatsapp-contact_content ${selected===e.wa_id?'selected':''}`} role="button" onClick={()=>handleChatChange(e.wa_id)}>
											<div className="whatsapp-contact_content_1">
												<div>{e.name}</div>
												<div>{moment.unix(e.last_message_date).format('D-MM-Y')} </div>
											</div>
											<div className="whatsapp-contact_content_2">
												<div>{e.messages.slice(0, 20)}</div>
												{e.unread>0?<span>{e.unread}</span>:''}
											</div>
										</div>
									)
								})}
							</div>
							<div className={`col-12 col-md-9 whatsapp-chat ${isMobile?(isMobileShowChat?'mobile': 'mobile hide'):''}`}>
								<Chat wa_id={waId} setWaId={setWaId} newChat={newChat} setNewChat={setNewChat} isMobile={isMobile} setIsMobileShowChat={setIsMobileShowChat}/>
							</div>
						</div>
						: ''}
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_vendors;
