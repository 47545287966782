import React, { useState, useEffect } from "react";
import { Table, Spinner } from "reactstrap";
import { useQuery, gql } from '@apollo/client';
import { numberFormat } from "../../../helpers/custom";
import moment from 'moment';
import { Link } from "react-router-dom";

const GET_ORDERS = gql`
  query getOrders(
	$indexFrom:Int 
	$limit:Int 
	$status:String
	$store:String
  ) {
    getOrders(
		indexFrom: $indexFrom
		limit: $limit
		status: $status
		store: $store
    ) {
      total
      hasMore
      items {
		id
		order_id
		customers_name
		name
		subtotal
		shipping_cost
		discount
		created_date
      }
    }
  }
`;
const OrderComplaint = ({user, loadData}) => {
    const [data, setData] = useState(0);
    var { loading, refetch } = useQuery(GET_ORDERS, {
        variables: {
            indexFrom: 0,
            limit: 5,
            status: 'Complaint',
            store: user.stores_slug
        },
    });
    useEffect(()=>{
        const fetchData = async () => {
            const data = await refetch({
                indexFrom: 0,
                limit: 5,
                status: 'Complaint',
            store: user.stores_slug
        });
            setData(data.data);
        }

        if(loadData === 'load'){
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadData])

    return (
        <div className="user-status table-responsive latest-order-table">
            <Table borderless>
                <thead>
                    <tr>
                        <th scope="col">Order ID</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Order Total</th>
                        <th scope="col">Order Date</th>
                    </tr>
                </thead>
                <tbody>
                    {!loading?
                    data && data.getOrders && data.getOrders.items.length>0? data.getOrders.items.map(e => {
                        return (
                            <tr>
                                <td><Link to={`/order/${e.id}`} className="text-black">{e.order_id}</Link></td>
                                <td className="">{e.name}</td>
                                <td className="digits">{numberFormat(e.subtotal+e.shipping_cost)}</td>
                                <td className="digits">{moment(e.created_date).format('D MMM YY')}</td>
                            </tr>
                        )
                    }):
                    <tr>
                        <td colSpan="4" className="text-center">Data Empty</td>
                    </tr>
                    :
                    <tr>
                        <td colSpan="4" className="text-center"><Spinner/></td>
                    </tr>}
                </tbody>
            </Table>
        </div>
    );
};

export default OrderComplaint;
