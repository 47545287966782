export function numberFormat(value) {
  return value.toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
}

export function phoneCheck(phone) {
  phone = String(phone).trim();
  if (phone.startsWith('+628')) {
    return true
  } else if (phone.startsWith('628')) {
    return true
  } else if (phone.startsWith('08')) {
    return true
  } else {
    return false
  }
}

export const orderStatus ={
    Pending: 'Belum di bayar',
    Paid: 'Sudah di bayar',
    Accepted: 'Sedang di proses',
    OTW: 'Sedang dalam perjalanan',
    Received: 'Sudah Sampai',
    Finish: 'Selesai',
    Canceled: 'Dibatalkan',
  };

export const orderStatusOptions = 
  [
    {
      label: 'Show All',
      value: '',
    },
    {
      label: 'Belum di bayar',
      value: 'Pending',
    },
    {
      label: 'Sudah di bayar',
      value: 'Paid',
    },
    {
      label: 'Sedang di proses',
      value: 'Accepted',
    },
    {
      label: 'Sedang dalam perjalanan',
      value: 'OTW',
    },
    {
      label: 'Sudah Sampai',
      value: 'Received',
    },
    {
      label: 'Kompalin',
      value: 'Complaint',
    },
    {
      label: 'Selesai',
      value: 'Finish',
    },
    {
      label: 'Dibatalkan',
      value: 'Canceled',
    },
    {
      label: 'Dikembalikan',
      value: 'Refund',
    }
  ];
