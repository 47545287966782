import React, { useEffect, useState } from "react";
import SelectField from './selectField.js'
import TextField from './textfield.js'
import DateField from './dateField.js'
import DateFieldMonthYear from './dateFieldMonthYear.js'
import DateFieldWithRange from './dateFieldWithRange.js'

const Breadcrumb = ({ filterOptions, filter, handleFilterParentChange, range }) => {
    const [showOpenFilter, setShowOpenFilter] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);

    useEffect(e => {
        if (filterOptions.length > 5) {
            setShowOpenFilter(true);
        }
    }, [(filterOptions)]);

    useEffect(e => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filter)]);

    const handleOpenCloseFilter = (e) => {
        setOpenFilter(!openFilter);
    }

    const handleResetFilter = (e) => {
        let filters = filter;
        filterOptions.forEach((e, index) => {
            if(e.id === 'date'){
            filters[e.id]['start'] = '';
            filters[e.id]['end'] = '';
            }else{
            filters[e.id] = '';
            }
        });
        handleFilterParentChange(filters)
    }

    const handleFilterChange = (e) => {
        handleFilterParentChange(e)
    }
    return (
        <div className="d-flex pb-4">
            <div className={`col row row-cols-5 row-cols-lg-5 g-2 ${openFilter ? 'filter-open' : 'filter-close'}`}>
                {filterOptions.map((e, index) => {
                    return <>
                        {e.type === 'text' ?
                            <TextField cssClass={`${index > 4 ? (openFilter ? 'd-block' : 'd-none') : ''}`} options={e} filter={filter} handleFilterChange={handleFilterChange} />
                            :
                            (e.type === 'dropdown' ?
                                <SelectField cssClass={`${index > 4 ? (openFilter ? 'd-block' : 'd-none') : ''}`} options={e} filter={filter} handleFilterChange={handleFilterChange} />
                                :
                                (e.type === 'date' ?
                                    <DateField classes={e.className} cssClass={`${index > 4 ? (openFilter ? 'd-block' : 'd-none') : ''}`} options={e} filter={filter} handleFilterChange={handleFilterChange} />
                                    :
                                    (e.type === 'dateMonthYear' ?
                                        <DateFieldMonthYear classes={e.className} cssClass={`${index > 4 ? (openFilter ? 'd-block' : 'd-none') : ''}`} options={e} filter={filter} handleFilterChange={handleFilterChange} />
                                        : (e.type === 'dateWithRange' ?
                                            <DateFieldWithRange classes={e.className} cssClass={`${index > 4 ? (openFilter ? 'd-block' : 'd-none') : ''}`} options={e} filter={filter} handleFilterChange={handleFilterChange} range={range} />
                                            : '')
                                    )
                                )
                            )}
                    </>
                })}
            </div>
            <div className="col-1 w-2/12 d-flex justify-content-between ">
                {showOpenFilter ? <div className="col text-center pt-2" onClick={handleOpenCloseFilter}><i className={`fa ${openFilter ? 'fa-angle-up' : 'fa-angle-down'} fs-2`} role="button" /></div> : ''}
                <div className="col text-center pt-2" onClick={handleResetFilter}><i className="fa fa-refresh fs-5" role="button" /></div>
            </div>
        </div>
    );
};

export default Breadcrumb;
