import React, { Fragment, useState, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Button, Form, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { phoneCheck } from "../../../helpers/custom";


const GET_INFO = gql`
  query ($id: Int!) {
  	getCustomer(id: $id) {
		id
		name
		email
		phone
	}
  }
`;

const UPDATE_STORE = gql`
  mutation updateCustomer($input: CustomerInput) {
    updateCustomer(input: $input) {
        success,
        message,
    }
  }
`;

const TabsetUser = ({ id, isPublic }) => {
	let navigate = useNavigate();
	const [values, setValues] = useState([{
		id: 0,
		name: '',
		email: '',
		phone: '',
	}]);
	const [invalid, setInvalid] = useState([{
		name: '',
		email: '',
		phone: '',
	}]);

	const [updateTodo] = useMutation(UPDATE_STORE);

	var { data } = useQuery(GET_INFO, {
		variables: {
			id: id ? parseInt(id) : null,
		},
	});

	useEffect(() => {
		if (data && data.getCustomer) {
			const tmp_data = {
				id: data.getCustomer.id,
				name: data.getCustomer.name,
				email: data.getCustomer.email,
				phone: data.getCustomer.phone,
			}
			setValues([tmp_data])
		}
	}, [data])

	const handleChange = (event) => {
		let tmp_data = [...values];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_data[0][name] = value;
		setValues(tmp_data);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;
		if (!inputData[0]['name']) {
			valid += 1;
			invalidData[0]['name'] = 'invalid';
		} else {
			invalidData[0]['name'] = '';
		}
		var emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

		if (!inputData[0]['email'] || !emailRegex.test(inputData[0]['email'])) {
			valid += 1;
			invalidData[0]['email'] = 'invalid';
		} else {
			invalidData[0]['email'] = '';
		}
		if (!inputData[0]['phone'] || !Number.isInteger(parseInt(inputData[0]['phone']))) {
			valid += 1;
			invalidData[0]['phone'] = 'This field is required';
		} else {
			if (phoneCheck(inputData[0]['phone'])) {
				invalidData[0]['phone'] = '';
			} else {
				valid += 1;
				invalidData[0]['phone'] = 'Please insert valid phone';
			}
		}

		if (valid > 0) {
			let invalidData = [...invalid];
			invalidData[0]['src'] = 'invalid';
			setInvalid(invalidData);
			return false;
		}
		inputData[0].id = parseInt(inputData[0].id);
		if (inputData[0].id > 0) {
			const result = await updateTodo({ variables: { input: inputData[0] } });
			if (result.data.updateCustomer.success) {
				toast.success(result.data.updateCustomer.message)
				return navigate("../customer/" + id, { replace: true });
			} else {
				toast.error(result.data.updateCustomer.message)
			}
		} 
	};

	return (
		<Fragment>
			<Tabs>
				<TabPanel>
					<Form className="needs-validation user-add" noValidate="" onSubmit={handleSubmit}>

						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Nama
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="name"
									name="name"
									type="text"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].name}
									invalid={invalid[0].name ? 'invalid' : ''}
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Email
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="email"
									name="email"
									type="email"
									onChange={(e) => handleChange(e)}
									value={values[0].email}
									invalid={invalid[0].email ? 'invalid' : ''}
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Telp
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="phone"
									name="phone"
									type="text"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].phone}
									invalid={invalid[0].phone ? 'invalid' : ''}
								/>
								<FormFeedback>
									{invalid[0].phone ? invalid[0].phone : 'This field is required'}
								</FormFeedback>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<div className="pull-right">
								<Button type="submit" color="primary">
									Save
								</Button>
							</div>
						</FormGroup>
					</Form>
				</TabPanel>
			</Tabs>
		</Fragment>
	);
};

export default TabsetUser;
