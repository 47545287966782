import React, { Fragment } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import App from '../components/app'
import Datatable from '../components/common/datatable'
import Createcoupons from '../components/coupons/create-coupons'
import ListCoupons from '../components/coupons/list-coupons'
import Dashboard from '../components/dashboard'
import DashboardSample from '../components/dashboard/sample'
import Invoice from '../components/invoice'
import Rates from '../components/localization/rates'
import Taxes from '../components/localization/taxes'
import Translations from '../components/localization/translations'
import Media from '../components/media/media'
import Createmenu from '../components/menus/create-menu'
import Listmenu from '../components/menus/list-menu'
import Createpage from '../components/pages/create-page'
import ListPages from '../components/pages/list-page'
//import Digitaladdpro from '../components/products/digital/digital-add-pro'
//import Digitalcategory from '../components/products/digital/digital-category'
//import Digitalprolist from '../components/products/digital/digital-pro-list'
//import Digitalsubcategory from '../components/products/digital/digital-sub-category'
import Addproduct from '../components/produk/add-product'
import Category from '../components/produk/category'
import Productdetail from '../components/produk/product-detail'
import Productlist from '../components/produk/index'
import Productcategory from '../components/produk/category'
import ProductSubcategory from '../components/produk/sub-category'
import Subcategory from '../components/produk/sub-category'
import Reports from '../components/reports/report'
import Orders from '../components/sales/orders'
import Transactionsales from '../components/sales/transactions-sales'
import Profile from '../components/settings/profile'
import ProfileFormUser from '../components/settings/form-user'
import ProfileFormStore from '../components/settings/form-toko'
import Createuser from '../components/users/create-user'
import Listuser from '../components/users/list-user'
import Createtoko from '../components/toko/create-toko'
import Detailtoko from '../components/toko/detail-toko'
import Listtoko from '../components/toko/list-toko'
import Pendaftarantoko from '../components/pendaftaran-toko/list-toko'
import CreatePendaftarantoko from '../components/pendaftaran-toko/create-toko'
import ListBanner from '../components/banner/banner'
import FormBanner from '../components/banner/form-banner'
import Orderdetail from '../components/order/order-detail'
import OrderList from '../components/order/index'
import Whatsapp from '../components/whatsapp/index'
import CreateCustomer from '../components/customer/create-customer'
import DetailCustomer from '../components/customer/detail-customer'
import ListCustomer from '../components/customer/list-customer'
import Saldo from '../components/saldo/index'
import SaldoWithdraw from '../components/saldo/withdraw_list'
import SaldoWithdrawForm from '../components/saldo/withdraw_form'
import SaldoMutasi from '../components/saldo/mutasi'
import SettingsListShipping from '../components/settings/shipping/list'
import SettingsDetailShipping from '../components/settings/shipping/detail'
import ServiceFee from '../components/settings/service-fee/list'
import WithdrawFee from '../components/settings/withdraw-fee/list'

import ReportsIncome from '../components/reports/income/list'
import ReportsIncomeDetail from '../components/reports/income/detail'
import ReportsExpense from '../components/reports/expense/list'
import ReportsExpenseDetail from '../components/reports/expense/detail'
import PageNotFound from '../components/errorPage'

import Refund from '../components/refund/index'

import CheckoutLink from '../components/checkoutLink/index'
import CheckoutLinkForm from '../components/checkoutLink/form'

//import ListShipping from '../components/shipping/list'
//import Tarif from '../components/settings/tarif'

const LayoutRoutes = () => {
	if (localStorage.getItem('user') === 'false' || localStorage.getItem('user') === null) {
		return <Navigate to="/" replace={true} />
	}
	return (
		<Fragment>
			<Routes>
				<Route element={<App />}>
					<Route
						path={`${process.env.PUBLIC_URL}/dashboard`}
						element={<Dashboard />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/dashboard/sample`}
						element={<DashboardSample />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/produk/category`}
						element={<Category />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/produk/subcategory`}
						element={<Subcategory />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/produk`}
						element={<Productlist />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/produk/:slug`}
						element={<Productdetail />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/produk/form/:slug`}
						element={<Addproduct />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/produk/kategori`}
						element={<Productcategory />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/produk/subkategori`}
						element={<ProductSubcategory />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/banner/`}
						element={<ListBanner />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/banner/edit/:id`}
						element={<FormBanner />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/order`}
						element={<OrderList />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/order/:id`}
						element={<Orderdetail />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/order/form/:id`}
						element={<Addproduct />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/whatsapp`}
						element={<Whatsapp />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/sales/orders`}
						element={<Orders />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/sales/transactions`}
						element={<Transactionsales />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/coupons/list-coupons`}
						element={<ListCoupons />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/coupons/create-coupons`}
						element={<Createcoupons />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/pages/list-page`}
						element={<ListPages />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/pages/create-page`}
						element={<Createpage />}
					/>

					<Route path={`${process.env.PUBLIC_URL}/media`} element={<Media />} />

					<Route
						path={`${process.env.PUBLIC_URL}/menus/list-menu`}
						element={<Listmenu />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/menus/create-menu`}
						element={<Createmenu />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/users`}
						element={<Listuser />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/users/form/:id`}
						element={<Createuser />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/toko`}
						element={<Listtoko />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/toko/:slug`}
						element={<Detailtoko />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/toko/form/:slug`}
						element={<Createtoko />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/pendaftaran-toko`}
						element={<Pendaftarantoko />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/pendaftaran-toko/:id`}
						element={<CreatePendaftarantoko />}
					/>
					
					<Route
						path={`${process.env.PUBLIC_URL}/customer`}
						element={<ListCustomer />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/customer/:id`}
						element={<DetailCustomer />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/customer/form/:id`}
						element={<CreateCustomer />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/saldo`}
						element={<Saldo />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/saldo/withdraw`}
						element={<SaldoWithdraw />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/saldo/withdraw/form/:id`}
						element={<SaldoWithdrawForm />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/saldo/mutasi`}
						element={<SaldoMutasi />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/refund`}
						element={<Refund />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/checkout-link`}
						element={<CheckoutLink />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/checkout-link/form/:id`}
						element={<CheckoutLinkForm
						/>}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/settings/shipping`}
						element={<SettingsListShipping />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/settings/shipping/:id`}
						element={<SettingsDetailShipping />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/settings/service-fee`}
						element={<ServiceFee />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/settings/withdraw-fee`}
						element={<WithdrawFee />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/reports/income`}
						element={<ReportsIncome />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/reports/income/:periode`}
						element={<ReportsIncomeDetail />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/reports/expense`}
						element={<ReportsExpense />}
					/>


					<Route
						path={`${process.env.PUBLIC_URL}/reports/expense/:periode`}
						element={<ReportsExpenseDetail />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/localization/transactions`}
						element={<Translations />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/localization/currency-rates`}
						element={<Rates />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/localization/taxes`}
						element={<Taxes />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/reports/report`}
						element={<Reports />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/profile`}
						element={<Profile />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/profile/user/edit`}
						element={<ProfileFormUser />}
					/>
					<Route
						path={`${process.env.PUBLIC_URL}/profile/store/edit`}
						element={<ProfileFormStore />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/invoice`}
						element={<Invoice />}
					/>

					<Route
						path={`${process.env.PUBLIC_URL}/data-table`}
						element={<Datatable />}
					/>

					<Route path="/*" element={<PageNotFound />} />
				</Route>
			</Routes>
		</Fragment>
	)
}

export default LayoutRoutes