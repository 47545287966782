import React, { Fragment, useState, useEffect, useRef } from "react";
import { Button, Col, Form, FormGroup, FormFeedback, Input, Label, Row, Card, CardBody, Container, FormText } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CREATE_BANNER = gql`
  mutation createBanner($input: CreateBannerInput) {
    createBanner(input: $input) {
        success,
        message,
		id
    }
  }
`;

const UPDATE_BANNER = gql`
  mutation updateBanner($input: CreateBannerInput) {
    updateBanner(input: $input) {
        success,
        message,
    }
  }
`;

const GET_BANNER = gql`
  query getBanner($id: Int!) {
    getBanner(id: $id) {
	  id
	  mobile_src
      src
      page
      title
      description
      link
      button_title
      status
	  url
    }
  }
`;

const Create_user = ({ isPublic }) => {
	let { id } = useParams();
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	const form = useRef(null);
	const [showTitle, setShowTitle] = useState(true);
	const [showDescription, setShowDescription] = useState(true);
	const [showButton, setShowButton] = useState(true);
	const [showUrl, setShowUrl] = useState(true);
	const [values, setValues] = useState([{
		id: 0,
		title: '',
		description: '',
		page: 'home',
		mobile_src: '',
		src_: '',
		link: '',
		button_title: '',
		url: '',
		status: 'active',
		uid: 1,
	}]);
	const [invalid, setInvalid] = useState([{
		page: '',
		src: '',
		status: '',
	}]);
	const [addTodo] = useMutation(CREATE_BANNER);
	const [updateTodo] = useMutation(UPDATE_BANNER);

	var { data } = useQuery(GET_BANNER, {
		variables: {
			id: parseInt(id),
		},
	});

	useEffect(() => {
		if (data && data.getBanner) {
			if (data.getBanner.page === 'home') {
				setShowTitle(true);
				setShowDescription(true);
				setShowButton(true);
				setShowUrl(true);
			} else {
				setShowTitle(false);
				setShowDescription(false);
				setShowButton(false);
				setShowUrl(false);
			}
			setValues([{
				id: data.getBanner.id,
				title: data.getBanner.title,
				description: data.getBanner.description,
				page: data.getBanner.page,
				mobile_src: '',
				mobile_src_: process.env.REACT_APP_IMAGE_URL + data.getBanner.mobile_src,
				preview_mobile_src_: process.env.REACT_APP_IMAGE_URL + data.getBanner.mobile_src,
				src: '',
				src_: process.env.REACT_APP_IMAGE_URL + data.getBanner.src,
				preview_src_: process.env.REACT_APP_IMAGE_URL + data.getBanner.src,
				link: data.getBanner.link,
				url: data.getBanner.url,
				button_title: data.getBanner.button_title,
				status: data.getBanner.status,
				uid: user.id,
			}]);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	const handleChange = (event) => {
		let tmp_data = [...values];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_data[0][name] = value;
		if (target.id === 'mobile_src') {
			tmp_data[0]['mobile_src_'] = target.type === 'file' ? target.files : tmp_data[0].src_;
			tmp_data[0]['preview_mobile_src_'] = (URL.createObjectURL(target.files[0]));
			//tmp_data[0]['mobile_src_'] = URL.createObjectURL(target.type === 'file' ? target.files : tmp_data[0].src_);
		}
		if (target.id === 'src') {
			tmp_data[0]['src_'] = target.type === 'file' ? target.files : tmp_data[0].src_;
			tmp_data[0]['preview_src_'] = (URL.createObjectURL(target.files[0]));
			//tmp_data[0]['mobile_src_'] = URL.createObjectURL(target.type === 'file' ? target.files : tmp_data[0].src_);
		}
		if (target.id === 'page') {
			if (value === 'home') {
				setShowTitle(true);
				setShowDescription(true);
				setShowButton(true);
				setShowUrl(true);
			} else {
				setShowTitle(false);
				setShowDescription(false);
				setShowButton(false);
				setShowUrl(false);
			}
		}
		//tmp_data[0]['src_'] = target.type === 'file' ? target.files : tmp_data[0].src_;
		setValues(tmp_data);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		let inputData = values;
		let valid = 0;
		let invalidData = [...invalid];
		if (!inputData[0]['id']) {
			if (!inputData[0]['src_'][0]) {
				invalidData[0]['src'] = 'invalid';
				valid += 1;
			} else {
				invalidData[0]['src'] = '';
			}

			if (!inputData[0]['mobile_src_'][0]) {
				invalidData[0]['mobile_src'] = 'invalid';
				valid += 1;
			} else {
				invalidData[0]['mobile_src'] = '';
			}

			if (valid > 0) {
				setInvalid(invalidData);
				return false;
			}
		}
		inputData[0]['file'] = inputData[0]['src_'][0]
		inputData[0]['file_mobile'] = inputData[0]['mobile_src_'][0]
		delete (inputData[0]['src']);
		delete (inputData[0]['src_']);
		delete (inputData[0]['preview_src_']);
		delete (inputData[0]['mobile_src']);
		delete (inputData[0]['mobile_src_']);
		delete (inputData[0]['preview_mobile_src_']);
		//return false;
		// eslint-disable-next-line eqeqeq
		if (id == 0) {
			const result = await addTodo({ variables: { input: inputData[0] } });
			if (result.data.createBanner.success) {
				toast.success("Successfully Created !");
				return navigate("/banner");
			} else {
				toast.error("Failed Created !");
			}
		} else {
			if (typeof inputData[0]['file'] !== 'object') {
				delete (inputData[0]['file']);
			}
			if (typeof inputData[0]['file_mobile'] !== 'object') {
				delete (inputData[0]['file_mobile']);
			}
			const result = await updateTodo({ variables: { input: inputData[0] } });
			if (result.data.updateBanner.success) {
				toast.success(result.data.updateBanner.message);
				return navigate("/banner");
			} else {
				toast.error(result.data.updateBanner.message);
			}
		}

	};

	return (
		<Fragment>
			<Breadcrumb title="Add Banner" parent="Banner" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Form ref={form} className="needs-validation user-add" noValidate="" onSubmit={handleSubmit}>
									<Input type="hidden" id="id" name="id" value={values[0].id} />
									<Input type="hidden" id="uid" name="uid" value={values[0].uid} />
									<FormGroup className="row">
										<Label className="col-xl-3 col-md-4">
											<span>*</span> Page
										</Label>
										<div className="col-xl-8 col-md-7">
											<Input
												id="page"
												name="page"
												type="select"
												onChange={(e) => handleChange(e)}
												value={values[0].page}
												invalid={invalid[0].page ? 'invalid' : ''}
											>
												<option value="home">
													Home
												</option>
												<option value="shop">
													Shop
												</option>
											</Input>
											<FormFeedback>
												This field is required
											</FormFeedback>
										</div>
									</FormGroup>
									{showTitle ?
										<FormGroup className="row">
											<Label className="col-xl-3 col-md-4">
												Title
											</Label>
											<div className="col-xl-8 col-md-7">
												<Input
													className="form-control"
													id="title"
													name="title"
													type="text"
													required=""
													onChange={(e) => handleChange(e)}
													value={values[0].title}
												/>
											</div>
										</FormGroup> : ''}
									{showDescription ?
										<FormGroup className="row">
											<Label className="col-xl-3 col-md-4">
												Description
											</Label>
											<div className="col-xl-8 col-md-7">
												<Input
													className="form-control"
													id="description"
													name="description"
													type="textarea"
													required=""
													onChange={(e) => handleChange(e)}
													value={values[0].description}
												/>
											</div>
										</FormGroup> : ''}
									{showButton ?
										<FormGroup className="row">
											<Label className="col-xl-3 col-md-4">
												Button Text
											</Label>
											<div className="col-xl-8 col-md-7">
												<Input
													className="form-control"
													id="button_title"
													name="button_title"
													type="text"
													required=""
													onChange={(e) => handleChange(e)}
													value={values[0].button_title}
												/>
											</div>
										</FormGroup> : ''}
									{showUrl ?
										<FormGroup className="row">
											<Label className="col-xl-3 col-md-4">
												Button Url
											</Label>
											<div className="col-xl-8 col-md-7">
												<Input
													className="form-control"
													id="link"
													name="link"
													type="url"
													required=""
													onChange={(e) => handleChange(e)}
													value={values[0].link}
												/>
											</div>
										</FormGroup> : ''}
									<FormGroup className="row">
										<Label className="col-xl-3 col-md-4">
											<span>*</span> Desktop Image
										</Label>
										<div className="col-xl-8 col-md-7">
											<img src={values[0].preview_src_} width="150px" alt="" />
											<Input
												className="form-control"
												id="src"
												name="src"
												type="file"
												required=""
												onChange={(e) => handleChange(e)}
												value={values[0].src}
												accept="image/png, image/gif, image/jpeg"
												invalid={invalid[0].src ? 'invalid' : ''}
											/>
											{values[0].page === 'home' ?
												<FormText>Desktop Image Size 1519px x 530px</FormText>
												:
												<FormText>Desktop Image Size 720px x 900px</FormText>
											}
											<FormFeedback>
												This field is required.
											</FormFeedback>
										</div>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-xl-3 col-md-4">
											<span>*</span> Mobile Image
										</Label>
										<div className="col-xl-8 col-md-7">
											<img src={values[0].preview_mobile_src_} width="150px" alt=""/>
											<Input
												className="form-control"
												id="mobile_src"
												name="mobile_src"
												type="file"
												required=""
												onChange={(e) => handleChange(e)}
												value={values[0].mobile_src}
												accept="image/png, image/gif, image/jpeg"
												invalid={invalid[0].mobile_src ? 'invalid' : ''}
											/>
											{values[0].page === 'home' ?
												<FormText>Mobile Image Size 390px x 465px</FormText>
												:
												<FormText>Mobile Image Size 245px x 306px</FormText>
											}
											<FormFeedback>
												This field is required.
											</FormFeedback>
										</div>
									</FormGroup>
										<FormGroup className="row">
											<Label className="col-xl-3 col-md-4">
												Url
											</Label>
											<div className="col-xl-8 col-md-7">
												<Input
													className="form-control"
													id="url"
													name="url"
													type="text"
													required=""
													onChange={(e) => handleChange(e)}
													value={values[0].url}
												/>
											</div>
										</FormGroup>
									<FormGroup className="row">
										<Label className="col-xl-3 col-md-4">
											<span>*</span> Status
										</Label>
										<div className="col-xl-8 col-md-7">
											<Input
												id="status"
												name="status"
												type="select"
												onChange={(e) => handleChange(e)}
												value={values[0].status}
											>
												<option value="active">
													Active
												</option>
												<option value="inactive">
													Inactive
												</option>
											</Input>
											<FormFeedback>
												This field is required
											</FormFeedback>
										</div>
									</FormGroup>
									<FormGroup>
										<div className="pull-right">
											<Button type="submit" color="primary">
												Save
											</Button>
										</div>
									</FormGroup>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Create_user;
