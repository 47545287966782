import React, { Fragment, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Unlock } from "react-feather";
import { useNavigate, useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useMutation, gql } from '@apollo/client';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FORGOT_PASSWORD = gql`
  mutation forgotPasswordUser($email: String!) {
    forgotPasswordUser(email: $email) {
        success,
        message,
    }
  }
`;


const LoginTabset = ({ isPublic }) => {
	const history = useNavigate();
	const [addTodo] = useMutation(FORGOT_PASSWORD);

	const clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove("show");
		event.target.classList.add("show");
	};

	const routeChange = () => {
		history(`${process.env.PUBLIC_URL}/dashboard`);
	};

	useEffect(() => {
		if (localStorage.getItem('user') !== 'false' && localStorage.getItem('user') !== null) {
			history("/dashboard");
		}
	}, []);
	const handleSubmit = async (event) => {
		event.preventDefault();
		const inputData = {
			email: event.target.email.value,
		};
		var valid = 0;
		if (!inputData.email) {
			toast.error("Please enter your Email !");
			valid += 1;
		}
		if (valid > 0) {
			return false;
		}

		const result = await addTodo({ variables: { email: event.target.email.value } });
		if (!result.data.forgotPasswordUser.success) {
			toast.error(result.data.forgotPasswordUser.message);
		} else {
			toast.success(result.data.forgotPasswordUser.message);
			history("/");
		}
	}
	return (
		<div>
			<Fragment>
				<Tabs>
					<TabList className="nav nav-tabs tab-coupon">
						<Tab className="nav-link" onClick={(e) => clickActive(e)}>
							<User />
							Forgot Password
						</Tab>
					</TabList>

					<TabPanel>
						<Form className="form-horizontal auth-form" onSubmit={handleSubmit}>
							<FormGroup>
								<Input
									required=""
									name="email"
									type="email"
									className="form-control"
									placeholder="Email"
									id="email"
								/>
							</FormGroup>
							<div className="form-terms">
								<div className="custom-control custom-checkbox me-sm-2">
									<Label className="d-block">
										<span className="pull-right">
											<a href="/" className="btn btn-default forgot-pass p-0">
												Ingat password?
											</a>
										</span>
									</Label>
								</div>
							</div>
							<div className="form-button">
								<Button
									color="primary"
									type="submit"
								>
									Pulihkan
								</Button>
							</div>
						</Form>
					</TabPanel>
					{/*<TabPanel>
						<Form className="form-horizontal auth-form">
							<FormGroup>
								<Input
									required=""
									name="login[username]"
									type="email"
									className="form-control"
									placeholder="Username"
									id="exampleInputEmail12"
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="login[password]"
									type="password"
									className="form-control"
									placeholder="Password"
								/>
							</FormGroup>
							<FormGroup>
								<Input
									required=""
									name="login[password]"
									type="password"
									className="form-control"
									placeholder="Confirm Password"
								/>
							</FormGroup>
							<div className="form-terms">
								<div className="custom-control custom-checkbox me-sm-2">
									<Label className="d-block">
										<Input
											className="checkbox_animated"
											id="chk-ani2"
											type="checkbox"
										/>
										I agree all statements in{" "}
										<span>
											<a href="/#">Terms &amp; Conditions</a>
										</span>
									</Label>
								</div>
							</div>
							<div className="form-button">
								<Button
									color="primary"
									type="submit"
									onClick={() => routeChange()}
								>
									Register
								</Button>
							</div>
							<div className="form-footer">
								<span>Or Sign up with social platforms</span>
								<ul className="social">
									<li>
										<a href="/#">
											<i className="icon-facebook"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-twitter-alt"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-instagram"></i>
										</a>
									</li>
									<li>
										<a href="/#">
											<i className="icon-pinterest-alt"></i>
										</a>
									</li>
								</ul>
							</div>
						</Form>
					</TabPanel>*/}
				</Tabs>
			</Fragment>
		</div>
	);
};

export default LoginTabset;
