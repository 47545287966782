import React, { useEffect, useState } from "react";
import { CreditCard } from "react-feather";
import { Card, CardBody, Media } from "reactstrap";
import moment from 'moment';
import CountUp from "react-countup";

const Income = ({ data }) => {
    const [total, setTotal] = useState();
    const [percentage, setPercentage] = useState();
    useEffect(() => {
        if (data) {
            let last_month_val = 0;
            let percentages = 0;
            let shipping_cost = data[0]?.shipping_cost?data[0].shipping_cost:0;
            let subtotal = data[0]?.subtotal?data[0].subtotal:0;
            let total = shipping_cost + subtotal;
            /*data.forEach(e => {
                if (e.periode === moment().format('MM-YYYY')) {
                    total = e.shipping_cost + e.subtotal;
                } else if (e.periode !== moment().format('MM-YYYY')) {
                    last_month_val = e.shipping_cost + e.subtotal;
                }
            })*/

            if (last_month_val === 0 && total > 0) {
                percentages = 100;
            } else if (last_month_val > 0 && total > 0) {
                percentages = ((total - last_month_val) / last_month_val) * 100;
            }
            setPercentage(percentages)
            setTotal(total)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])
    return (
        <Card className=" order-graph sales-carousel">
            <CardBody>
                <Media>
                    <Media body>
                        <span>Transactions</span>
                        <h2 className="mb-0"><CountUp className="counter" end={total} /></h2>
                        {/*<p>
                            <CountUp className="counter" end={percentage} />%{" "}
                            <span>
                                <i className={`fa ${percentage > 0 ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                            </span>
                        </p>*/}
                        <br/>
                    </Media>
                    <div className="bg-secondary b-r-8">
                        <div className="small-box">
                            <CreditCard />
                        </div>
                    </div>
                </Media>
            </CardBody>
        </Card>
    );
};

export default Income;
