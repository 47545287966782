/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, FormGroup, FormFeedback, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormText, InputGroup } from "reactstrap";
import { useQuery, gql, useMutation } from "@apollo/client";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CREATE_PRODUCT_VARIANT = gql`
  mutation createProductVariant($input: ProductVariantInput) {
    createProductVariant(input: $input) {
        success,
        message,
    }
  }
`;

const UPDATE_PRODUCT_VARIANT = gql`
  mutation updateProductVariant($input: ProductVariantInput) {
    updateProductVariant(input: $input ) {
        success,
        message,
    }
  }
`;

const ModalVariant = ({ product_slug, user,products_id, open, setOpen, variant, setVariant, picture, setPicture, editVariant, setEditVariant }) => {
	const [values, setValues] = useState([{
		title: '',
		stock: '',
		price: '',
		weight: '',
		height: '',
		width: '',
		length: '',
		image: '',
		image_: '',
		status: 'active',
	}]);
	const [invalid, setInvalid] = useState([{
		title: '',
		stock: '',
		price: '',
		weight: '',
		image: '',
		image_: '',
	}]);
	const [replaceImg, setReplaceImg] = useState(false)

	const [addTodo] = useMutation(CREATE_PRODUCT_VARIANT);
	const [updateTodo] = useMutation(UPDATE_PRODUCT_VARIANT);

	useEffect(() => {
		setValues([{
			title: '',
			stock: '',
			price: '',
			weight: '',
			image: '',
			image_: '',
			status: '',
		}]);
	}, []);

	useEffect(() => {
		if (editVariant > 0) {
			console.log(picture[editVariant - 1])
			console.log(variant[editVariant - 1])
			setValues([{
				id: variant[editVariant - 1].id ? variant[editVariant - 1].id : '',
				title: variant[editVariant - 1].title ? variant[editVariant - 1].title : '',
				stock: variant[editVariant - 1].stock ? variant[editVariant - 1].stock : (variant[editVariant - 1].qty ? variant[editVariant - 1].qty : ''),
				price: variant[editVariant - 1].price,
				weight: variant[editVariant - 1].weight,
				height: variant[editVariant - 1].height,
				width: variant[editVariant - 1].width,
				length: variant[editVariant - 1].length,
				//image: variant[editVariant-1].image,
				image_: picture[editVariant - 1],
				status: variant[editVariant - 1].status,
			}]);
		} else {
			setValues([{
				title: '',
				stock: '',
				price: '',
				weight: '',
				image: '',
				image_: '',
				status: '',
			}]);
		}
	}, [editVariant]);

	const handleChange = (event) => {
		let tmp_data = [...values];
		let tmp_invalid_data = [...invalid];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_invalid_data[0][name] = value ? '' : 'invalid';
		tmp_data[0][name] = value;
		tmp_data[0]['image_'] = target.type === 'file' ? target.files[0] : tmp_data[0].image_;
		if(target.type === 'file'){
			setReplaceImg(true)
		}

		if (name === 'length') {
			if ((!value || value == 0 || !Number.isInteger(parseInt(value)))) {
				tmp_invalid_data[0]['length'] = 'invalid';
			} else {
				tmp_invalid_data[0]['length'] = '';
			}
		}
		if (name === 'width') {
			if ((!value || value == 0 || !Number.isInteger(parseInt(value)))) {
				tmp_invalid_data[0]['width'] = 'invalid';
			} else {
				tmp_invalid_data[0]['width'] = '';
			}
		}
		if (name === 'height') {
			if ((!value || value == 0 || !Number.isInteger(parseInt(value)))
			) {
				tmp_invalid_data[0]['height'] = 'invalid';
			} else {
				tmp_invalid_data[0]['height'] = '';
			}
		}
		if (name === 'length' || name === 'width' || name === 'height') {
			if ((!tmp_data[0]['length'] || tmp_data[0]['length'] == 0 || !Number.isInteger(parseInt(tmp_data[0]['length']))) ||
				(!tmp_data[0]['width'] || tmp_data[0]['width'] == 0 || !Number.isInteger(parseInt(tmp_data[0]['width']))) ||
				(!tmp_data[0]['height'] || tmp_data[0]['height'] == 0 || !Number.isInteger(parseInt(tmp_data[0]['height'])))
			) {
				tmp_invalid_data[0]['volume'] = 'invalid';
			} else {
				tmp_invalid_data[0]['volume'] = '';
			}
		}

		setValues(tmp_data);
		setInvalid(tmp_invalid_data);

	}

	const onCloseModal = () => {
		setEditVariant('');
		setOpen(false);
	};

	const handleSaveVariant = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;
		if (!inputData[0]['title']) {
			valid += 1;
			invalidData[0]['title'] = 'invalid';
		} else {
			invalidData[0]['title'] = '';
		}
		if (!inputData[0]['stock'] || !Number.isInteger(parseInt(inputData[0]['stock']))) {
			valid += 1;
			invalidData[0]['stock'] = 'invalid';
		} else {
			invalidData[0]['stock'] = '';
		}

		if (!inputData[0]['price'] || !Number.isInteger(parseInt(inputData[0]['price']))) {
			valid += 1;
			invalidData[0]['price'] = 'invalid';
		} else {
			invalidData[0]['price'] = '';
		}

		if (!inputData[0]['weight'] || !Number.isInteger(parseInt(inputData[0]['weight']))) {
			valid += 1;
			invalidData[0]['weight'] = 'invalid';
		} else {
			invalidData[0]['weight'] = '';
		}

		if ((!inputData[0]['length'] || inputData[0]['length'] == 0 || !Number.isInteger(parseInt(inputData[0]['length'])))) {
			valid += 1;
			invalidData[0]['length'] = 'invalid';
		} else {
			invalidData[0]['length'] = '';
		}

		if ((!inputData[0]['width'] || inputData[0]['width'] == 0 || !Number.isInteger(parseInt(inputData[0]['width'])))) {
			valid += 1;
			invalidData[0]['width'] = 'invalid';
		} else {
			invalidData[0]['width'] = '';
		}

		if ((!inputData[0]['height'] || inputData[0]['height'] == 0 || !Number.isInteger(parseInt(inputData[0]['height'])))
		) {
			valid += 1;
			invalidData[0]['height'] = 'invalid';
		} else {
			invalidData[0]['height'] = '';
		}

		if ((!inputData[0]['length'] || inputData[0]['length'] == 0 || !Number.isInteger(parseInt(inputData[0]['length']))) ||
			(!inputData[0]['width'] || inputData[0]['width'] == 0 || !Number.isInteger(parseInt(inputData[0]['width']))) ||
			(!inputData[0]['height'] || inputData[0]['height'] == 0 || !Number.isInteger(parseInt(inputData[0]['height'])))
		) {
			valid += 1;
			invalidData[0]['volume'] = 'invalid';
		} else {
			invalidData[0]['volume'] = '';
		}

		if (!inputData[0]['image'] && !inputData[0]['image_']) {
			valid += 1;
			invalidData[0]['image'] = 'invalid';
		} else {
			invalidData[0]['image'] = '';
		}

		if (valid > 0) {
			let invalidData = [...invalid];
			invalidData[0]['src'] = 'invalid';
			setInvalid(invalidData);
			return false;
		}

		inputData[0].id = parseInt(inputData[0].id);
			inputData[0].stock = parseInt(inputData[0].stock);
		inputData[0].price = parseInt(inputData[0].price);
		inputData[0].weight = parseInt(inputData[0].weight);
		inputData[0].width = parseInt(inputData[0].width);
		inputData[0].height = parseInt(inputData[0].height);
		inputData[0].length = parseInt(inputData[0].length);
		inputData[0].uid = parseInt(user.id);
		const tmp_variants = variant ? variant : [];
		if (editVariant > 0) {
			const pictures = picture ? picture : [];

			tmp_variants[editVariant - 1] = inputData[0];
			pictures[editVariant - 1] = inputData[0].image_;
			setPicture(pictures)
		} else {
			tmp_variants.push(inputData[0])
			setPicture([...picture, inputData[0].image_])
		}
		console.log(tmp_variants)
		let tmp_img = inputData[0].image_

		if (product_slug !== '' && product_slug !== "0") {
			inputData[0].products_id = parseInt(products_id);

			if (editVariant > 0) {
				console.log(replaceImg)
				if(!replaceImg){
				console.log('asd')
				inputData[0].image_ = null
				}
				console.log(inputData[0])

				const result = await updateTodo({ variables: { input: inputData[0] } });
				if (result.data.updateProductVariant.success) {
					toast.success(result.data.updateProductVariant.message)
				} else {
					toast.error(result.data.updateProductVariant.message)
				}
			} else {
			const result = await addTodo({ variables: { input: inputData[0] } });
				if (result.data.createProductVariant.success) {
					toast.success(result.data.createProductVariant.message)
				} else {
					toast.error(result.data.createProductVariant.message)
				}
			}
		}
		console.log(tmp_variants)
		
		inputData[0].image_ = tmp_img
		
		setVariant(tmp_variants);

		setValues([{
			title: '',
			stock: '',
			price: '',
			weight: '',
			image: '',
			image_: '',
		}]);
		setInvalid([{
			title: '',
			stock: '',
			price: '',
			weight: '',
			image: '',
			image_: '',
		}]);
		setOpen(false);
		setEditVariant('');
	};

	return (
		<Modal isOpen={open} toggle={onCloseModal}>
			<ModalHeader toggle={onCloseModal}>
				<h5
					className="modal-title f-w-600"
					id="exampleModalLabel2"
				>
					Tambah Varian
				</h5>
			</ModalHeader>
			<ModalBody>
				<Form>
					<FormGroup>
						<Label
							htmlFor="message-text"
							className="col-form-label"
						>
							Nama Varian :
						</Label>
						<Input
							className="form-control"
							id="title"
							name="title"
							type="text"
							value={values[0].title}
							onChange={handleChange}
							invalid={invalid[0].title ? 'invalid' : ''}
						/>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>
					<FormGroup>
						<Label
							htmlFor="message-text"
							className="col-form-label"
						>
							Stok :
						</Label>
						<Input
							className="form-control"
							id="stock"
							name="stock"
							type="number"
							value={values[0].stock}
							onChange={handleChange}
							invalid={invalid[0].stock ? 'invalid' : ''}
						/>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>
					<FormGroup>
						<Label
							htmlFor="message-text"
							className="col-form-label"
						>
							Harga :
						</Label>
						<Input
							className="form-control"
							id="price"
							name="price"
							type="number"
							value={values[0].price}
							onChange={handleChange}
							invalid={invalid[0].price ? 'invalid' : ''}
						/>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>
					<FormGroup>
						<Label
							htmlFor="message-text"
							className="col-form-label"
						>
							Berat :
						</Label>
						<Input
							className="form-control"
							id="weight"
							name="weight"
							type="number"
							value={values[0].weight}
							onChange={handleChange}
							invalid={invalid[0].weight ? 'invalid' : ''}
						/>
						<FormText>
							Berat dalam Gram
						</FormText>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>

					<FormGroup >
						<Label className="col-form-label">
							Volume :
						</Label>
						<div className="col-xl-12 col-sm-7">
							<>
								<InputGroup className="input-group-alternative">
									<div className="d-flex align-items-center">
										P&nbsp;<Input
											className="form-control w-25 mb-0"
											name="length"
											id="length"
											type="number"
											value={values[0].length}
											onChange={handleChange}
											invalid={invalid[0].length ? 'invalid' : ''}
										/>
										&nbsp;&nbsp;x &nbsp;&nbsp;L&nbsp;<Input
											className="form-control w-25 mb-0"
											name="width"
											id="width"
											type="number"
											value={values[0].width}
											onChange={handleChange}
											invalid={invalid[0].width ? 'invalid' : ''}
										/>
										&nbsp;&nbsp;x &nbsp;&nbsp;T&nbsp;<Input
											className="form-control w-25 mb-0"
											name="height"
											id="height"
											type="number"
											value={values[0].height}
											onChange={handleChange}
											invalid={invalid[0].height ? 'invalid' : ''}
										/>
									</div>
									<FormText>
										Volume dalam Cm
									</FormText>
								</InputGroup>
								<FormFeedback className={invalid[0].volume ? "d-block" : ''}>
									This field is required
								</FormFeedback>

							</>
						</div>
						<div className="valid-feedback">Looks good!</div>
					</FormGroup>
					<FormGroup>
						<Label
							htmlFor="recipient-name"
							className="col-form-label"
						>
							Gambar :
						</Label>
						<div><img src={values[0].image_ ? (values[0].image_[0] ? URL.createObjectURL(values[0].image_[0]) : URL.createObjectURL(values[0].image_)) : ''} width="150px" /></div>
						<Input type="file" className="form-control"
							id="image"
							name="image"
							value={values[0].image}
							onChange={handleChange}
							invalid={invalid[0].image ? 'invalid' : ''}
						/>
						<FormText>Image Size 1616px × 1144px</FormText>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>
				</Form>
				<FormGroup className="form-group mb-3 row">
					<Label
						htmlFor="recipient-name"
						className="col-form-label"
					>
						Status :
					</Label>
					<div className="col-xl-12 col-sm-7">
						<Input
							className="form-control"
							id="status"
							name="status"
							type="select"
							required=""
							onChange={(e) => handleChange(e)}
							value={values[0].status}
							invalid={invalid[0].status ? 'invalid' : ''}
						>
							<option value="Active">
								Active
							</option>
							<option value="Inactive">
								Inactive
							</option>
						</Input>
					</div>
				</FormGroup>
			</ModalBody>
			<ModalFooter>
				<Button
					type="button"
					color="primary"
					onClick={handleSaveVariant}
				>
					Simpan
				</Button>
				<Button
					type="button"
					color="secondary"
					onClick={() => onCloseModal("VaryingMdo")}
				>
					Tutup
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalVariant;
