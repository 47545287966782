import React, { useState, useRef, useCallback } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { Card, CardBody, Col, Table, Modal, ModalBody, ModalFooter, ModalHeader, Button, Tooltip, Form } from "reactstrap";
import { useMutation, gql } from '@apollo/client';
import { toast } from "react-toastify";
import ModalPickupHistory from "./modal-pickup-history";
import ModalShippingHistory from "./modal-shipping-history";
import CetakLabel from "./cetak-label";

const CREATE_PICKUP = gql`
  mutation requestPickup($id:Int!) {
    requestPickup(id: $id) {
		success
		message
    }
  }
`;

const UPDATE_ORDER = gql`
  mutation updateOrdersStatus($id:Int!,$status:String!) {
    updateOrdersStatus(id: $id,status:$status) {
		success
		message
    }
  }
`;

const CANCEL_ORDER = gql`
  mutation cancelOrders($id:Int!) {
    cancelOrders(id: $id) {
		success
		message
    }
  }
`;


const TabsetProfile = ({ id, data, refetch }) => {
	const resi = useRef(null);
	const [createStt] = useMutation(CREATE_PICKUP);
	const [updateOrder] = useMutation(UPDATE_ORDER);
	const [cancelOrder] = useMutation(CANCEL_ORDER);
	const [openModalShippingHistory, setOpenModalShippingHistory] = useState(false);
	const [openModalTerima, setOpenModalTerima] = useState(false);
	const [openModalCancelOrder, setOpenModalCancelOrder] = useState(false);
	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [cetakLabel, setCetakLabel] = useState(false);
	const renderStatus = (param) => {
		switch (param) {
			case 'Pending':
				return 'Belum di bayar';
			case 'Paid':
				return 'Sudah di bayar';
			case 'Accepted':
				return 'Pesanan sedang di proses';
			case 'OTW':
				return 'Sedang dalam perjalanan';
			case 'Finish':
				return 'Selesai';
			case 'Canceled':
				return 'Dibatalkan';
			case 'Received':
				return 'Sudah sampai';
			default:
				return 'Sedang dalam proses2';
		}
	}
	const handleCreateStt = async () => {
		const results = await createStt({ variables: { id: parseInt(data.getOrder.id) } });
		if (!results.data.requestPickup.success) {
			toast.error(results.data.requestPickup.message);
		} else {
			refetch({
				variables: {
					id: parseInt(id),
				},
			})
			toast.success(results.data.requestPickup.message);
		}
	}
	const handleCheckPakages = async () => {
		setOpenModalShippingHistory(true);
	}
	const handleOpenModalTerima = async () => {
		setOpenModalTerima(true);
	}

	const onCloseModalTerima = async () => {
		setOpenModalTerima(false);
	}

	const handleTerimaOrder = async (e) => {
		e.preventDefault();
		const results = await updateOrder({ variables: { id: parseInt(data.getOrder.id), status: 'Accepted' } });
		if (!results.data.updateOrdersStatus.success) {
			toast.error(results.data.updateOrdersStatus.message);
		} else {
			refetch({
				variables: {
					id: parseInt(id),
				},
			})
			toast.success(results.data.updateOrdersStatus.message);
		}
		if (e.target.ambilResi.checked) {
			handleCreateStt();
		}
		setOpenModalTerima(false);
	}
	
	const handleCancelOrder = async (e) => {
		e.preventDefault();
		const results = await cancelOrder({ variables: { id: parseInt(data.getOrder.id) } });
		if (!results.data.cancelOrders.success) {
			toast.error(results.data.cancelOrders.message);
		} else {
			refetch({
				variables: {
					id: parseInt(id),
				},
			})
			toast.success(results.data.cancelOrders.message);
		}
		setOpenModalCancelOrder(false);
	}

	return (
		<>
			<Col xl="4">
				{data && data.getOrder ?
					<Card className="profile-card">
						<CardBody>
							<div>
								<Tabs>
									<TabList className="nav nav-tabs tab-coupon">
										<Tab className="nav-link">
											<User className="me-2" />
											Data Pengiriman
										</Tab>
									</TabList>

									<TabPanel>
										<div className="tab-pane fade show active">
											<div className="table-responsive order-table">
												<Table className="table-responsive">
													<tbody>
														<tr>
															<td>Order ID:</td>
															<td>{data.getOrder.order_id}</td>
														</tr>
														<tr>
															<td>Nama:</td>
															<td>{data.getOrder.name}</td>
														</tr>
														<tr>
															<td>Telp:</td>
															<td>{data.getOrder.phone}</td>
														</tr>
														<tr>
															<td>Alamat:</td>
															<td>{data.getOrder.detail_address}</td>
														</tr>
														<tr>
															<td>Kota:</td>
															<td>{data.getOrder.ref_city_name + ", " + data.getOrder.ref_province_name}</td>
														</tr>
														<tr>
															<td>Tanggal Order:</td>
															<td>{data.getOrder.created_date}</td>
														</tr>
														<tr>
															<td>Status Pembayaran:</td>
															<td>{data.getOrder.payment_status ? 'Lunas' : 'Belum Di bayar'}</td>
														</tr>
														<tr>
															<td>Status Pesanan:</td>
															<td>{renderStatus(data.getOrder.status)+(data.getOrder.complaint_date?' - Komplain':'')}</td>
														</tr>
														{data.getOrder.status !== 'Canceled' ?
															<>
																<>
																	{data.getOrder.status !== 'Pending' && data.getOrder.status !== 'Paid' &&  ( data.getOrder.shipment_id || data.getOrder.no_resi) ?
																		<tr>
																			<td>No. Resi:</td>
																			<td>
																				{data.getOrder.no_resi ?
																					data.getOrder.no_resi : (data.getOrder.shipment_id ? data.getOrder.shipment_id : "-")}
																				<div className="text-primary" onClick={() => setCetakLabel(true)} role="button">Cetak Label</div>
																			</td>
																		</tr> : ''}
																</>
																<>
																	{data.getOrder.status === 'Paid' ?
																		<tr>
																			<td colSpan={2}>
																				<button className="btn btn-primary mx-2" onClick={handleOpenModalTerima}>Terima Order</button>
																				{/*<button className="btn btn-primary " onClick={handleCreateStt}>Drop off</button>
																				<button className="btn btn-primary mx-2" onClick={handleCreateStt}>Pick up</button>*/}
																			</td>
																		</tr> : ''}
																</>
																<>
																	{data.getOrder.status === 'OTW' || ( data.getOrder.status === 'Accepted' && data.getOrder.shipment_id) ?
																		<tr >
																			<td colSpan={2}>
																				{/*<div className="" onClick={() => setCetakLabel(true)}>Cetak Pesanan</div>*/}
																				<button className="btn btn-success my-2" onClick={handleCheckPakages}>Cek Paket</button>
																			</td>
																		</tr> : ''}
																</>
																<>
																	{data.getOrder.status === 'Accepted' && !data.getOrder.shipment_id ?
																		<tr >
																			<td colSpan={2}>
																				<button className="btn btn-primary " onClick={handleCreateStt}>Request No. Resi</button>
																			</td>
																		</tr> : ''}
																</>
																<>

																	{data && data.getOrder && (data.getOrder.status === 'Pending' || data.getOrder.status === 'Paid' || data.getOrder.status === 'Accepted' || data.getOrder.status === 'OTW') ?

																		<tr >
																			<td colSpan={2}>
																				<button className="btn btn-primary " onClick={()=>setOpenModalCancelOrder(true)}>Cancel Order</button>
																			</td>
																		</tr> : ''}
																</>
																{/*<>{data.getOrder.no_resi ?
																	<tr>
																		<td>Status Paket:</td>
																		<td>
																			{data.getOrder.status_pengiriman}
																		</td>
																	</tr> : ''}
																</>*/}
															</>
															: ''}
													</tbody>
												</Table>
											</div>
										</div>
									</TabPanel>
								</Tabs>
							</div>
							{data.getOrder.no_resi ?
								<ModalShippingHistory stt={data.getOrder.no_resi} openModalShippingHistory={openModalShippingHistory} setOpenModalShippingHistory={setOpenModalShippingHistory} />

								:
								(data.getOrder.shipment_id ?
									<ModalPickupHistory stt={data.getOrder.shipment_id} openModalShippingHistory={openModalShippingHistory} setOpenModalShippingHistory={setOpenModalShippingHistory} />
									: '')
							}
							<Modal isOpen={openModalTerima} toggle={onCloseModalTerima} style={{ overlay: { opacity: 0.1 } }} className="modal-dialog-centered modal-lg" >
								<Form onSubmit={handleTerimaOrder}>
									<ModalHeader toggle={onCloseModalTerima}>
										<h5 className="modal-title f-w-600" id="exampleModalLabel2">
											Konfirmasi Terima Pesan
										</h5>
									</ModalHeader>
									<ModalBody>
										<div className="my-2">
											Apakah kamu ingin menerima pesan ?
										</div>
										<ul className="list">
											<li>Mohon perhatikan beberapa hal berikut untuk menerima pesan kamu:</li>
											<ol>Silahkan ambil dan cetak kode booking pesanan dahulu sebelum mengirimkan barang.</ol>
										</ul>
									</ModalBody>
									<ModalFooter className="display-flex justify-content-between">
										<div class="form-check">
											<input class="form-check-input" type="checkbox" value="" id="ambilResi" name="ambilResi" />
											<label class="form-check-label" for="ambilResi" id="TooltipExample" onMouseEnter={() => setTooltipOpen(true)} onMouseOut={() => setTooltipOpen(false)}>
												Sekaligus ambil Kode Booking
											</label>
											<div className={`position-absolute ctooltip ${tooltipOpen ? '' : 'hide'}`}>
												<b>Terima Langsung Kirim</b>
												<ul className="list">
													<li>Dengan mencentang pengaturan ini, dalam 1 langkah saja kamu bisa:</li>
													<ol>Terima Pesanan</ol>
													<ol>Ambil Kode Booking (Resi Otomatis)</ol>
												</ul>
											</div>
										</div>
										<Button
											type="submit"
											color="primary"
										>
											Terima
										</Button>
									</ModalFooter>
								</Form>
							</Modal>

							<Modal isOpen={openModalCancelOrder} toggle={()=>setOpenModalCancelOrder(false)} style={{ overlay: { opacity: 0.1 } }} className="modal-dialog-centered modal-lg" >
								<Form onSubmit={handleCancelOrder}>
									<ModalHeader toggle={()=>setOpenModalCancelOrder(false)}>
										<h5 className="modal-title" id="exampleModalLabel2">
											Pembatalan Pesan
										</h5>
									</ModalHeader>
									<ModalBody>
										<div className="my-2">
											Apakah kamu ingin membatalkan pesan ?
										</div>
									</ModalBody>
									<ModalFooter>
										<div
											role="button"
											class="btn"
											onClick={()=>setOpenModalCancelOrder(false)}
										>
											No
										</div>
										<Button
											type="submit"
											color="primary"
										>
											Yes
										</Button>
									</ModalFooter>
								</Form>
							</Modal>
						</CardBody>
					</Card>
					: ''}
			</Col>

			{data && data.getOrder && ( data.getOrder.status === 'OTW' ||  (data.getOrder.status === 'Accepted' && data.getOrder.shipment_id ))?
				<CetakLabel data={data} cetakLabel={cetakLabel} setCetakLabel={setCetakLabel} />
				: ''}

		</>
	);
};

export default TabsetProfile;
