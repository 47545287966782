import React, { useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { Card, CardBody, Col, Table, Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { useQuery, gql } from '@apollo/client';
import { toast } from "react-toastify";
import { useEffect } from "react";
import moment from "moment";
import { numberFormat } from "../../../helpers/custom";

const GET_DETAIL_SALDO_WITHDRAW = gql`
  query getSaldoWithdrawalRequestHistoryStoreDetail($id:Int!) {
    getSaldoWithdrawalRequestHistoryStoreDetail(id: $id) {
			id
			total
            withdraw_fee
			status
			created_date
			created_by
			accepted_date
			accepted_by
			done_date
			done_by
			reject_by
			reject_date
			reject_reason
    }
  }
`;

const ModalShippingHistory = ({ id, openModalDetail, setOpenModalDetail }) => {
    const [data, setData] = useState([]);
    var { refetch } = useQuery(GET_DETAIL_SALDO_WITHDRAW, {
        variables: {
            id: id ? parseInt(id) : 0,
        },
    });
    useEffect(() => {
        if (openModalDetail) {
            const fetchData = async () => {
                let datas = await refetch({
                    variables: {
                        id: parseInt(id),
                    }
                });
                setData(datas.data)
            }

            // call the function
            fetchData()
        }
    }, [openModalDetail])

    const onCloseModal = async () => {
        setOpenModalDetail(false);
    }

    return (
        <Modal
            isOpen={openModalDetail}
            toggle={onCloseModal}
            style={{ overlay: { opacity: 0.1 } }}
        >
            <ModalHeader toggle={onCloseModal}>
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Detail Request Withdrawal
                </h5>
            </ModalHeader>
            {data && data.getSaldoWithdrawalRequestHistoryStoreDetail ?

                <ModalBody>
                    <div className="d-flex mb-2">
                        <div className="col-3">ID </div>
                        <span className="font-weight-bold">: {data.getSaldoWithdrawalRequestHistoryStoreDetail.id}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="col-3">Date </div>
                        <span className="font-weight-bold">: {data.getSaldoWithdrawalRequestHistoryStoreDetail.created_date}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="col-3">Amount </div>
                        <span className="font-weight-bold">: {numberFormat(parseInt(data.getSaldoWithdrawalRequestHistoryStoreDetail.total))}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="col-3">Withdraw Fee </div>
                        <span className="font-weight-bold">: {numberFormat(parseInt(data.getSaldoWithdrawalRequestHistoryStoreDetail.withdraw_fee))}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="col-3">Status </div>
                        <span className="font-weight-bold">: {data.getSaldoWithdrawalRequestHistoryStoreDetail.status}</span>
                    </div>
                    {data.getSaldoWithdrawalRequestHistoryStoreDetail.status ==='Done'?
                    <div className="d-flex mb-2">
                        <div className="col-3">Withdraw Date </div>
                        <span className="font-weight-bold">: {data.getSaldoWithdrawalRequestHistoryStoreDetail.done_date}</span>
                    </div>:''}
                    {data.getSaldoWithdrawalRequestHistoryStoreDetail.status ==='Accept'?
                    <div className="d-flex mb-2">
                        <div className="col-3">Accept Date </div>
                        <span className="font-weight-bold">: {data.getSaldoWithdrawalRequestHistoryStoreDetail.accepted_date}</span>
                    </div>:''}
                    {data.getSaldoWithdrawalRequestHistoryStoreDetail.status ==='Reject'?
                    <>
                    <div className="d-flex mb-2">
                        <div className="col-3">Reject Date </div>
                        <span className="font-weight-bold">: {data.getSaldoWithdrawalRequestHistoryStoreDetail.reject_date}</span>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="col-3">Reject Reason </div>
                        <span className="font-weight-bold">: {data.getSaldoWithdrawalRequestHistoryStoreDetail.reject_reason}</span>
                    </div>
                    </>:''}
                    <div></div>
                </ModalBody>
                : ''}
            <ModalFooter>
                <Button
                    type="button"
                    color="secondary"
                    onClick={onCloseModal}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalShippingHistory;
