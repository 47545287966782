import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Filter from "../common/filter_components/filter";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { numberFormat, orderStatus, orderStatusOptions } from "../../helpers/custom";

const GET_STORE = gql`
  query stores(
  	$name:String
	$city:String
    $indexFrom: Int!
    $limit: Int!
    $status: String
    $phone: String
	$sortBy: String
  ){
    stores(
	name:$name
	city:$city
    indexFrom: $indexFrom
    limit: $limit
    status: $status
    phone: $phone
    sortBy: $sortBy
	) {
		hasMore
		total
		items{
			id
			name
			slug
			pic
			email
			phone
			address
			ref_cities_list_id
			ref_cities_list_name
			ref_province_list_id
			ref_province_list_name
			image
			status
			sisa_saldo
	  	}
    }
  }
`;

const columns = [
	{
		dataField: 'id',
		text: '#',
		sort: true,
	},
	{
		dataField: 'name',
		text: 'Nama',
		sort: true,

	},
	{
		dataField: 'phone',
		text: 'Phone',
		sort: true,

	},
	{
		dataField: 'ref_cities_list_name',
		text: 'Lokasi',
		sort: true,
	},
	{
		dataField: 'sisa_saldo',
		text: 'Sisa Saldo',
		sort: true,
		formatter: (cell, row, rowIndex, extraData) => (
			numberFormat(parseInt(row.sisa_saldo))
		),
	},
	{
		dataField: 'status',
		text: 'Status',
		sort: true,
	},
	{
		dataField: '',
		text: '',
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/toko/${row.slug}`} >
					<i className="fa fa-eye" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: '13%' };
		}
	}
];

const List_vendors = () => {
	const sizePerPage = 10;
	let navigate = useNavigate();
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState({
		name: '',
		phone: '',
		lokasi: '',
		status: '',
	})
	const user = JSON.parse(localStorage.getItem('user'));
	const [sortBy, setSortBy] = useState();
	const [defaultSorted, setDefaultSorted] = useState([{
		dataField: 'id',
		order: 'desc'
	}]);
	useEffect(() => {
		if (!user || user.privilege === 'Stores') {
			return navigate("/dashboard", { replace: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	var { data, refetch } = useQuery(GET_STORE, {
		variables: {
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			city: filter && filter.lokasi ? filter.lokasi : '',
			name: filter && filter.name ? filter.name : '',
			phone: filter && filter.phone ? filter.phone : '',
			status: filter && filter.status ? filter.status.value : '',
			sortBy: sortBy,
		}
	});

	const filterOptions = [
		{
			id: 'name',
			label: 'Nama',
			value: filter.name,
			type: 'text',
		},
		{
			id: 'phone',
			label: 'Phone',
			value: filter.phone,
			type: 'text',
		},
		{
			id: 'lokasi',
			label: 'Lokasi',
			value: filter.lokasi,
			type: 'text',
		},
		{
			id: 'status',
			label: 'Status',
			value: filter.status,
			type: 'dropdown',
			options: [
				{
					label: 'Active',
					value: 'active',
				},
				{
					label: 'Inactive',
					value: 'inactive',
				}
			]
		},
	]

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		if (page) {
			setPage(page)
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + '-' + sortOrder)
			setDefaultSorted([{
				dataField: sortField,
				order: sortOrder
			}])
		}
	}
	const handleFilterChange = (e) => {
		refetch({
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			city: filter && filter.lokasi ? filter.lokasi : '',
			name: filter && filter.name ? filter.name : '',
			phone: filter && filter.phone ? filter.phone : '',
			status: filter && filter.status ? filter.status.value : '',
			sortBy: sortBy,
		})
		setFilter(e);
	}
	return (
		<Fragment>
			<Breadcrumb title="Toko" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<div className="btn-popup pull-right">
							<Link to="/toko/form/0" className="btn btn-primary">
								Tambah Toko
							</Link>
						</div>
						<div className="clearfix"></div>
						<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} />
						<div
							id="batchDelete"
							className="category-table store-list order-table coupon-list-delete"
						>
							{data ?
								<BootstrapTable
									keyField='id'
									hover
									condensed
									remote={{ sort: true, pagination: true }}
									data={data && data.stores ? data.stores.items : []}
									columns={columns}
									bordered={false}
									noDataIndication="Table is Empty"
									pagination={paginationFactory(
										{
											sizePerPage: sizePerPage,
											page: page,
											hideSizePerPage: true,
											hidePageListOnlyOnePage: true,
											totalSize: data && data.stores ? data.stores.total : 0
										})}
									defaultSorted={defaultSorted}
									onTableChange={handleTableChange}
									wrapperClasses="table-responsive"
								/>
								: ''}
						</div>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_vendors;
