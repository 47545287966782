import React, { useEffect, useState } from "react";
import { Form, FormGroup, FormFeedback, FormText, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const GET_PRODUCTS = gql`
  query getSettingTarifShippingDetail(
    $id: Int!
  ) {
    getSettingTarifShippingDetail(
		id: $id
    ) {
		id
        name
        value
        created_date
        start_date
        end_date
        ref_shipping_id
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation createSettingShippingDetail($input: SettingShippingDetailInput) {
    createSettingShippingDetail(input: $input) {
        success,
        message,
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation updateSettingShippingDetail($input: SettingShippingDetailInput) {
    updateSettingShippingDetail(input: $input) {
        success,
        message,
    }
  }
`;
const ModalCategory = ({ open, setOpen, refetch, user, ref_shipping_id }) => {
	const [values, setValues] = useState([{
		title: '',
	}]);
	const [invalid, setInvalid] = useState([{
		title: '',
	}]);
	var { data, refetch: refetchDetail } = useQuery(GET_PRODUCTS, {
		variables: {
			id: 0,
		},
	});
	const [addTodo] = useMutation(CREATE_CATEGORY);
	const [updateTodo] = useMutation(UPDATE_CATEGORY);

	useEffect(() => {
        if(data){
            setValues([{
                name: data.getSettingTarifShippingDetail.name,
                value: data.getSettingTarifShippingDetail.value,
                start_date: data.getSettingTarifShippingDetail.start_date,
                end_date: data.getSettingTarifShippingDetail.end_date,
            }]);
        }
	}, [data]);

	useEffect(() => {
		if (open !== 'new' && open !== '') {
			refetchDetail({
                id: parseInt(open),
            })
		}else{
            setValues([{
                name: '',
                value: '',
                start_date: '',
                end_date: '',
            }]);
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const handleChange = (event) => {
		let tmp_data = [...values];
		let tmp_invalid_data = [...invalid];
		const { target } = event;
		const value = target.value;
		const { name } = target;
        if(name !== 'end_date'){
		    tmp_invalid_data[0][name] = value ? '' : 'invalid';
        }
		tmp_data[0][name] = value;
		setValues(tmp_data);
	}

	const onCloseModal = () => {
		setOpen(false);
	};

	const handleSave = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;
		if (!inputData[0]['name']) {
			valid += 1;
			invalidData[0]['name'] = 'invalid';
		} else {
			invalidData[0]['name'] = '';
		}

		if (!inputData[0]['value']) {
			valid += 1;
			invalidData[0]['value'] = 'invalid';
		} else {
			invalidData[0]['value'] = '';
		}

		if (!inputData[0]['start_date']) {
			valid += 1;
			invalidData[0]['start_date'] = 'invalid';
		} else {
			invalidData[0]['start_date'] = '';
		}

		if (valid > 0) {
			setInvalid(invalidData);
			return false;
		}
		//inputData[0].value = parseInt(inputData[0].value);
		inputData[0].modified_by = user.id;
		inputData[0].ref_shipping_id = parseInt(ref_shipping_id);
        
		//setOpen(false);
		if (open !== 'new') {
		    inputData[0].id = parseInt(open);

			const result = await updateTodo({ variables: { input: inputData[0] } });
			if (result.data.updateSettingShippingDetail.success) {
				refetch({
                    id: parseInt(ref_shipping_id),
                })
				toast.success(result.data.updateSettingShippingDetail.message);
			} else {
				toast.error(result.data.updateSettingShippingDetail.message);
			}
		} else {
		    inputData[0].created_date = moment().format('YYYY-MM-DD hh:mm:ss');
		    inputData[0].created_by = user.id;

			const result = await addTodo({ variables: { input: inputData[0] } });
			if (result.data.createSettingShippingDetail.success) {
				refetch({
                    id: parseInt(ref_shipping_id),
                })
				toast.success(result.data.createSettingShippingDetail.message);
			} else {
				toast.error(result.data.createSettingShippingDetail.message);
			}
		}
		setValues([{
			title: '',
		}]);
		setInvalid([{
			title: '',
		}]);
		setOpen(false);

	};

	return (
		<Modal isOpen={open} toggle={onCloseModal}>
			<ModalHeader toggle={onCloseModal}>
				<h5
					className="modal-title f-w-600"
					id="exampleModalLabel2"
				>
					{open!=='new'?'Edit':'Add'} Shipping
				</h5>
			</ModalHeader>
			<Form onSubmit={handleSave} >
				<ModalBody>
					<FormGroup>
						<Label
							htmlFor="message-text"
							className="col-form-label"
						>
							Name :
						</Label>
						<Input
							className="form-control"
							id="name"
							name="name"
							type="text"
							value={values[0].name}
							onChange={handleChange}
							invalid={invalid[0].name ? 'invalid' : ''}
						/>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>
                    <FormGroup>
						<Label
							htmlFor="message-text"
							className="col-form-label"
						>
							Value :
						</Label>
						<Input
							className="form-control"
							id="value"
							name="value"
							type="text"
							value={values[0].value}
							onChange={handleChange}
							invalid={invalid[0].value ? 'invalid' : ''}
						/>
						<FormText>
							Tambahkan % jika persentase
						</FormText>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>
                    <FormGroup>
						<Label
							htmlFor="message-text"
							className="col-form-label"
						>
							Start :
						</Label>
						<Input
							className="form-control"
							id="start_date"
							name="start_date"
							type="datetime-local"
							value={values[0].start_date}
							onChange={handleChange}
							invalid={invalid[0].start_date ? 'invalid' : ''}
						/>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>
                    <FormGroup>
						<Label
							htmlFor="message-text"
							className="col-form-label"
						>
							End :
						</Label>
						<Input
							className="form-control"
							id="end_date"
							name="end_date"
							type="datetime-local"
							value={values[0].end_date}
							onChange={handleChange}
							invalid={invalid[0].end_date ? 'invalid' : ''}
						/>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						type="submit"
						color="primary"
					>
						Simpan
					</Button>
					<Button
						type="button"
						color="secondary"
						onClick={() => onCloseModal("VaryingMdo")}
					>
						Tutup
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export default ModalCategory;
