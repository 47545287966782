import React, { Fragment, useState, useEffect } from "react";
import { Button, Form, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { phoneCheck } from "../../../helpers/custom";

const GET_USER = gql`
  query ($id:Int){
    getUser(id:$id){
    		id
    		email
    		phone
    		name
			privilege
			stores_id
			stores_name
			stores_slug
			status
			created_date
  	}
  }
`;

const GET_STORE = gql`
  query {
    stores {
		hasMore
		total
		items{
			id
			name
			slug
			pic
			email
			phone
			address
			ref_cities_list_id
			ref_cities_list_name
			ref_province_list_id
			ref_province_list_name
			image
	  	}
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($input: UserInput) {
    createUser(input: $input) {
        success,
        message,
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UserInput) {
    updateUser(input: $input ) {
        success,
        message,
    }
  }
`;
const TabsetUser = ({ id }) => {
	let navigate = useNavigate();
	const [showStore, setShowStore] = useState(false);
	const user = JSON.parse(localStorage.getItem('user'));
	const [values, setValues] = useState([{
		name: '',
	}]);
	const [invalid, setInvalid] = useState([{
		name: '',
	}]);
	const [addTodo] = useMutation(CREATE_USER);
	const [updateTodo] = useMutation(UPDATE_USER);
	var { data } = useQuery(GET_USER, {
		variables: {
			id: parseInt(id)
		}
	});
	var { data: storesData } = useQuery(GET_STORE);

	useEffect(() => {
		if (data && data.getUser) {
			setValues([{
				id: data.getUser.id,
				name: data.getUser.name,
				email: data.getUser.email,
				phone: data.getUser.phone,
				privilege: data.getUser.privilege,
				stores_id: data.getUser.stores_id,
				status: data.getUser.status,
			}]);
			if (data.getUser.privilege === 'Stores') {
				setShowStore(true);
			}

		}
	}, [data]);

	const handleChange = (event) => {
		let tmp_data = [...values];
		let tmp_invalid_data = [...invalid];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_invalid_data[0][name] = value ? '' : 'invalid';
		tmp_data[0][name] = value;
		setValues(tmp_data);
		if (name === 'privilege') {
			if (value === 'Stores') {
				setShowStore(true);
			} else {
				setShowStore(false);
			}
		}
	}

	const handleValidSubmit = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;

		if (!inputData[0]['name']) {
			valid += 1;
			invalidData[0]['name'] = 'invalid';
		} else {
			invalidData[0]['name'] = '';
		}

		if (!inputData[0]['email']) {
			valid += 1;
			invalidData[0]['email'] = 'invalid';
		} else {
			invalidData[0]['email'] = '';
		}
		if (!inputData[0]['phone'] || !Number.isInteger(parseInt(inputData[0]['phone']))) {
			valid += 1;
			invalidData[0]['phone'] = 'This field is required';
		} else {
			if (phoneCheck(inputData[0]['phone'])) {
				invalidData[0]['phone'] = '';
			} else {
				valid += 1;
				invalidData[0]['phone'] = 'Please insert valid phone';
			}
		}
		if (!inputData[0]['privilege']) {
			valid += 1;
			invalidData[0]['privilege'] = 'invalid';
		} else {
			invalidData[0]['privilege'] = '';
		}
		if (inputData[0]['privilege'] === 'Stores') {
			if (!inputData[0]['stores_id']) {
				valid += 1;
				invalidData[0]['stores_id'] = 'invalid';
			} else {
				invalidData[0]['stores_id'] = '';
				inputData[0]['stores_id'] = parseInt(inputData[0]['stores_id']);
			}
		}
		if (valid > 0) {
			let invalidData = [...invalid];
			setInvalid(invalidData);
			return false;
		}
		inputData[0].uid = parseInt(user.id);
		if (inputData[0].id > 0) {
			const result = await updateTodo({ variables: { input: inputData[0] } });
			if (result.data.updateUser.success) {
				toast.success(result.data.updateUser.message)
				return navigate("/users", { replace: true });
			} else {
				toast.error(result.data.updateUser.message)
			}
		} else {
			const result = await addTodo({ variables: { input: inputData[0] } });
			if (result.data.createUser.success) {
				toast.success(result.data.createUser.message)
				return navigate("/users", { replace: true });
			} else {
				toast.error(result.data.createUser.message)
			}
		}

	};
	return (
		<Fragment>
			<Form className="needs-validation user-add" noValidate="" onSubmit={handleValidSubmit} >
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						<span>*</span> Nama
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							id="name"
							name="name"
							type="text"
							onChange={(e) => handleChange(e)}
							value={values[0].name}
							invalid={invalid[0].name ? 'invalid' : ''}
						/>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</div>
				</FormGroup>
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						<span>*</span> Email
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							id="email"
							name="email"
							type="text"
							onChange={(e) => handleChange(e)}
							value={values[0].email}
							invalid={invalid[0].email ? 'invalid' : ''}
						/>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</div>
				</FormGroup>
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						<span>*</span> Telp
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							id="phone"
							name="phone"
							type="text"
							onChange={(e) => handleChange(e)}
							value={values[0].phone}
							invalid={invalid[0].phone ? 'invalid' : ''}
						/>
						<FormFeedback>
							{invalid[0].phone ? invalid[0].phone : 'This field is required'}
						</FormFeedback>
					</div>
				</FormGroup>
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						<span>*</span> Privilege
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							id="privilege"
							name="privilege"
							type="select"
							onChange={(e) => handleChange(e)}
							value={values[0].privilege}
							invalid={invalid[0].privilege ? 'invalid' : ''}

						>
							<option value="">
								Pilih salah satu
							</option>
							<option value="Admin">
								Admin
							</option>
							<option value="Stores">
								Toko
							</option>
						</Input>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</div>
				</FormGroup>
				{showStore ?
					<FormGroup className="row">
						<Label className="col-xl-3 col-md-4">
							<span>*</span> Store
						</Label>
						<div className="col-xl-8 col-md-7">
							<Input
								id="stores_id"
								name="stores_id"
								type="select"
								onChange={(e) => handleChange(e)}
								value={values[0].stores_id}
								invalid={invalid[0].stores_id ? 'invalid' : ''}
							>
								<option value="">
									Pilih salah satu
								</option>
								{storesData && storesData.stores.items.map(e => {
									return (
										<option value={e.id}>
											{e.name}
										</option>
									)
								})}
							</Input>
							<FormFeedback>
								This field is required
							</FormFeedback>
						</div>
					</FormGroup> : ''}
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4"
						htmlFor="message-text"
					>
						Status :
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							id="status"
							name="status"
							type="select"
							onChange={(e) => handleChange(e)}
							value={values[0].status}
						>
							<option value="active">
								Active
							</option>
							<option value="inactive">
								Inactive
							</option>
						</Input>
						<FormFeedback>
							This field is required
						</FormFeedback>
					</div>
				</FormGroup>
				<div className="pull-right">
					<Button type="submit" color="primary">
						Save
					</Button>
				</div>
			</Form>
		</Fragment>
	);
};

export default TabsetUser;
