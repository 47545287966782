import React, { useState } from "react";
import { FormGroup, Form, FormFeedback, Label, Input, Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { numberFormat } from "../../../helpers/custom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";


const UPDATE_STORE = gql`
  mutation updateRefundOrder($input: OrdersRefundInput) {
    updateRefundOrder(input: $input) {
        success,
        message,
    }
  }
`;

const ModalShippingHistory = ({ data, openModalDetail, setOpenModalDetail, refetch }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const [values, setValues] = useState([{
        id: '',
        status: '',
        reject_reason: '',
    }]);
    const [invalid, setInvalid] = useState([{
        status: '',
        reject_reason: '',
    }]);
    const [updateTodo] = useMutation(UPDATE_STORE);

    const onCloseModal = async () => {
        setOpenModalDetail(false);
    }
    useEffect(() => {
        if (data) {
            setValues([{
                id: data.id,
                status: data.status,
                reject_reason: data.reject_reason,
            }]);
        }
    }, [data])
    const handleChange = (event) => {
        let tmp_data = [...values];
        const { target } = event;
        const value = target.value;
        const { name } = target;
        tmp_data[0][name] = value;
        setValues(tmp_data);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const inputData = values;
        let invalidData = [...invalid];
        let valid = 0;
        if (!inputData[0]['status']) {
            valid += 1;
            invalidData[0]['status'] = 'invalid';
        } else {
            invalidData[0]['status'] = '';
        }
        if (inputData[0]['status'] === 'reject') {
            if (!inputData[0]['reject_reason'] ) {
                valid += 1;
                invalidData[0]['reject_reason'] = 'invalid';
            } else {
                invalidData[0]['reject_reason'] = '';
            }
        }

        if (valid > 0) {
            let invalidData = [...invalid];
            setInvalid(invalidData);
            return false;
        }
        inputData[0].id = data.id;
        inputData[0].uid = user.id;
        const result = await updateTodo({ variables: { input: inputData[0] } });
        if (result.data.updateRefundOrder.success) {
            toast.success(result.data.updateRefundOrder.message)
            refetch();
            setOpenModalDetail(false);
        } else {
            toast.error(result.data.updateRefundOrder.message)
        }
    };
    return (
        <Modal
            isOpen={openModalDetail}
            toggle={onCloseModal}
            style={{ overlay: { opacity: 0.1 } }}
        >
            <ModalHeader toggle={onCloseModal}>
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Detail Refund
                </h5>
            </ModalHeader>
            <Form className="needs-validation user-add" noValidate="" onSubmit={handleSubmit}>
                {data ?
                    <ModalBody>
                        <div className="d-flex mb-2">
                            <div className="col-3">Order ID </div>
                            <span className="font-weight-bold"> <Link to={`/order/${data.orders_id}`} target="_blank">{data.orders_no}</Link></span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">Customer </div>
                            <span className="font-weight-bold"> {data.customer_name}</span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">Store </div>
                            <span className="font-weight-bold"> {data.store_name}</span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">Nama Rekening </div>
                            <span className="font-weight-bold"> {data.nama}</span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">Nama Bank </div>
                            <span className="font-weight-bold"> {data.nama_bank}</span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">No. Rekening </div>
                            <span className="font-weight-bold"> {data.no_rek}</span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">Tanggal Pengajuan </div>
                            <span className="font-weight-bold"> {data.created_date}</span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">Total </div>
                            <span className="font-weight-bold"> {numberFormat(parseInt(data.total))}</span>
                        </div>
                        <div>
                            <FormGroup className="row">
                                <Label className="col-xl-3 col-md-4">
                                    Status
                                </Label>
                                <div className="col-xl-8 col-md-7">
                                    <Input
                                        className="form-control"
                                        id="status"
                                        name="status"
                                        type="select"
                                        required=""
                                        onChange={handleChange}
                                        value={values[0].status}
                                        disabled={data && data.status!=='pending' ? 'disabled' : ''}
                                        invalid={invalid[0].status ? 'invalid' : ''}
                                    >
                                        <option value=''>
                                            Pilih Salah Satu
                                        </option>
                                        <option value="pending">
                                            Pending
                                        </option>
                                        <option value="accept">
                                            Accept
                                        </option>
                                        <option value="done">
                                            Done
                                        </option>
                                        <option value="reject">
                                            Reject
                                        </option>
                                    </Input>
                                    <FormFeedback>
                                        This field is required
                                    </FormFeedback>
                                </div>
                            </FormGroup>
                        </div>
                        {data.status==='accept'?
                        <>
                        <div className="d-flex mb-2">
                            <div className="col-3">Tanggal Di Terima </div>
                            <span className="font-weight-bold"> {data.accept_date}</span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">Di Terima Oleh</div>
                            <span className="font-weight-bold"> {data.accept_by_name}</span>
                        </div>
                        </>:''}
                        {data.status==='done'?
                        <>
                        <div className="d-flex mb-2">
                            <div className="col-3">Tanggal Di Transfer </div>
                            <span className="font-weight-bold"> {data.done_date}</span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">Di Transfer Oleh</div>
                            <span className="font-weight-bold"> {data.done_by_name}</span>
                        </div>
                        </>:''}
                        {data.status==='reject'?
                        <>
                        <div className="d-flex mb-2">
                            <div className="col-3">Tanggal Di Tolak</div>
                            <span className="font-weight-bold"> {data.reject_date}</span>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-3">Di Tolak Oleh</div>
                            <span className="font-weight-bold"> {data.reject_by_name}</span>
                        </div>
                        </>:''}
                        {values[0].status === 'reject' ?
                            <div>
                                <FormGroup className="row">
                                    <Label className="col-xl-3 col-md-4">
                                        Alasan Di Tolak
                                    </Label>
                                    <div className="col-xl-8 col-md-7">
                                        <Input
                                            className="form-control"
                                            type="textarea"
                                            required=""
                                            name="reject_reason"
                                            onChange={handleChange}
                                            disabled={data && data.reject_reason ? 'disabled' : ''}
                                            value={data && data.reject_reason}
                                            invalid={invalid[0].reject_reason}
                                        />
                                        <FormFeedback>
                                            This field is required
                                        </FormFeedback>
                                    </div>
                                </FormGroup>
                            </div> : ''}
                    </ModalBody>
                    : ''}
                <ModalFooter className={`${data && data.status === 'pending' ? 'justify-content-between' : 'justify-content-end'}`}>
                    <Button
                        type="submit"
                        color="primary"
                        className={`${data && data.status === 'pending' ? '' : 'hide'}`}
                    >
                        Submit
                    </Button>
                    <Button
                        type="button"
                        color="secondary"
                        onClick={onCloseModal}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Form>

        </Modal>
    );
};

export default ModalShippingHistory;
