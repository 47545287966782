import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate, Link } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { useQuery, gql } from "@apollo/client";
import ListSaldoWithdrawRequest from "./common/list-saldo-withdraw";
import { numberFormat } from "../../helpers/custom";

const List_vendors = () => {
	const sizePerPage = 10;
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	
	return (
		<Fragment>
			<Breadcrumb title="Saldo" />
			<Container fluid={true}>
				<Card>
					<CardBody>
						<ListSaldoWithdrawRequest  />
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default List_vendors;
