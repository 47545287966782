import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import Filter from "../../common/filter_components/filter";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ModalDetailSaldoWithdrawRequest from "./modal-detail-saldo-withdraw-request";
import { numberFormat } from "../../../helpers/custom";

const GET_SALDO_WITHDRAWAL_HISTORY = gql`
  query getSaldoWithdrawalRequestHistoryStore(
    $stores_id: Int!
    $indexFrom: Int
    $limit: Int
    $status: String
    $start_date: String
    $end_date: String
    $sortBy: String
  ) {
    getSaldoWithdrawalRequestHistoryStore(
		stores_id: $stores_id
		indexFrom: $indexFrom
		limit: $limit
		status: $status
		start_date: $start_date
		end_date: $end_date
		sortBy: $sortBy
    ) {
		total
  		hasMore
  		items{
			id
			total
			status
			created_date
			created_by
			accepted_date
			accepted_by
			done_date
			done_by
			reject_by
			reject_date
			reject_reason
		}
    }
  }
`;

const TabsetProfile = ({ stores_id }) => {
	const sizePerPage = 10;
	const [detailId, setDetailId] = useState('');
	const [openModalDetail, setOpenModalDetail] = useState(false);
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState({
		date: '',
		total: '',
		status: '',
	})
	const [sortBy, setSortBy] = useState();
	const [defaultSorted, setDefaultSorted] = useState([{
		dataField: 'id',
		order: 'desc'
	}]);
	var { data, refetch } = useQuery(GET_SALDO_WITHDRAWAL_HISTORY, {
		variables: {
			stores_id: parseInt(stores_id),
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			status: filter && filter.status ? filter.status.value : '',
			start_date: filter && filter.date ? filter.date.start : '',
			end_date: filter && filter.date ? filter.date.end : '',
			sortBy: sortBy,
		},
	});

	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '10%' };
			}
		},
		{
			dataField: 'created_date',
			text: 'Date',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '15%' };
			}
		},
		{
			dataField: 'total',
			text: 'Total',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat(parseInt(row.total))
			),
		},
		{
			dataField: 'status',
			text: 'Status',
			sort: true,
		},
		{
			dataField: '',
			text: '',
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center">
					<Link onClick={()=>showModalDetail(row.id)} >
						<i className="fa fa-eye" />
					</Link>
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: '13%' };
			}
		}
	];
	const showModalDetail = (id) => {
		setDetailId(id);
		setOpenModalDetail(true);
	}
	
	const filterOptions = [
		{
			id: 'date',
			label: 'Date',
			value: filter.date,
			type: 'date',
			className: 'first-column',
		},
		{
			id: 'status',
			label: 'Status',
			value: filter.status,
			type: 'dropdown',
			options: [
				{
					label: 'All',
					value: '',
				},
				{
					label: 'Pending',
					value: 'Pending',
				},
				{
					label: 'Accept',
					value: 'Accept',
				},
				{
					label: 'Done',
					value: 'Done',
				},
				{
					label: 'Reject',
					value: 'Reject',
				}
			]
		},
	]

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		if (page) {
			setPage(page)
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + '-' + sortOrder)
			setDefaultSorted([{
				dataField: sortField,
				order: sortOrder
			}])
		}
	}
	const handleFilterChange = (e) => {
		refetch({
			stores_id: parseInt(stores_id),
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			status: filter && filter.status ? filter.status.value : '',
			start_date: filter && filter.date ? filter.date.start : '',
			end_date: filter && filter.date ? filter.date.end : '',
			sortBy: sortBy,
		})
		setFilter(e);
	}

	return (
		<>
			<h5 className="f-w-600 f-16 pull-left">
				Withdraw Request History
			</h5>
		<div className="btn-popup pull-right">
			<Link to="/saldo/withdraw/form/0" className="btn btn-primary">
				Withdraw
			</Link>
		</div>
			<div className="clearfix"></div>
			<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} />
			<div
				id="batchDelete"
				className="category-table store-list order-table coupon-list-delete"
			>
				{data ?
					<>
					<BootstrapTable
						keyField='id'
						hover
						condensed
						remote={{ sort: true, pagination: true }}
						data={data && data.getSaldoWithdrawalRequestHistoryStore ? data.getSaldoWithdrawalRequestHistoryStore.items : []}
						columns={columns}
						bordered={false}
						noDataIndication="Table is Empty"
						pagination={paginationFactory(
							{
								sizePerPage: sizePerPage,
								page: page,
								hideSizePerPage: true,
								hidePageListOnlyOnePage: true,
								totalSize: data && data.getSaldoWithdrawalRequestHistoryStore ? data.getSaldoWithdrawalRequestHistoryStore.total : 0
							})}
						defaultSorted={defaultSorted}
						onTableChange={handleTableChange}
						wrapperClasses="table-responsive"
					/>
					<ModalDetailSaldoWithdrawRequest id={detailId} openModalDetail={openModalDetail} setOpenModalDetail={setOpenModalDetail} />
					</>
					: ''}
			</div>
		</>
	);
};

export default TabsetProfile;
