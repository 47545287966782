import React, { useState, useRef, useEffect } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Spinner } from "reactstrap";


function MyMapComponent({ center, zoom, marker, setMarker, className }) {
    const ref = useRef();
    const prevMarkersRef = useRef([]);
    const [map, setMap] = React.useState();

    const onClick = (e) => {
        setMarker({ lat: parseFloat(e.latLng.lat()), lng: parseFloat(e.latLng.lng()) });
        clearMarkers(prevMarkersRef.current)
        createMarker({ lat: parseFloat(e.latLng.lat()), lng: parseFloat(e.latLng.lng()) }, map)
    };

    function clearMarkers(markers) {
        for (let m of markers) {
            m.setMap(null);
        }
    }
    const createMarker = (marker, map) => {
        if (marker) {
            const curr_marker = new window.google.maps.Marker({
                position: { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) },
                map: map,
            });
            if (map) {
                map.setCenter(marker);
                map.setZoom(17);
            }
            prevMarkersRef.current.push(curr_marker);
        }
    };
    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                center,
                zoom,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                scaleControl: false,
            }));

        } else {
            if (!document.getElementById('currentLocation')) {
                let infoWindow = new window.google.maps.InfoWindow();
                const locationButton = document.createElement("button");
                locationButton.id = "currentLocation";
                locationButton.type = "button";
                locationButton.innerHTML = "<i class='fa fa-crosshairs'>";
                locationButton.classList.add("custom-map-control-button");
                map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(locationButton);
                locationButton.addEventListener("click", (e) => {
                    e.preventDefault()
                    // Try HTML5 geolocation.
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                if (position) {
                                    const pos = {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude,
                                    };
                                    map.setCenter(pos);
                                    map.setZoom(17);
                                    setMarker(pos);
                                    clearMarkers(prevMarkersRef.current)
                                    createMarker(pos, map)
                                }
                            },
                            () => {
                                handleLocationError(true, infoWindow, map.getCenter());
                            }
                        );
                    } else {
                        // Browser doesn't support Geolocation
                        handleLocationError(false, infoWindow, map.getCenter());
                    }
                });
            }
        }
    }, [ref, map]);

    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation
                ? "Error: The Geolocation service failed."
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(map);
    }
    useEffect(() => {
        if (marker) {
            createMarker(marker, map)
        }

    }, [marker, map]);
    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );
            map.addListener("click", onClick);
        }
    })

    return <div className={className} ref={ref} id="map" style={{ height: "500px" }} />;
}

const Googlemaps = ({ marker, setMarker, className }) => {
    const [zoom, setZoom] = useState(marker ? 17 : 5);
    const [center, setCenter] = useState(marker ? marker : { lat: 0.059529, lng: 117.488024 });

    const render = (status) => {
        return <Spinner />;
    };

    useEffect(() => {
    }, [marker])

    return (

        <Wrapper apiKey={process.env.REACT_APP_GMAP_TOKEN} render={render}>
            <MyMapComponent className={className} center={center} zoom={parseInt(zoom)} marker={marker} setMarker={setMarker}>
            </MyMapComponent>
        </Wrapper>
    )
}

export default Googlemaps;
