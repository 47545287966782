import {
  setKey,
  fromAddress,
} from "react-geocode";
export function getGeolocFromAddress(address, district, city, province, postcode) {
  setKey(process.env.REACT_APP_GMAP_TOKEN); // Your API key here.

  const geolog = fromAddress(address + ', ' + district + ', ' + city + ', ' + province + ', ' + postcode)
    .then(({ results }) => {
      const { lat, lng } = results[0].geometry.location;
      return { lat: lat, lng: lng }
    })
    .catch(console.error);
  return geolog;
}
