import React, { Fragment, useState, useEffect } from "react";
import { Button, Form, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const UPDATE_PROFILE = gql`
  mutation updateProfile($input: ProfileInput) {
    updateProfile(input: $input) {
        success,
        message,
    }
  }
`;

const TabsetUser = ({ data, id, isPublic }) => {
	let navigate = useNavigate();
	const [values, setValues] = useState([{
		id: data.id,
		name: data.name,
		email: data.email,
		phone: data.phone,
		password: '',
		c_password: '',
	}]);
	const [invalid, setInvalid] = useState([{
		name: '',
		email: '',
		phone: '',
		address: '',
		password: '',
		c_password: '',
	}]);
	const [updateTodo] = useMutation(UPDATE_PROFILE);

	useEffect(() => {

		setValues([{
			id: data.id,
			name: data.name,
			email: data.email,
			phone: data.phone,
			password: '',
			c_password: '',
		}]);
	}, [data])
	const handleChange = (event) => {
		let tmp_data = [...values];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_data[0][name] = value;
		setValues(tmp_data);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;
		if (!inputData[0]['name']) {
			valid += 1;
			invalidData[0]['name'] = 'invalid';
		} else {
			invalidData[0]['name'] = '';
		}
		var emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
		if (!inputData[0]['email'] || !emailRegex.test(inputData[0]['email'])) {
			valid += 1;
			invalidData[0]['email'] = 'invalid';
		} else {
			invalidData[0]['email'] = '';
		}
		if (!inputData[0]['phone'] || !Number.isInteger(parseInt(inputData[0]['phone']))) {
			valid += 1;
			invalidData[0]['phone'] = 'invalid';
		} else {
			invalidData[0]['phone'] = '';
		}

		if (inputData[0]['password'] || inputData[0]['c_password']) {
			if (!inputData[0]['password']) {
				valid += 1;
				invalidData[0]['password'] = 'Harap mengisi field ini.';
			} else {
				invalidData[0]['password'] = '';
			}
			if (!inputData[0]['c_password']) {
				valid += 1;
				invalidData[0]['c_password'] = 'Harap mengisi field ini.';
			} else {
				invalidData[0]['c_password'] = '';
			}
			if (inputData[0]['password'] !== inputData[0]['c_password']) {
				valid += 1;
				invalidData[0]['c_password'] = 'Password tidak sama';
			} else {
				invalidData[0]['c_password'] = '';
			}
		} else {
			invalidData[0]['password'] = '';
			invalidData[0]['c_password'] = '';
		}
		if (valid > 0) {
			setInvalid(invalidData);
			return false;
		}
		delete (inputData[0]['c_password']);
		const result = await updateTodo({ variables: { input: inputData[0] } });
		if (result.data.updateProfile.success) {
			toast.success(result.data.updateProfile.message)
			return navigate("/profile", { replace: true });
		} else {
			toast.error(result.data.updateProfile.message)
		}

	};

	return (
		<Fragment>
			<Form className="needs-validation user-add" noValidate="" onSubmit={handleSubmit}>
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						<span>*</span> Nama
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							id="name"
							name="name"
							type="text"
							required=""
							onChange={(e) => handleChange(e)}
							value={values[0].name}
							invalid={invalid[0].name ? 'invalid' : ''}
						/>
						<FormFeedback>
							Harap mengisi field ini.
						</FormFeedback>
					</div>
				</FormGroup>
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						<span>*</span> Email
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							id="email"
							name="email"
							type="email"
							onChange={(e) => handleChange(e)}
							value={values[0].email}
							invalid={invalid[0].email ? 'invalid' : ''}
						/>
						<FormFeedback>
							Harap mengisi field ini.
						</FormFeedback>
					</div>
				</FormGroup>
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						<span>*</span> Telp
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							id="phone"
							name="phone"
							type="text"
							required=""
							onChange={(e) => handleChange(e)}
							value={values[0].phone}
							invalid={invalid[0].phone ? 'invalid' : ''}
						/>
						<FormFeedback>
							Harap mengisi field ini.
						</FormFeedback>
					</div>
				</FormGroup>
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						Password Baru
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							id="password"
							name="password"
							type="password"
							onChange={(e) => handleChange(e)}
							value={values[0].password}
							invalid={invalid[0].password ? 'invalid' : ''}
						/>
						<FormFeedback>
							{invalid[0].password}
						</FormFeedback>
					</div>
				</FormGroup>
				<FormGroup className="row">
					<Label className="col-xl-3 col-md-4">
						Konfirmasi Password Baru
					</Label>
					<div className="col-xl-8 col-md-7">
						<Input
							className="form-control"
							id="c_password"
							name="c_password"
							type="password"
							onChange={(e) => handleChange(e)}
							value={values[0].c_password}
							invalid={invalid[0].c_password ? 'invalid' : ''}
						/>
						<FormFeedback>
							{invalid[0].c_password}
						</FormFeedback>
					</div>
				</FormGroup>
				<FormGroup className="row">
					<div className="pull-right">
						<Button type="submit" color="primary">
							Save
						</Button>
					</div>
				</FormGroup>
			</Form>
		</Fragment>
	);
};

export default TabsetUser;
