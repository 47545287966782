import React, { Fragment, useRef, useState } from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Breadcrumb from "../common/breadcrumb";
//import Income from "./common/income";
//import Expense from "./common/expense";
import TotalStore from "./common/totalStore";
import TotalProduct from "./common/totalProduct";
import OrderSuccessCanceled from "./common/orderSuccessCanceled";
import OrderNew from "./common/orderNew";
import OrderSent from "./common/orderSent";
import OrderComplaint from "./common/orderTable";
import Finance from "./common/finance";
//import TotalTransaction from "./common/totalTransaction";
//import TotalTransactionGross from "./common/totalTransactionGross";
//import TotalMidtransFee from "./common/totalMidtransFee";
//import TotalServiceFee from "./common/totalServiceFee";
//import TotalShippingCost from "./common/totalShippingCost";

import { Col, Container, Row } from "reactstrap";

const Dashboard = () => {
	const user = JSON.parse(localStorage.getItem('user'));
	const privilege = user.privilege;
	const myRef = useRef();
    const [startDate, setStartDate] = useState(moment().subtract(1, 'months').format('MM/DD/YYYY'));
    const [endDate, setEndDate] = useState(moment().format('MM/DD/YYYY'));
	const [value, setValue] = useState(startDate + ' - ' + endDate);

	function rearrangeDatePickerPosition() {
		const dateRangePicker = document.getElementsByClassName('daterangepicker');
		for (let i = 0; i < dateRangePicker.length; i++) {
			const top = dateRangePicker[i].style['top'];
			if (top) {
				dateRangePicker[i].style.top = (parseInt(top.replace('px', '')) - 76) + 'px';
			}
		};
	}

	const handleShowDatePicker = (e) => {
		rearrangeDatePickerPosition();
	}
	const handleShowCalendarDatePicker = (e) => {
		rearrangeDatePickerPosition();
	}

	const handleApply = (event, picker) => {
		const start_date = moment(picker.startDate).format('MM/DD/YYYY');
		const end_date = moment(picker.endDate).format('MM/DD/YYYY');
		setStartDate(start_date)
		setEndDate(end_date)
		setValue(start_date + ' - ' + end_date);
	}

	return (
		<Fragment>
			<Breadcrumb title="Dashboard" parent="" />
			<Container fluid={true}>
				<div style={{ marginBottom: "1rem", display: "flex", justifyContent: "end" }}>
					<Col xl="3 xl-50" md="4">
						<DateRangePicker
							ref={myRef}
							initialSettings={{
								local: 'id',
								autoUpdateInput: false,
								alwaysShowCalendars: true,
								opens: "center",
								startDate: startDate,
								endDate: endDate,
								ranges:{
									'Today': [moment(), moment()],
									'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
									'Last 7 Days': [moment().subtract(6, 'days'), moment()],
									'Last 30 Days': [moment().subtract(29, 'days'), moment()],
									'This Month': [moment().startOf('month'), moment().endOf('month')],
									'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
								}
							}}
							onShow={handleShowDatePicker}
							onShowCalendar={handleShowCalendarDatePicker}
							onApply={handleApply}
						>
							<input type="text" className="form-control" placeholder="dd/mm/yyyy - dd/mm/yyyy" value={value} />
						</DateRangePicker>
					</Col>
				</div>
				<Row style={{ justifyContent: "center" }}>

					<div className="d-flex row" >
						<Col className="d-flex row">
							{privilege !== 'Stores' ?
								<Col xl="6 xl-50" md="6">
									<TotalStore startDate={startDate} endDate={endDate} />
								</Col> : ''}
							{privilege !== 'Stores' ?
								<Col xl="6 xl-50" md="6">
									<TotalProduct startDate={startDate} endDate={endDate}  />
								</Col> : ''}
							<Col xl="6 xl-50" md="6">
								<OrderNew user={user} startDate={startDate} endDate={endDate}  />
							</Col>
							<Col xl="6 xl-50" md="6">
								<OrderSent user={user} startDate={startDate} endDate={endDate}  />
							</Col>
						</Col>
						<Col className="d-flex row">
							<Finance user={user} startDate={startDate} endDate={endDate}  />
						</Col>
					</div>
					<Col xl="6 xl-100">
						<OrderSuccessCanceled user={user} />
					</Col>
					<Col xl="6 xl-100">
						<OrderComplaint user={user} />
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Dashboard;
