/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { Card, CardBody, Col, Table, Modal, ModalBody, ModalFooter, ModalHeader, Button, Form, FormGroup, Label, FormFeedback, Input } from "reactstrap";
import { useQuery, gql } from '@apollo/client';
import { toast } from "react-toastify";
import { useEffect } from "react";
import moment from "moment";
import { numberFormat } from "../../../helpers/custom";
import Select from 'react-select'

const GET_PRODUCTS = gql`
  query (
	$status: String
  ) {
    products(
      status: $status
    ) {
      total
      hasMore
      items {
        id
        title
        price
		status
        variants {
          id
          title
          price
          image
        }
        images {
          src
        }
      }
    }
  }
`;

const ModalShippingHistory = ({ productKey, setProductKey, openModal, setOpenModal, productValues, setProductValues }) => {
    const [data, setData] = useState([]);
    const [optionData, setOptionData] = useState([]);
    const [formData, setFormData] = useState([{
        products_id: '',
        qty: '',
    }])
    const [invalidFormData, setInvalidFormData] = useState([{
        products_id: '',
        qty: '',
    }])
    var { refetch } = useQuery(GET_PRODUCTS, {
        variables: {
            status: 'active',
        },
    });
    useEffect(() => {
        if (openModal) {
            const fetchData = async () => {
                let datas = await refetch({
                    status: 'active',
                });
                setData(datas.data)
                createOptions(datas.data)
            }
            fetchData()
        }
    }, [openModal])

    useEffect(() => {
        if (parseInt(productKey)) {
            const productKeys = productKey-1; 
            setFormData([{
                products_id: {
                    value:productValues[productKeys].products_id+'-'+productValues[productKeys].products_variant_id,
                    label:productValues[productKeys].products_variant_id?productValues[productKeys].products_title+' - '+productValues[productKeys].products_variant_title:productValues[productKeys].products_title,
                    products_id: productValues[productKeys].id,
                    products_title: productValues[productKeys].products_title,
                    products_price: parseInt(productValues[productKeys].products_price),
                    products_img: productValues[productKeys].products_img,
                    products_variant_id: productValues[productKeys].products_variant_id,
                    products_variant_title: productValues[productKeys].products_variant_title,
                    products_variant_price: parseInt(productValues[productKeys].products_variant_price),
                    products_variant_img: productValues[productKeys].products_variant_img,
                },
                qty: productValues[productKeys].qty,
            }]
            
            )
        }
    }, [productKey])

    const onCloseModal = async () => {
        setFormData([{
            products_id: '',
            qty: '',
        }]);
        setInvalidFormData([{
            products_id: '',
            qty: '',
        }])
        setOpenModal(false);
    }

    const createOptions = (datas) => {
        let options = [];
        if (datas && datas.products) {
            datas.products.items.forEach(e => {
                if (e.variants.length > 0) {
                    e.variants.forEach(f => {
                        options.push({
                            label: e.title + ' - ' + f.title,
                            value: e.id + '-' + f.id,
                            products_id: e.id,
                            products_title: e.title,
                            products_price: parseInt(e.price),
                            products_img: e.images[0].src,
                            products_variant_id: f.id,
                            products_variant_title: f.title,
                            products_variant_price: parseInt(f.price),
                            products_variant_img: f.image,
                        })
                    })
                } else {
                    options.push({
                        label: e.title,
                        value: e.id + '-',
                        products_id: e.id,
                        products_title: e.title,
                        products_price: parseInt(e.price),
                        products_img: e.images[0].src,
                        products_variant_id: '',
                        products_variant_title: '',
                        products_variant_price: '',
                        products_variant_img: '',
                    })
                }
            })
        }
        setOptionData(options);
    }
    const handleChange = (e, names) => {
        let tmpFormDatas = [...formData]
        if (e.target) {
            const name = e.target.name;
            const value = e.target.value;
            tmpFormDatas[0][name] = value;
        } else {
            //tmpFormDatas[0][names + '_id'] = e.value;
            //tmpFormDatas[0][names + '_name'] = e.label;
            tmpFormDatas[0][names] = e;

        }
        setFormData(tmpFormDatas);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let productValue = [...productValues];
		const inputData = formData;
        let invalidData = [...invalidFormData];
		let valid = 0;
		if (!inputData[0]['products_id']) {
			valid += 1;
			invalidData[0]['products_id'] = 'invalid';
		} else {
			invalidData[0]['products_id'] = '';
		}
		if (!inputData[0]['qty']) {
			valid += 1;
			invalidData[0]['qty'] = 'invalid';
		} else {
			invalidData[0]['qty'] = '';
		}
		if (valid > 0) {
			setInvalidFormData(invalidData);
			return false;
		}
        const check_exist = productValue.filter((e,key)=>{
            if(e.products_id === formData[0].products_id.products_id && e.products_variant_id === formData[0].products_id.products_variant_id){
                const qty = productKey>0?parseInt(formData[0].qty):parseInt(productValue[key].qty)+parseInt(formData[0].qty)
                productValue[key].qty = qty
                productValue[key].total = qty * parseInt(productValue[key].products_variant_price ? productValue[key].products_variant_price : productValue[key].products_price)
            }
            return e.products_id === formData[0].products_id.products_id && e.products_variant_id === formData[0].products_id.products_variant_id
        })
        if(check_exist.length === 0){
            productValue.push({
                id: 0,
                checkout_link_id: 0,
                products_id: formData[0].products_id.products_id,
                products_title: formData[0].products_id.products_title,
                products_price: parseInt(formData[0].products_id.products_price),
                products_img: formData[0].products_id.products_img,
                products_variant_id: formData[0].products_id.products_variant_id?formData[0].products_id.products_variant_id:null,
                products_variant_title: formData[0].products_id.products_variant_title,
                products_variant_price: parseInt(formData[0].products_id.products_variant_price),
                products_variant_img: formData[0].products_id.products_variant_img,
                qty: parseInt(formData[0].qty),
                total: formData[0].qty * parseInt(formData[0].products_id.products_variant_price ? formData[0].products_id.products_variant_price : formData[0].products_id.products_price)
            })
        }
        setProductValues(productValue);
        setProductKey('');
        setOpenModal(false);
        setFormData([{
            products_id: '',
            qty: '',
        }]);
    }

    return (
        <Modal
            isOpen={openModal}
            toggle={onCloseModal}
            style={{ overlay: { opacity: 0.1 } }}
        >
            <ModalHeader toggle={onCloseModal}>
                <div className="modal-title f-w-600" id="exampleModalLabel2">
                    Add Product
                </div>
            </ModalHeader>
            <Form className="needs-validation user-add" noValidate="" onSubmit={handleSubmit}>
                <ModalBody>
                    <FormGroup className="form-group mb-3 row">
                        <Label className="col-xl-3 col-sm-4 my-auto">
                            Product :
                        </Label>
                        <div className="col-xl-8 col-sm-7 form form-label-center add-product-form">
                            <div className="input-group">
                                <Select
                                    className={`form-select ${invalidFormData[0].products_id ? 'is-invalidFormData' : ''}`}
                                    classNamePrefix="select"
                                    name="products_id"
                                    id="products_id"
                                    type="select"
                                    value={formData[0].products_id}
                                    onChange={(e) => handleChange(e, 'products_id')}
                                    invalidFormData={invalidFormData[0].products_id ? 'invalidFormData' : ''}
                                    placeholder="Pilih Salah Satu"
                                    options={optionData}
                                />
                                <br/>
                                <div className={`invalid-feedback ${invalidFormData[0].products_id ? 'is-invalid' : 'hide'}`}>
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <div className="valid-feedback">Looks good!</div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 row">
                        <Label className="col-xl-3 col-sm-4 my-auto">
                            Quantity :
                        </Label>
                        <div className="col-xl-8 col-sm-7">
                            <Input
                                className="form-control"
                                name="qty"
                                id="qty"
                                type="text"
                                value={formData[0].qty}
                                onChange={handleChange}
                                invalid={invalidFormData[0].qty ? 'invalid' : ''}
                            />
                            <FormFeedback>
                                This field is required
                            </FormFeedback>
                        </div>
                        <div className="valid-feedback">Looks good!</div>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        color="disabled"
                        onClick={onCloseModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default ModalShippingHistory;
