import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { numberFormat } from "../../../helpers/custom";


const GET_SALDO_MUTASI = gql`
  query getSaldoHistoryStore(
    $stores_id: Int!
  ) {
    getSaldoHistoryStore(
		stores_id: $stores_id
    ) {
		total
  		hasMore
  		items{
			date
			notes
			debit
			kredit
		}
    }
  }
`;


const defaultSorted = [{
	dataField: 'name',
	order: 'desc'
}];

const TabsetProfile = ({ stores_id }) => {
	const sizePerPage = 10;
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));

	useEffect(() => {
		if (!user || user.privilege !== 'Stores') {
			return navigate("/dashboard", { replace: true });
		}
	}, [])
	var { data } = useQuery(GET_SALDO_MUTASI, {
		variables: {
			stores_id: parseInt(stores_id),
		},
	});

	const columns = [
		{
			dataField: 'date',
			text: 'Date',
		},
		{
			dataField: 'notes',
			text: 'Order ID / Withdraw ID',
		},
		{
			dataField: 'debit',
			text: 'Debit',
			formatter: (cell, row, rowIndex, extraData) => (
				parseInt(row.debit) >= 0 ? numberFormat(parseInt(row.debit)) : ''
			),
		},
		{
			dataField: 'kredit',
			text: 'Kredit',
			formatter: (cell, row, rowIndex, extraData) => {
				return (parseInt(row.kredit) >=0 ? numberFormat(parseInt(row.kredit)) : '')
			},

		},
	];

	return (
		<>
			<div
				id="batchDelete"
				className="category-table store-list order-table coupon-list-delete"
			>
				{data ?
					<>
						<BootstrapTable
							keyField='id'
							hover
							condensed
							data={data && data.getSaldoHistoryStore ? data.getSaldoHistoryStore.items : []}
							columns={columns}
							bordered={false}
							noDataIndication="Table is Empty"
							pagination={paginationFactory(
								{
									sizePerPage: sizePerPage,
									hideSizePerPage: true,
									hidePageListOnlyOnePage: true,
									totalSize: data && data.getSaldoHistoryStore ? data.getSaldoHistoryStore.total : 0
								})}
							filter={filterFactory()}
							filterPosition="top"
							defaultSorted={defaultSorted}
							wrapperClasses="table-responsive"
						/>
					</>
					: ''}
			</div>
		</>
	);
};

export default TabsetProfile;
