import React, { useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { Card, CardBody, Col, Table, Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { useQuery, gql } from '@apollo/client';
import { toast } from "react-toastify";
import { useEffect } from "react";
import moment from "moment";

const GET_SHIPPING_HISTORY = gql`
  query getPickupHistory($stt:String!) {
    getPickupHistory(stt: $stt) {
        stt_id
        shipment_id
        shipped_time
        delivered_time
        sender_name
        recipient_name
        origin
        destination
        product_type
        current_status
        driver_detail
        shipping_vendor
        shipping_product
        history{
            date_time
            status_code
            status
            note
            location
            city
            receiver
            updated_by
        }
    }
  }
`;

const ModalShippingHistory = ({ stt, openModalShippingHistory, setOpenModalShippingHistory }) => {
    const [data, setData] = useState([]);
    var { refetch } = useQuery(GET_SHIPPING_HISTORY, {
        variables: {
            stt: stt,
        },
    });
    useEffect(() => {
        if (openModalShippingHistory) {
            const fetchData = async () => {
                let datas = await refetch({
                    variables: {
                        stt: stt,
                    }
                });
                setData(datas.data)
            }

            // call the function
            fetchData()
        }
    }, [openModalShippingHistory])

    const handleCheckPakages = async () => {

    }

    const onCloseModal = async () => {
        setOpenModalShippingHistory(false);
    }

    return (
        <Modal
            isOpen={openModalShippingHistory}
            toggle={onCloseModal}
            style={{ overlay: { opacity: 0.1 } }}
        >
            <ModalHeader toggle={onCloseModal}>
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    Lacak Paket
                </h5>
            </ModalHeader>
            {data && data.getPickupHistory ?
                <ModalBody>
                    <div>
                        <span>No. Resi : </span>
                        <span className="font-weight-bold">{data.getPickupHistory.shipment_id}</span>
                    </div>
                    <div>
                        Dikirim menggunakan <span className="font-weight-bold">{data.getPickupHistory.shipping_vendor} - {data.getPickupHistory.shipping_product}</span>
                    </div>
                    <div class="shippingHistory">
                        
                        {data.getPickupHistory.history.map(e => {
                            return (
                                <div class="shippingHistory-sub">
                                    <div className="col-2 date">{moment(e.date_time).format('DD MMM, HH:mm')}</div>
                                    <div className="col-1 strips"><i className="fa fa-circle" /></div>
                                    <div className="col-9 remarks">{e.status}</div>
                                </div>)
                        })}
                    </div>
                    <div></div>
                </ModalBody>
                : ''}
            <ModalFooter>
                <Button
                    type="button"
                    color="secondary"
                    onClick={onCloseModal}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalShippingHistory;
