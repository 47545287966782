import React, { useState, useEffect } from "react";
import Sidebar from "./common/sidebar_components/sidebar";
import SidebarStore from "./common/sidebar_components/sidebarStore";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import Header from "./common/header_components/header";
import { Outlet, useNavigate } from "react-router-dom";
import { MENUITEMS } from "../constants/menu";
import { MENUSTOREITEMS } from "../constants/menuStore";

const App = (props) => {
	const initialState = {
		ltr: true,
		divName: "RTL",
	};

	const [side, setSide] = useState(initialState);
	const user = JSON.parse(localStorage.getItem('user'));
	const history = useNavigate();
	
    useEffect(() => {
        if (localStorage.getItem('user') === 'false' || localStorage.getItem('user') === null) {
            history("/");
        }
    }, []);

	const ChangeRtl = (divName) => {
		if (divName === "RTL") {
			document.body.classList.add("rtl");
			setSide({ divName: "LTR" });
		} else {
			document.body.classList.remove("rtl");
			setSide({ divName: "RTL" });
		}
	};
	return (
		<div>
			<div className="page-wrapper">
				<Header />
				<div className="page-body-wrapper">
					{<Sidebar MENUITEMS={user && user.privilege === 'Stores'?MENUSTOREITEMS:MENUITEMS}/>}
					{/*<SidebarStore />*/}
					{/*user && user.privilege === 'Stores'?<SidebarStore />:<Sidebar />*/}
					{/*<RightSidebar />*/}
					<div className="page-body"><Outlet/></div>
					<Footer />
				</div>
			</div>
		</div>
	);
};
export default App;
