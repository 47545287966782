import React, { Fragment, useState, useEffect } from "react";
import { Tabs, TabPanel } from "react-tabs";
import { Button, Form, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Zoom from 'react-medium-image-zoom'

const GET_INFO = gql`
  query getStoreRegistration(
    $id: Int!
  ) {
	getStoreRegistration(
		id: $id
		) {
			id
			store_name
			user_name
			no_ktp
			foto_ktp
			user_name
			email
			phone
			store_description
			product_description
			ref_province_id
			ref_province_name
			ref_city_id
			ref_city_name
			ref_district_id
			ref_district_name
			ref_postcode_id
			ref_postcode_name
			detail_address
			geoloc
			reject_date
			reject_reason
			reject_by
			reject_by_name
			approve_by
			approve_by_name
			approve_date
			created_date
	}
  }
`;

const CREATE_STORE = gql`
  mutation updateStoreRegistration($input: StoreRegistrationInput) {
    updateStoreRegistration(input: $input) {
        success,
        message,
    }
  }
`;

const TabsetUser = ({ id, isPublic }) => {
	const user = JSON.parse(localStorage.getItem('user'));
	let navigate = useNavigate();
	const [values, setValues] = useState([{
		status: '',
		reject_reason: '',
	}]);
	const [invalid, setInvalid] = useState([{
		status: '',
		reject_reason: '',
	}]);

	const [addTodo] = useMutation(CREATE_STORE);

	var { data } = useQuery(GET_INFO, {
		variables: {
			id: id ? parseInt(id) : '',
		},
	});

	useEffect(() => {
		if (data && data.getStoreRegistration) {
			const tmp_data = {
				status: data.getStoreRegistration.approve_date ? 'approve' : data.getStoreRegistration.reject_date ? 'reject' : 'pending',
				reject_reason: data.getStoreRegistration.reject_reason,
			}
			setValues([tmp_data])
		}
	}, [data])

	const handleChange = (event) => {
		let tmp_data = [...values];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_data[0][name] = value;
		tmp_data[0]['file'] = target.type === 'file' ? target.files : tmp_data[0].file;
		setValues(tmp_data);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;

		if (inputData[0]['status'] === 'pending') {
			valid += 1;
			invalidData[0]['status'] = 'invalid';
		} else {
			invalidData[0]['status'] = '';
		}

		if (inputData[0]['status'] === 'reject') {
			if (!inputData[0]['reject_reason']) {
				valid += 1;
				invalidData[0]['reject_reason'] = 'invalid';
			} else {
				invalidData[0]['reject_reason'] = '';
			}
		}

		if (valid > 0) {
			setInvalid(invalidData);
			return false;
		}

		if (id > 0) {
			inputData[0].uid = parseInt(user.id)
			inputData[0].id = parseInt(id)
			const result = await addTodo({ variables: { input: inputData[0] } });
			if (result.data.updateStoreRegistration.success) {
				toast.success(result.data.updateStoreRegistration.message)
				return navigate("/pendaftaran-toko", { replace: true });
			} else {
				toast.error(result.data.updateStoreRegistration.message)
			}
		}
	};

	return (
		<Fragment>
			<Tabs>
				<TabPanel>
					<Form className="needs-validation user-add" noValidate="" onSubmit={handleSubmit}>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Nama Toko
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									disabled
									value={data?.getStoreRegistration?.store_name}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Nama Sesuai KTP
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									disabled
									value={data?.getStoreRegistration?.user_name}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								No KTP
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									disabled
									value={data?.getStoreRegistration?.no_ktp}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Foto KTP
							</Label>
							<div className="col-xl-8 col-md-7">
								<Zoom >
									<img className="p-2"
										width="500"
										alt="" src={data && data.getStoreRegistration? process.env.REACT_APP_IMAGE_URL + data.getStoreRegistration.foto_ktp:''} />
								</Zoom>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Email
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									disabled
									value={data?.getStoreRegistration?.email}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Phone
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									disabled
									value={data?.getStoreRegistration?.phone}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Provinsi
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									disabled
									type="text"
									value={data?.getStoreRegistration?.ref_province_name}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Kota
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									type="text"
									disabled
									value={data?.getStoreRegistration?.ref_city_name}
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>

						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Kecamatan
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									type="text"
									disabled
									value={data?.getStoreRegistration?.ref_district_name}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Alamat
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									type="textarea"
									className="form-control"
									disabled
									value={data?.getStoreRegistration?.detail_address}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Kodepos
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									type="text"
									disabled
									value={data?.getStoreRegistration?.ref_postcode_name}
								/>

							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Deskripsi Toko
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									type="textarea"
									disabled
									value={data?.getStoreRegistration?.store_description}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Deskripsi Product
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									type="textarea"
									disabled
									value={data?.getStoreRegistration?.product_description}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								Status
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									id="status"
									name="status"
									type="select"
									onChange={(e) => handleChange(e)}
									invalid={invalid[0].status ? 'invalid' : ''}
									disabled={!data?.getStoreRegistration?.approve_date && !data?.getStoreRegistration?.reject_date ? '' : 'disabled'}
									value={values[0].status}
								>
									<option value="pending">
										Pending
									</option>
									<option value="approve">
										Di Terima
									</option>
									<option value="reject">
										Di Tolak
									</option>
								</Input>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>

						{data?.getStoreRegistration?.reject_date ?
							<>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">
										Tgl Di Tolak
									</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											value={data.getStoreRegistration.reject_date}
											disabled={values[0].status === 'pending' || values[0].status === '' ? '' : 'disabled'}
										/>
									</div>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">
										Di Tolak Oleh
									</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											value={data?.getStoreRegistration?.reject_by_name}
											disabled={values[0].status === 'pending' || values[0].status === '' ? '' : 'disabled'}
										/>
									</div>
								</FormGroup>
							</>
							: ''}
						{values[0].status === 'reject' ?
							<>
								<FormGroup className="row">
									<Label className="col-xl-3 col-md-4">
										Alasan di tolak
									</Label>
									<div className="col-xl-8 col-md-7">
										<Input
											className="form-control"
											id="reject_reason"
											name="reject_reason"
											type="textarea"
											required=""
											onChange={(e) => handleChange(e)}
											value={values[0].reject_reason}
											invalid={invalid[0].reject_reason ? 'invalid' : ''}
											disabled={!data?.getStoreRegistration?.approve_date && !data?.getStoreRegistration?.reject_date ? '' : 'disabled'}
										/>
										<FormFeedback>
											This field is required
										</FormFeedback>
									</div>
								</FormGroup>
							</>
							: ''}

						{!data?.getStoreRegistration?.approve_date && !data?.getStoreRegistration?.reject_date ?
							<FormGroup className="row">
								<div className="pull-right">
									<Button type="submit" color="primary">
										Save
									</Button>
								</div>
							</FormGroup> : ''}
					</Form>
				</TabPanel>
			</Tabs>
		</Fragment>
	);
};

export default TabsetUser;
