import React, { Fragment } from "react";
import TabsetUserToko from "./common/form-user-toko";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const Create_vendors = () => {
	const user = JSON.parse(localStorage.getItem('user'));

	return (
		<Fragment>
			<Breadcrumb title="Ubah Profil" parent="Profil" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<TabsetUserToko slug={user.stores_slug} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Create_vendors;
