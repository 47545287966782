import React, { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TabsetProduct from "./common/detail-shipping-product";
import Breadcrumb from "../../common/breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";


const TokoDetail = () => {
	let { id } = useParams();
	let navigate = useNavigate();
	const [shippingName, setShippingName] = useState('');
	const user = JSON.parse(localStorage.getItem('user'));
	useEffect(() => {
		if (!user || user.privilege === 'Stores') {
		return navigate("/dashboard", { replace: true });
	}
	}, [])
	return (
		<Fragment>
			<Breadcrumb title="Detail Shipping" parent="Shipping" />
			<Container fluid={true}>
				<Row>
					<Col xl="12">
					{shippingName?
						<Card className="toko-detail-card">
							<CardBody>
								<h3>{shippingName}</h3>
							</CardBody>
						</Card>:''}
					</Col>
					<Col xl="12">
						<Card className="toko-detail-card">
							<CardBody>
								<TabsetProduct id={id} setShippingName={setShippingName} user={user}/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default TokoDetail;
