import React,{ Fragment } from "react"
import { Route, Routes } from "react-router-dom"
import Login from "../components/auth/login"
import ForgotPassword from "../components/auth/forgotpassword"
import LayoutRoutes from "./LayoutRoutes"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Routers =() =>{

    return(
        <Fragment>
            <Routes>
                <Route exact path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
					<Route
						exact
						path={`${process.env.PUBLIC_URL}/auth/login`}
						element={<Login />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL}/forgotpassword`}
						element={<ForgotPassword />}
					/>
                    <Route path={`/*`} element={<LayoutRoutes />} />
            </Routes>
				<ToastContainer />
        </Fragment>
    )
}

export default Routers