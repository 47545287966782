import React, { useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Table } from "reactstrap";
import { useQuery, gql } from "@apollo/client";

const GET_STORE = gql`
  query store(
    $slug: String!
  ) {
    store(
		slug: $slug
    ) {
		id
		name
		slug
		description
		pic
		email
		phone
		address
		ref_cities_list_id
		ref_cities_list_name
		ref_province_list_id
		ref_province_list_name
		ref_district_id
		ref_district_name
		ref_postcode_id
		ref_postcode_name
		image
		created_date
		total_product
		total_transaction_paid
		total_transaction_otw
		total_transaction_finish
    }
  }
`;

const TabsetProfile = ({ data, slug }) => {
	var { refetch } = useQuery(GET_STORE,{
		variables:{
			slug:''
		}
	});

	useEffect( () => {
		async function fetchDataStore() {
			const result = await refetch({
				slug: slug
			})
			if(result){
				data.store = result.data.store;
			}
		}

		if (data) {
			fetchDataStore();
		}
	}, [data])

	const clickActive = (event) => {
		document.querySelector(".nav-link").classList.remove("show");
		event.target.classList.add("show");
	};

	return (
		<>
			<Col xl="12">
				{data && data.store ?
					<Card className="profile-card">
						<CardBody>
							<div>
								<Tabs>
									<TabList className="nav nav-tabs tab-coupon">
										<Tab className="nav-link"  onClick={(e) => clickActive(e)}>
											<User className="me-2" />
											Profil User
										</Tab>
										<Tab className="nav-link"  onClick={(e) => clickActive(e)}>
											<User className="me-2" />
											Profil Toko
										</Tab>
									</TabList>

									<TabPanel>
										<div className="tab-pane fade show active">
											<div className="table-responsive profile-table">
												<Table className="table-responsive">
													<tbody>
														<tr>
															<td>Nama:</td>
															<td>{data.name}</td>
														</tr>
														<tr>
															<td>Email:</td>
															<td>{data.email}</td>
														</tr>
														<tr>
															<td>Telp:</td>
															<td>{data.phone}</td>
														</tr>
														<tr>
															<td>Tanggal Pembuatan:</td>
															<td>{data.created_date}</td>
														</tr>
														<tr>
															<td>
																<Link to={`/profile/user/edit`} className="btn btn-primary">
																		Edit
																</Link>
																</td>
														</tr>
													</tbody>
												</Table>
											</div>
										</div>
									</TabPanel>
									<TabPanel>
										<div className="tab-pane fade show active">
											<div className="table-responsive profile-table">
												<Table className="table-responsive">
													<tbody>
														<tr>
															<td>Image:</td>
															<td><img src={process.env.REACT_APP_IMAGE_URL+data.store.image} width="100px"/></td>
														</tr>
														<tr>
															<td>Nama:</td>
															<td>{data.store.name}</td>
														</tr>
														<tr>
															<td>Email:</td>
															<td>{data.store.email}</td>
														</tr>
														<tr>
															<td>Telp:</td>
															<td>{data.store.phone}</td>
														</tr>
														<tr>
															<td>Alamat:</td>
															<td>{data.store.address}<br/>{data.store.ref_district_name}, {data.store.ref_postcode_name}</td>
														</tr>
														<tr>
															<td>Deskripsi:</td>
															<td>{data.store.description}</td>
														</tr>
														<tr>
															<td>Tanggal Pembuatan:</td>
															<td>{data.store.created_date}</td>
														</tr>
														<tr>
															<td>
																<Link to={`/profile/store/edit`} className="btn btn-primary">
																		Edit
																</Link>
																</td>
														</tr>
													</tbody>
												</Table>
											</div>
										</div>
									</TabPanel>
								</Tabs>
							</div>

						</CardBody>
					</Card>
					: ''}
			</Col>
		</>
	);
};

export default TabsetProfile;
