import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { numberFormat } from "../../../../helpers/custom";
import moment from 'moment';
import Filter from "../../../common/filter_components/filter";

const GET_SUMMARY_TRANSACTION = gql`
  query (
    $stores_id: Int
    $indexFrom: Int
    $limit: Int
    $start_date: String
    $end_date: String
  ) {
    getSummaryTransactionPerMonth(
		stores_id: $stores_id
		indexFrom: $indexFrom
		limit: $limit
		start_date: $start_date
		end_date: $end_date
    ) {
		total
  		hasMore
  		items{
			periode
			total_transaksi
			subtotal
			shipping_cost
			service_fee
			shipping_fee
			midtrans_fee
		}
    }
  }
`;


const defaultSorted = [{
	dataField: 'name',
	order: 'desc'
}];

const TabsetProfile = ({ stores_id }) => {
	const sizePerPage = 10;
	const [page, setPage] = useState(1)
	let navigate = useNavigate();
	const [sortBy, setSortBy] = useState("periodedesc");
	const user = JSON.parse(localStorage.getItem('user'));
	const [filter, setFilter] = useState({
		date: { start: '', end: '' },
	})

	var { data, refetch } = useQuery(GET_SUMMARY_TRANSACTION, {
		variables: {
			stores_id: 0,
			limit: sizePerPage,
			indexFrom: (page * sizePerPage) - sizePerPage,
		},
	});

	const columns = [
		{
			dataField: 'periode',
			text: 'Periode',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				moment(row.periode,'MM/YYYY').format("MMM YY")
			),
		},
		{
			dataField: 'total_transaksi',
			text: 'Orders',
			sort: true,
		},
		{
			dataField: 'service_fee',
			text: 'Seller Payment',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat(parseInt(row.subtotal) - parseInt(row.service_fee))
			),
		},
		{
			dataField: 'shipping_cost',
			text: 'Shipping Fee',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat(parseInt(row.shipping_cost) - parseInt(row.shipping_fee))
			),
		},
		{
			dataField: 'midtrans_fee',
			text: 'Midtrans Fee',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat(parseInt(row.midtrans_fee))
			),
		},
		{
			dataField: 'total',
			text: 'Total',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat((parseInt(row.subtotal) - parseInt(row.service_fee))+(parseInt(row.shipping_cost) - parseInt(row.shipping_fee))+parseInt(row.midtrans_fee))
			),
		},
		{
			dataField: '',
			text: '',
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center">
				<Link to={`/reports/expense/${row.periode}`} >
						<i className="fa fa-eye" />
					</Link>
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: '13%' };
			}
		}
	];
	
	const handleTableChange = (e, { filters, page:pages, sizePerPage, sortField, sortOrder, data }) => {
		if (pages) {
			setPage(pages)
		}
	}
	
	const handleFilterChange = (e) => {
		refetch({
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			start_date: filter && filter.date ? filter.date.start : '',
			end_date: filter && filter.date ? filter.date.end : '',
		})
		setFilter(e);
	}
	const filterOptions = [
		{
			id: 'date',
			label: 'Date',
			value: filter.date,
			type: 'dateMonthYear',
			className: 'first-column',
		},
	]
	return (
		<>
			<h5 className="f-w-600 f-16 pull-left">
				Summary Expense by month
			</h5>
			<div className="clearfix"></div>
			<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} />
			<div
				id="batchDelete"
				className="category-table store-list order-table coupon-list-delete"
			>
				{data ?
					<>
					<BootstrapTable
						keyField='id'
						hover
						condensed
						remote={{ pagination: true }}
						data={data && data.getSummaryTransactionPerMonth ? data.getSummaryTransactionPerMonth.items : []}
						columns={columns}
						bordered={false}
						noDataIndication="Table is Empty"
						pagination={paginationFactory(
							{
								sizePerPage: sizePerPage,
								page:page,
								hideSizePerPage: true,
								hidePageListOnlyOnePage: true,
								totalSize: data && data.getSummaryTransactionPerMonth ? data.getSummaryTransactionPerMonth.total : 0
							})}
						onTableChange={handleTableChange}
						wrapperClasses="table-responsive"
					/>
					</>
					: ''}
			</div>
		</>
	);
};

export default TabsetProfile;
