import React, { Fragment, useState, useEffect, useRef } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link } from "react-router-dom";
import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	FormText
} from "reactstrap";
import { useQuery, gql } from '@apollo/client';
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
import "./product-detail.css";
import { numberFormat } from "../../helpers/custom";

const GET_SINGLE_PRODUCTS = gql`
  query product($slug: String!) {
    product(slug: $slug) {
		id
		slug
		title
		description
		brand
		category
		price
		new
		sale
		discount
		weight
		width
		height
		length
		stock
		variants {
			id
			title
			description
			stock
			price
			image
			weight
			width
			height
			length
			status
		}
		images {
			id
			alt
			src
		}
		store {
			id
			name
			slug
			ref_cities_list_name
			image
      	}
    }
  }
`;
const Add_product = () => {
	const is_mobile = window.innerWidth < 750 ? true : false;
	const { slug } = useParams();
	const [state, setState] = useState({ nav1: null, nav2: null });
	const [haveVariant, setHaveVariant] = useState(false);
	const [priceRange, setPriceRange] = useState(0);
	const [totalStock, setTotalStock] = useState(0);
	const user = JSON.parse(localStorage.getItem('user'));
	const slider1 = useRef();
	const slider2 = useRef();

	var { loading, data } = useQuery(GET_SINGLE_PRODUCTS, {
		variables: {
			slug: slug,
		},
	});
	useEffect(e => {
		if (data) {
			if (data.product.variants.length > 0) {
				let lowest_price = 0;
				let highest_price = 0;
				let stock = 0;
				data.product.variants.forEach(e => {
					if (lowest_price === 0) {
						lowest_price = e.price;
					}
					if (lowest_price >= e.price) {
						lowest_price = e.price;
					}
					if (highest_price <= e.price) {
						highest_price = e.price;
					}
					stock += parseInt(e.stock)
				});
				let price_range = '';
				if (lowest_price === highest_price) {
					price_range = numberFormat(parseInt(highest_price));
				} else {
					price_range = numberFormat(parseInt(lowest_price)) + ' - ' + numberFormat(parseInt(highest_price));
				}
				setHaveVariant(true)
				setPriceRange(price_range)
				setTotalStock(stock)
			}
		}
	}, [data])

	var products = {
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		arrows: true,
		fade: true,
	};
	var productsnav = {
		slidesToShow: 5,
		swipeToSlide: true,
		arrows: false,
		dots: false,
		focusOnSelect: true,
		infinite: false,
		vertical: true,
		verticalSwiping: true,
		variableWidth: true,
		adaptiveHeight: true
	};

	useEffect(() => {
		setState({
			nav1: slider1.current,
			nav2: slider2.current,
		});
	}, [data]);

	const { nav1, nav2 } = state;

	return (
		<Fragment>
			<Breadcrumb title="Product Detail" parent="Product" />
			{data ?
				<Container fluid={true}>
					<Row>
						<Col sm="12">
							<Card>
								<CardHeader>
									<h5>Product Info</h5>
								</CardHeader>
								<CardBody>
									<Row className="product-adding">
										<Col xl="5">
											<div className="add-product">
												<Row>
													<Col xl="9 xl-50" sm="12 col-12">
														<Slider
															{...products}
															asNavFor={nav2}
															ref={(slider) => (slider1.current = slider)}
															className="product-slider"
														>
															{data.product.images.map((res, i) => {
																if (res.id > 0) {
																	return (

																		<div className="item" key={i}>
																			<img className="img-fluid" src={`${process.env.REACT_APP_IMAGE_URL}${res.src}`} alt="" />
																		</div>

																	);
																} else {
																	return '';
																}
															})}
														</Slider>

													</Col>
													<Col xl="3 xl-50" sm="12 col-12">
														<Slider
															{...productsnav}
															asNavFor={nav1}
															ref={(slider) => (slider2.current = slider)}
															style={{ maxHeight: "400px" }}
														>
															{data.product.images.map((res, i) => {
																if (res.id > 0) {
																	return (
																		<div className="item" key={i}>
																			<img className="img-fluid" src={`${process.env.REACT_APP_IMAGE_URL}${res.src}`} alt="" style={{ height: "70px", width: "auto" }} />
																		</div>
																	);
																} else { return '' }
															})}
														</Slider>
													</Col>
												</Row>
											</div>
										</Col>
										<Col xl="7">
											<Form
												className="add-product-form"
											>
												<div className="form form-label-center">
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-4 mb-0">
															Nama Produk :
														</Label>
														<div className="col-xl-8 col-sm-7">
															<Input
																className="form-control"
																name="product_name"
																id="validationCustom01"
																type="text"
																required
																value={data.product.title}
															/>
														</div>
														<div className="valid-feedback">Looks good!</div>
													</FormGroup>
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-4 mb-0">
															Kategori :
														</Label>
														<div className="col-xl-8 col-sm-7">
															<Input
																className="form-control"
																name="product_name"
																id="validationCustom01"
																type="text"
																required
																value={data.product.category}
															/>
														</div>
														<div className="valid-feedback">Looks good!</div>
													</FormGroup>
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-4 mb-0">
															Harga :
														</Label>
														<div className="col-xl-8 col-sm-7">
															{haveVariant ?
																priceRange
																:
																<Input
																	className="form-control mb-0"
																	name="price"
																	id="validationCustom02"
																	type="text"
																	required
																	value={numberFormat(parseInt(data.product.price))}
																/>
															}
														</div>
														<div className="valid-feedback">Looks good!</div>
													</FormGroup>

													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-4 mb-0">
															Stok :
														</Label>
														<div className="col-xl-8 col-sm-7">
															{haveVariant ?
																totalStock
																:
																<Input
																	className="form-control mb-0"
																	name="stock"
																	id="stock"
																	type="number"
																	value={data.product.stock}
																/>
															}
														</div>
													</FormGroup>
													{haveVariant ? '' :
														<FormGroup className="form-group mb-3 row">
															<Label className="col-xl-3 col-sm-4 mb-0">
																Berat :
															</Label>
															<div className="col-xl-8 col-sm-7">

																<Input
																	className="form-control mb-0"
																	name="weight"
																	id="validationCustom02"
																	type="text"
																	required
																	value={(parseInt(data.product.weight))}
																/>
																<FormText>
																	Berat dalam Gram
																</FormText>
															</div>
														</FormGroup>
													}
													{haveVariant ?
														''
														:
														<FormGroup className="form-group mb-3 row">
															<Label className="col-xl-3 col-sm-4 mb-0">
																Volume :
															</Label>
															<div className="col-xl-8 col-sm-7">
																<>
																	<div className="d-flex align-items-center">
																		P&nbsp;<Input
																			className="form-control w-25 mb-0"
																			name="length"
																			id="length"
																			type="number"
																			value={parseInt(data.product.length)}
																		/>
																		&nbsp;&nbsp;x &nbsp;&nbsp;L&nbsp;<Input
																			className="form-control w-25 mb-0"
																			name="width"
																			id="width"
																			type="number"
																			value={parseInt(data.product.width)}
																		/>
																		&nbsp;&nbsp;x &nbsp;&nbsp;T&nbsp;<Input
																			className="form-control w-25 mb-0"
																			name="height"
																			id="height"
																			type="number"
																			value={parseInt(data.product.height)}
																		/>
																	</div>
																	<FormText>
																		Volume dalam Cm
																	</FormText>
																</>
															</div>
															<div className="valid-feedback">Looks good!</div>
														</FormGroup>
													}
													<FormGroup className="form-group mb-3 row">
														<Label className="col-xl-3 col-sm-4">
															Deskripsi :
														</Label>
														<div className="col-xl-8 col-sm-7 description-sm">
															<Input
																type="textarea"
																value={data.product.description.replace(/<br \/>/g, '\n')}
																rows="5"
															/>
														</div>
													</FormGroup>
												</div>
												<div className="offset-xl-3 offset-sm-4">
													<Link to={`/produk/form/${data.product.slug}`} className="btn btn-primary">
														Ubah
													</Link>
												</div>
											</Form>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
						{data.product.variants.length > 0 ?
							<Col sm="12">
								<Card>
									<CardHeader className="variant-header">
										<h5>Produk Variant</h5>
										{/*<button className="btn btn-secondary btn-add-variant"><i className="fa fa-plus f-12" /></button>*/}
									</CardHeader>
									<CardBody>
										<Row className="product-adding">
											<Col xl="12">
												<div className="table-responsive ">
													<table className="table table-bordernone mb-0">
														<thead>
															{is_mobile ?
																<tr>
																	<th scope="col" className="col-1"></th>
																	<th scope="col"></th>
																	<th scope="col" className="col-1">Status</th>
																</tr>
																:
																<tr>
																	<th scope="col" className="col-1"></th>
																	<th scope="col">Nama</th>
																	<th scope="col" className="col-1">Stock</th>
																	<th scope="col" className="col-3">Harga</th>
																	<th scope="col" className="col-1">Berat</th>
																	<th scope="col" className="col-1">Status</th>
																</tr>
															}
														</thead>
														<tbody>
															{data.product.variants.map(e => {
																return (
																	is_mobile ?
																		<tr>
																			<th scope="col"><img src={`${process.env.REACT_APP_IMAGE_URL}${e.image}`} style={{ height: "100px" }} alt="" /></th>
																			<td >
																				Nama : {e.title}<br />
																				Stok : {e.stock}<br />
																				Harga : {numberFormat(parseInt(e.price))}<br />
																				Berat : {e.weight}
																			</td>
																			<td >{e.status}</td>
																		</tr>
																		:
																		<tr>
																			<th scope="col"><img src={`${process.env.REACT_APP_IMAGE_URL}${e.image}`} style={{ height: "100px" }} alt="" /></th>
																			<td >{e.title}</td>
																			<td >{e.stock}</td>
																			<td >{numberFormat(parseInt(e.price))}</td>
																			<td >{e.weight}</td>
																			<td >{e.status}</td>
																		</tr>
																)
															})}
														</tbody>
													</table>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							: ''}
					</Row>
				</Container>
				: loading}
		</Fragment>
	);
};

export default Add_product;
