import React, { useState, useRef } from "react";
import {
    Card, CardBody, Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { Line } from "react-chartjs-2";
import { useQuery, gql } from "@apollo/client";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

const GET_SUMMARY_TRANSACTION = gql`
  query (
    $stores_id: Int
    $start_date: String
    $end_date: String
    $type: String
  ) {
    getChartOrders(
		stores_id: $stores_id
		start_date: $start_date
		end_date: $end_date
		type: $type
    ) {
		label
        value{
            new
            cancel
            success
            sent
            complaint
        }
    }
  }
`;

export const buyOption = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        }
    },
    scales: {
        xAxes: {
            gridLines: {
                color: '#f8f8f8'
            },
        },
        yAxes: {
            gridLines: {
                color: '#f8f8f8'
            },

        }
    },
}

const OrderSuccessCanceled = ({user}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownValue, setDropdownValue] = useState('qty');
    const myRef = useRef();
    const [startDate, setStartDate] = useState(moment().subtract(6, 'months').format('MM/DD/YYYY'));
    const [endDate, setEndDate] = useState(moment().format('MM/DD/YYYY'));
	const [value, setValue] = useState(startDate + ' - ' + endDate);
    var { data, refetch } = useQuery(GET_SUMMARY_TRANSACTION, {
        variables: {
            stores_id: user.stores_id,
            start_date: moment(startDate).format('DD/MM/YYYY'),
            end_date: moment(endDate).format('DD/MM/YYYY'),
            type: dropdownValue,
        },
    });
    const buyData = {
        labels: data?.getChartOrders?.label,
        datasets: [
            {
                label: 'New',
                backgroundColor: "#13c9ca",
                borderColor: "#13c9ca",
                data: data?.getChartOrders?.value?.new,
                lineTension: 0.4,
            },
            {
                label: 'Sent',
                backgroundColor: "#00a8ff",
                borderColor: "#00a8ff",
                data: data?.getChartOrders?.value?.sent,
                lineTension: 0.4,
            },
            {
                label: 'Sucess',
                backgroundColor: "#13ca34",
                borderColor: "#13ca34",
                data: data?.getChartOrders?.value?.success,
                lineTension: 0.4,
            },
            {
                label: 'Complaint',
                backgroundColor: "#ffbc58",
                borderColor: "#ffbc58",
                data: data?.getChartOrders?.value?.complaint,
                lineTension: 0.4,
            },
            {
                label: 'Canceled',
                backgroundColor: "#a5a5a5",
                borderColor: "#a5a5a5",
                data: data?.getChartOrders?.value?.cancel,
                lineTension: 0.4,
            },
        ],
    };


    function rearrangeDatePickerPosition() {
        const dateRangePicker = document.getElementsByClassName('daterangepicker');
        for (let i = 0; i < dateRangePicker.length; i++) {
            const top = dateRangePicker[i].style['top'];
            if (top) {
                dateRangePicker[i].style.top = (parseInt(top.replace('px', '')) - 76) + 'px';
            }
        };
    }

    const handleShowDatePicker = (e) => {
        rearrangeDatePickerPosition();
    }
    const handleShowCalendarDatePicker = (e) => {
        rearrangeDatePickerPosition();
    }

    const handleApply = (event, picker) => {
        const start_date = moment(picker.startDate).format('MM/DD/YYYY');
        const end_date = moment(picker.endDate).format('MM/DD/YYYY');
        setStartDate(start_date)
        setEndDate(end_date)
        setValue(start_date + ' - ' + end_date);
        refetch()

    }


    const toggle = (e) => {
        if(e.target.value){
            setDropdownValue(e.target.value)
            refetch()
        }
        setDropdownOpen((prevState) => !prevState);
    }

    return (
        <Card className=" o-hidden widget-cards">
            <CardBody className="">
                <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "space-between" }}>
                    <h4 className="fw-bold font-black">Order</h4>
                    <Col xl="8 xl-50" style={{ display: "flex", justifyContent: "space-between" }}>
                        <Col xl="8 xl-50" md="12">
                            <DateRangePicker
                                ref={myRef}
                                initialSettings={{
                                    local: 'id',
                                    autoUpdateInput: false,
                                    alwaysShowCalendars: true,
                                    opens: "center",
                                    startDate: startDate,
                                    endDate: endDate,
                                    ranges: {
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    }
                                }}
                                onShow={handleShowDatePicker}
                                onShowCalendar={handleShowCalendarDatePicker}
                                onApply={handleApply}
                            >
                                <input type="text" className="form-control" placeholder="dd/mm/yyyy - dd/mm/yyyy" value={value} />
                            </DateRangePicker>
                        </Col>
                        <Col >
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                                <DropdownToggle caret>{dropdownValue}</DropdownToggle>
                                <DropdownMenu >
                                    <DropdownItem value="qty">Qty</DropdownItem>
                                    <DropdownItem value="value">Value</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Col>
                </div>
                <div >
                    <Line
                        data={buyData}
                        options={buyOption}
                        width={700}
                        height={350}
                    />
                </div>
            </CardBody>
        </Card>
    );
};

export default OrderSuccessCanceled;
