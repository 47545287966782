import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TabsetProfile from "./common/detail-user-info";
import TabsetStoreProfile from "./common/detail-user-toko-info";
import Breadcrumb from "../common/breadcrumb";
import { Container, Row } from "reactstrap";
import { useQuery, gql } from "@apollo/client";

const GET_USER = gql`
  query ($id:Int){
    getUser(id:$id){
    		id
    		email
    		phone
    		name
			privilege
			stores_id
			stores_name
			stores_slug
			status
			created_date
  	}
  }
`;

const TokoDetail = () => {
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	var { data } = useQuery(GET_USER, {
		variables: {
			id: parseInt(user.id)
		}
	});
	useEffect(() => {
		if (!user) {
			return navigate("/", { replace: true });
		}
	}, [])
	return (
		<Fragment>
			<Breadcrumb title="Profile" />
			<Container fluid={true}>
				<Row>
					{data && data.getUser ?
						(data.getUser.stores_id ?
							<TabsetStoreProfile data={data.getUser} slug={data.getUser.stores_slug} />
							:
							<TabsetProfile data={data.getUser} />
						) : ''
					}
				</Row>
			</Container>
		</Fragment>
	);
};

export default TokoDetail;
