import React, { useEffect, useState, useRef } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User } from "react-feather";
import { Link } from "react-router-dom";
import { Row, Card, CardBody, Col, Media, Table } from "reactstrap";
import designer from "../../../assets/images/dashboard/designer.jpg";
import { useQuery, gql, useMutation } from "@apollo/client";
import moment from 'moment';

const GET_WHATSAPP_CHAT_CONTENT = gql`
  query getWhatsappContactChat($wa_id: String!) {
    getWhatsappContactChat(wa_id: $wa_id) {
		name
		wa_id
		type
		messages
		timestamp
    }
  }
`;

const CREATE_WHATSAPP_CHAT_CONTENT = gql`
  mutation replyWhatsapp($input: ReplyWhatsappInput!) {
    replyWhatsapp(input: $input) {
		success
		message
    }
  }
`;

const TabsetProfile = ({ wa_id, setWaId, newChat, setNewChat, isMobile, setIsMobileShowChat }) => {
	const [data, setData] = useState();
	const textFocus = useRef(null);
	const el = useRef(null);
	const [addTodo] = useMutation(CREATE_WHATSAPP_CHAT_CONTENT);

	var { refetch } = useQuery(GET_WHATSAPP_CHAT_CONTENT, {
		variables: {
			wa_id: wa_id,
		},
	});

	useEffect(() => {
		if (wa_id) {
			const fetchData = async () => {
				let datas = await refetch({

					wa_id: wa_id,
				});
				setData(datas.data)
			}

			// call the function
			fetchData()
		}
	}, [wa_id])

	useEffect(() => {
		if (wa_id) {
			const fetchData = async () => {
				let datas = await refetch({

					wa_id: wa_id,
				});
				setData(datas.data)
				setNewChat(false);
			}
			fetchData()
		} else {
			setNewChat(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newChat])

	useEffect(() => {
		if (data) {
			el.current.scrollIntoView();
			textFocus.current.focus();
		}
	}, [data])
	
	const handleBackContact = async (e) => {
		setWaId('');
		setIsMobileShowChat(false);
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (e.target.messages.value) {
			const inputData = {
				messages: e.target.messages.value,
				wa_id: wa_id,
				name: data.getWhatsappContactChat[0].name,
			};
			const result = await addTodo({ variables: { input: inputData } });
			if (result.data.replyWhatsapp.success) {
				let datas = await refetch({
					wa_id: wa_id,
				});
				setData(datas.data)
				document.getElementById("messageForm").reset();
			}
		}
	}

	let current_date = '';
	return (
		<>
			{
				data ?
					< div className=" whatsapp-chat_content_header" ><span className={`p-2 ${isMobile?'':'hide'}`} onClick={handleBackContact}><i className="fa fa-chevron-left"/></span> test</div >
					: ''}
			<div class="whatsapp-chat_content">
				{data ?
					<>
						{data.getWhatsappContactChat.map(e => {
							const date = moment.unix(e.timestamp).format('DMMY');
							let create_date_label = current_date === date ? false : true;
							current_date = current_date !== date ? date : current_date;
							return (
								<>
									{create_date_label ?
										<div class={`whatsapp-chat_content_message date-label`}>
											{moment.unix(e.timestamp).format('D-MM-Y')}
										</div>
										: ''}
									<div class={`whatsapp-chat_content_message ${e.type === "send" ? 'right' : ''}`}>
										{e.messages}
										<span title={moment.unix(e.timestamp).format('D-MM-Y HH:mm')}>{moment.unix(e.timestamp).format('HH:mm')}</span>
									</div>
								</>
							)
						})}
						<div ref={el} />
					</>
					: ''}
			</div >
			{data ?
				<form id="messageForm" onSubmit={handleSubmit}>
					< div className=" whatsapp-chat_content_field" ><input ref={textFocus} autoFocus type="text" id="messages" name="messages" className="col-11 form-control" placeholder="Type a message" /><button type="submit"><i className="fa fa-paper-plane" /></button></div >
				</form >
				: ''}
		</>
	);
};

export default TabsetProfile;
