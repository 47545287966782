import React from "react";
import { Send } from "react-feather";
import CountUp from "react-countup";
import moment from 'moment';
import { useQuery, gql } from '@apollo/client';
import { Card, CardBody, Media } from "reactstrap";

const GET_ORDERS = gql`
  query getOrders(
	$indexFrom:Int 
	$limit:Int 
	$status:String
	$start_date:String,
	$end_date:String
	$store:String
	$group_status:String
  ) {
    getOrders(
		indexFrom: $indexFrom
		limit: $limit
		status: $status
		start_date: $start_date
		end_date: $end_date
		store: $store
		group_status: $group_status
    ) {
      total
    }
  }
`;
const Income = ({user, startDate, endDate}) => {
	var { data } = useQuery(GET_ORDERS, {
		variables: {
			indexFrom: 0,
			limit: 1,
			group_status: 'sent',
      start_date: moment(startDate).format('MM/DD/YYYY'),
      end_date: moment(endDate).format('MM/DD/YYYY'),
      store: user.stores_slug
		},
	});
    return (
        <Card className=" o-hidden widget-cards">
            <CardBody className="bg-info">
                <Media className="static-top-widget row">
                    <div className="icons-widgets col-2">
                        <div className="align-self-center text-center">
                            <Send className="font-info" />
                        </div>
                    </div>
                    <Media body className="col-10">
                        <span className="m-0">Order Sent</span>
                        <h3 className="mb-0">
                            &nbsp;&nbsp;<CountUp className="counter" end={data&&data.getOrders.total} />
                            <small> This Month</small>
                        </h3>
                    </Media>
                </Media>
            </CardBody>
        </Card>
    );
};

export default Income;
