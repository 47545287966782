import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import Filter from "../common/filter_components/filter";
import { Link } from "react-router-dom";
import { Container, Row, Card, CardBody } from "reactstrap";
import { useQuery, gql } from '@apollo/client';
import { numberFormat } from "../../helpers/custom";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const GET_PRODUCTS = gql`
  query products(
    $category: String!
    $indexFrom: Int!
    $limit: Int!
    $sortBy: String
    $store: String
	$store_name: String
	$title: String
	$status: String
  ) {
    products(
      category: $category
      indexFrom: $indexFrom
      limit: $limit
      sortBy: $sortBy
      store: $store
      store_name: $store_name
      title: $title
      status: $status
    ) {
      total
      hasMore
      items {
        id
        title
        description
        brand
        category
        price
        new
		tipe
        sale
        stock
        discount
        slug
		sold
		sisa
		status
        variants {
          id
          title
          description
          stock
          price
          image
          weight
        }
        images {
          image_id
          id
          alt
          src
        }
        store {
          name
          slug
          ref_cities_list_name
        }
      }
    }
  }
`;

const Product_list = () => {
	const is_mobile = window.innerWidth < 750 ? true : false;
	const sizePerPage = 10;
	const user = JSON.parse(localStorage.getItem('user'));
	const hideStore = !user.stores_id ? false : true
	const [sortBy, setSortBy] = useState("");
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState({
		store_name: '',
		category: '',
		title: '',
		status: '',
	})
	const [defaultSorted, setDefaultSorted] = useState([{
		dataField: 'id',
		order: 'desc'
	}]);
	const columns = [
		{
			dataField: 'id',
			text: '#',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '5%' };
			}
		},
		{
			dataField: 'stores_name',
			text: 'Toko',
			sort: true,
			hidden: hideStore,
			formatter: (cell, row, rowIndex, extraData) => (
				row.store[0].name
			),
		},
		{
			dataField: 'category',
			text: 'Kategori',
			sort: true,
		},
		{
			dataField: 'tipe',
			text: 'Tipe',
			sort: true,
		},
		{
			dataField: 'title',
			text: 'Nama',
			sort: true,

		},
		{
			dataField: 'stock',
			text: 'Stok',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '7%' };
			}
		},
		{
			dataField: 'sold',
			text: 'Terjual',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '8%' };
			}
		},
		{
			dataField: 'sisa',
			text: 'Sisa',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '7%' };
			}
		},
		{
			dataField: 'price',
			text: 'Harga',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat(parseInt(row.price))
			),
			headerStyle: (colum, colIndex) => {
				return { width: '12%' };
			}
		},
		{
			dataField: 'status',
			text: 'Status',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '12%' };
			}
		},
		{
			dataField: '',
			text: '',
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center">
					<Link to={`/produk/${row.slug}`} >
						<i className="fa fa-eye" title="Lihat" />
					</Link>
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: '5%' };
			}
		}
	];

	const columns_mobile = [
		{
			dataField: 'id',
			text: '#',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '15%' };
			}
		},
		{
			dataField: 'category',
			text: 'Kategori',
			sort: true,
		},
		{
			dataField: 'title',
			text: 'Nama',
			sort: true,

		},
		{
			dataField: '',
			text: '',
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center">
					<Link to={`/produk/${row.slug}`} >
						<i className="fa fa-eye" title="Lihat" />
					</Link>
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: '5%' };
			}
		}
	];
	const expandRow = {
		renderer: row => (
			<>
				<div>{!user.stores_id ? 'Toko: ' + row.store[0].name : ''}</div>
				<div>Stock: {row.stock}</div>
				<div>Laku: {row.sold}</div>
				<div>Sisa: {row.sisa}</div>
				<div>Harga: {numberFormat(parseInt(row.price))}</div>
			</>)

	};


	var { data, refetch } = useQuery(GET_PRODUCTS, {
		variables: {
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			category: filter && filter.category ? filter.category : '',
			title: filter && filter.title ? filter.title : '',
			price: filter && filter.price ? filter.price : '',
			status: filter && filter.status ? filter.status.value : '',
			store_name: filter && filter.store_name ? filter.store_name : '',
			store: user.stores_slug ? user.stores_slug : '',
			sortBy: sortBy,
		},
	});

	const filterOptions = [
		{
			id: 'store_name',
			label: 'Store',
			value: filter.store_name,
			type: 'text',
		},
		{
			id: 'category',
			label: 'Category',
			value: filter.category,
			type: 'text',
		},
		{
			id: 'title',
			label: 'Product',
			value: filter.title,
			type: 'text',
		},
		{
			id: 'status',
			label: 'Status',
			value: filter.status,
			type: 'dropdown',
			options: [
				{
					label: 'Active',
					value: 'active',
				},
				{
					label: 'Inactive',
					value: 'inactive',
				}
			]
		},
	]

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		if (page) {
			setPage(page)
		}
		if (sortField && sortOrder) {
			setSortBy(sortField + '-' + sortOrder)
			setDefaultSorted([{
				dataField: sortField,
				order: sortOrder
			}])
		}
	}
	const handleFilterChange = (e) => {
		refetch({
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			category: filter && filter.category ? filter.category : '',
			title: filter && filter.title ? filter.title : '',
			price: filter && filter.price ? filter.price : '',
			status: filter && filter.status ? filter.status.value : '',
			store_name: filter && filter.store_name ? filter.store_name : '',
			store: user.stores_slug ? user.stores_slug : '',
			sortBy: sortBy,
		})
		setFilter(e);
	}
	return (
		<Fragment>
			<Breadcrumb title="Produk" />
			<Container fluid={true}>

				<Card>
					<CardBody>
						<><div className="btn-popup pull-right">
							<Link to="/produk/form/0" className="btn btn-primary">
								Tambah Produk
							</Link>
						</div>
							<div className="clearfix"></div></>
						<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} />
						<Row className="products-admin ratio_asos">
							{data && data.products ?
								<BootstrapTable
									keyField='id'
									hover
									condensed
									remote={{ sort: true, pagination: true }}
									data={data && data.products ? data.products.items : []}
									columns={is_mobile ? columns_mobile : columns}
									bordered={false}
									noDataIndication="Table is Empty"
									pagination={paginationFactory(
										{
											sizePerPage: sizePerPage,
											page: page,
											hideSizePerPage: true,
											hidePageListOnlyOnePage: true,
											totalSize: data && data.products ? data.products.total : 0
										})}
									defaultSorted={defaultSorted}
									onTableChange={handleTableChange}
									wrapperClasses="table-responsive"
									expandRow={is_mobile ? expandRow : ''}

								/>
								: ''}
						</Row>
					</CardBody>
				</Card>
			</Container>
		</Fragment>
	);
};

export default Product_list;
