import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Table } from "reactstrap";

const TabsetProfile = ({ data }) => {
	return (
		<>
			<Col xl="12">
				{data ?
					<Card className="profile-card">
						<CardBody>
							<div>
										<div className="tab-pane fade show active">
											<div className="table-responsive profile-table">
												<Table className="table-responsive">
													<tbody>
														<tr>
															<td>Nama:</td>
															<td>{data.name}</td>
														</tr>
														<tr>
															<td>Email:</td>
															<td>{data.email}</td>
														</tr>
														<tr>
															<td>Telp:</td>
															<td>{data.phone}</td>
														</tr>
														<tr>
															<td>Tanggal Pembuatan:</td>
															<td>{data.created_date}</td>
														</tr>
														<tr>
															<td>
																<Link to={`/profile/user/edit`} className="btn btn-primary">
																		Edit
																</Link>
																</td>
														</tr>
													</tbody>
												</Table>
											</div>
										</div>
							</div>

						</CardBody>
					</Card>
					: ''}
			</Col>
		</>
	);
};

export default TabsetProfile;
