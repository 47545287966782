import React, { Fragment, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeft } from "react-feather";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
const Login = () => {

    return (
        <Fragment>
            <div className="error-template">
                <div className="error-template-body">
                    <h1>
                        Oops!</h1>
                    <h2>
                        404 Not Found</h2>
                    <div className="error-actions">
                        <a href="/dashboard" className="btn btn-primary btn-lg">
                            Dashboard 
                        </a>
                    </div>
                </div>
            </div>
        </Fragment>

    );
};

export default Login;
