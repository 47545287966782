import React,{ useEffect, useState } from "react";
import { Input } from "reactstrap";

const Breadcrumb = ({ options, filter, handleFilterChange, cssClass }) => {
    const [value,setValue] = useState();
	useEffect(()=>{
		setValue(filter[options.id])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(filter)]);

    const handleChange = (e)=>{
        let name = e.target.id;
        let values = e.target.value;
        let filters = filter;
        filter[name] = values
        setValue(values)
        handleFilterChange(filters)
    }
    return (
        <div className={cssClass}>
        <Input value={value} placeholder={options.label} id={options.id} name={options.id} onChange={handleChange}/>
        </div>
    );
};

export default Breadcrumb;
