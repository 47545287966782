import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { numberFormat } from "../../../../helpers/custom";
import moment from 'moment';
import Filter from "../../../common/filter_components/filter";

const getDetailTransactionPerMonth = gql`
  query (
    $periode: String!
    $indexFrom: Int
    $limit: Int
    $start_date: String
    $end_date: String
  ) {
    getDetailTransactionPerMonth(
		periode: $periode
		indexFrom: $indexFrom
		limit: $limit
		start_date: $start_date
		end_date: $end_date
    ) {
		total
		hasMore
		subtotal
		shipping_cost
		items{
			id
			order_id
			invoice_no
			name
			subtotal
			shipping_cost
			created_date
			service_fee
			shipping_fee
			midtrans_fee
		}
    }
  }
`;


const defaultSorted = [{
	dataField: 'name',
	order: 'desc'
}];

const TabsetProfile = ({ periode }) => {
	const sizePerPage = 10;
	const [page, setPage] = useState(1)
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	const [filter, setFilter] = useState({
		date: { start: '', end: '' },
	})
	var { data, refetch } = useQuery(getDetailTransactionPerMonth, {
		variables: {
			periode: periode,
			limit: sizePerPage,
			indexFrom: (page * sizePerPage) - sizePerPage,
		},
	});

	const columns = [
		{
			dataField: '#',
			text: '#',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '10%' };
			},
			formatter: (cell, row, rowIndex, extraData) => (
				rowIndex+1
			),
		},
		{
			dataField: 'created_date',
			text: 'Date',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				moment(row.created_date).format("D MMM YY")
			),
		},
		{
			dataField: 'order_id',
			text: 'Order ID',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '15%' };
			}
		},
		{
			dataField: 'service_fee',
			text: 'Seller Payment',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat(parseInt(row.subtotal) - parseInt(row.service_fee))
			),
		},
		{
			dataField: 'shipping_cost',
			text: 'Shipping Fee',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat(parseInt(row.shipping_cost) - parseInt(row.shipping_fee))
			),
		},
		{
			dataField: 'midtrans_fee',
			text: 'Midtrans Fee',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat(parseInt(row.midtrans_fee))
			),
		},
		{
			dataField: 'total',
			text: 'Total',
			sort: true,
			formatter: (cell, row, rowIndex, extraData) => (
				numberFormat((parseInt(row.subtotal) - parseInt(row.service_fee))+(parseInt(row.shipping_cost) - parseInt(row.shipping_fee))+parseInt(row.midtrans_fee))
			),
		},
		{
			dataField: '',
			text: '',
			formatter: (cell, row, rowIndex, extraData) => (
					<Link to={`/order/${row.id}`} >
						<i className="fa fa-eye" />
					</Link>
			),
			headerStyle: (colum, colIndex) => {
				return { width: '13%' };
			}
		}
	];

	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		refetch({
			indexFrom: (page*sizePerPage) - sizePerPage,
			limit: sizePerPage,
			name: filters && filters.name ? filters.name.filterVal : '',
			email: filters && filters.email ? filters.email.filterVal : '',
			phone: filters && filters.phone ? filters.phone.filterVal : '',
			sortBy: sortField+sortOrder,
		})
	}
	
	const handleFilterChange = (e) => {
		refetch({
			indexFrom: (page * sizePerPage) - sizePerPage,
			limit: sizePerPage,
			start_date: filter && filter.date ? filter.date.start : '',
			end_date: filter && filter.date ? filter.date.end : '',
		})
		setFilter(e);
	}
	const filterOptions = [
		{
			id: 'date',
			label: 'Date',
			value: filter.date,
			type: 'dateWithRange',
			className: 'first-column',
		},
	]
	return (
		<>
			<div
				id="batchDelete"
				className="category-table store-list order-table coupon-list-delete"
			>
			<Filter filterOptions={filterOptions} filter={filter} handleFilterParentChange={handleFilterChange} range={periode}/>

				{data ?
					<>
					<BootstrapTable
						keyField='id'
						hover
						condensed
						remote={{ pagination: true }}
						data={data && data.getDetailTransactionPerMonth ? data.getDetailTransactionPerMonth.items : []}
						columns={columns}
						bordered={false}
						noDataIndication="Table is Empty"
						pagination={paginationFactory(
							{
								sizePerPage: sizePerPage,
								page:page,
								hideSizePerPage: true,
								hidePageListOnlyOnePage: true,
								totalSize: data && data.getDetailTransactionPerMonth ? data.getDetailTransactionPerMonth.total : 0
							})}
						onTableChange={handleTableChange}
						wrapperClasses="table-responsive"
					/>
					</>
					: ''}
			</div>
		</>
	);
};

export default TabsetProfile;
