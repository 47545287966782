import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import ModalFormShippingProduct from "../common/modal-form-shipping-product";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

const GET_PRODUCTS = gql`
  query getSettingTarifShipping(
    $id: Int!
  ) {
    getSettingTarifShipping(
		id: $id
    ) {
		id
		name
		items{
			id
			name
			value
			created_date
			start_date
			end_date
			ref_shipping_id
		}
    }
  }
`;


const defaultSorted = [{
	dataField: 'id',
	order: 'desc'
}];
const List_vendors = ({ id, user }) => {
	const sizePerPage = 10;
	const [open, setOpen] = useState(null);
	var { data, refetch } = useQuery(GET_PRODUCTS, {
		variables: {
			id: id ? parseInt(id) : null,
		},
	});
	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		refetch({
			id: parseInt(id),
		})
	}

	const columns = [
		{
			dataField: 'id',
			text: '#',
			sort: true,
			headerStyle: (colum, colIndex) => {
				return { width: '5%' };
			}
		},
		{
			dataField: 'name',
			text: 'Name',

			filter: textFilter(),
			sort: true,

		},
		{
			dataField: 'value',
			text: 'Value',
			filter: textFilter(),
			sort: true, formatter: (cell, row, rowIndex, extraData) => (
				row.value 
			),
		},
		{
			dataField: 'start_date',
			text: 'Start',
			filter: textFilter(),
			sort: true,
		},
		{
			dataField: 'end_date',
			text: 'End',
			filter: textFilter(),
			sort: true,
		},
		{
			dataField: '',
			text: '',
			formatter: (cell, row, rowIndex, extraData) => (
				<div className="openbtn text-center text-danger">
					<i className="fa fa-edit" title="Edit" role="button" onClick={() => setOpen(row.id)} />
				</div>
			),
			headerStyle: (colum, colIndex) => {
				return { width: '5%' };
			}
		}
	];
	return (
		<>

			<div className="btn-popup pull-right">
				<button className="btn btn-primary" onClick={() => setOpen('new')}>
					Tambah
				</button>
			</div>
			<BootstrapTable
				keyField='id'
				hover
				condensed
				remote={{ sort: true, filter: true, pagination: true }}
				data={data && data.getSettingTarifShipping ? data.getSettingTarifShipping.items : []}
				columns={columns}
				bordered={false}
				noDataIndication="Table is Empty"
				pagination={paginationFactory(
					{
						sizePerPage: sizePerPage,
						hideSizePerPage: true,
						hidePageListOnlyOnePage: true,
						totalSize: data && data.getSettingTarifShipping ? data.getSettingTarifShipping.items.length : 0
					})}
				filter={filterFactory()}
				filterPosition="top"
				defaultSorted={defaultSorted}
				onTableChange={handleTableChange}
			/>
			<ModalFormShippingProduct open={open} setOpen={setOpen} refetch={refetch} ref_shipping_id={id} user={user} />
		</>
	);
};

export default List_vendors;
