/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Button, Form, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Googlemaps from './googlemaps';
import { getGeolocFromAddress } from "../../../helpers/gmaps";

const GET_INFO = gql`
  query (
    $slug: String!
  ) {
		store(
			slug: $slug
		) {
			id
			name
			slug
			description
			pic
			email
			phone
			address
			ref_cities_list_id
			ref_cities_list_name
			ref_province_list_id
			ref_province_list_name
			ref_district_id
			ref_district_name
			ref_postcode_id
			ref_postcode_name
			image
			created_date
			status
			total_product
			total_transaction_paid
			total_transaction_otw
			total_transaction_finish
			nama_rekening
			no_rekening
			ref_bank_id
			cabang_bank
			geoloc
	},
    getProvince{
      id
      name
      value
      label  
      created_date  
  }, 
  }
`;


const GET_CITY = gql`
  query getCity(
    $name: String
    $province_id:Int
   ){
    getCity(
      name: $name
      province_id: $province_id
    ){
      id
      name
      value
      label 
      ref_province_id
    }
  }`;

const GET_DISTRICT = gql`
  query getDistrict(
    $name: String
    $city_id: Int
   ){
    getDistrict(
      name: $name
      city_id:$city_id
    ){
      id
      name
      value
      label 
      ref_cities_id
    }
  }`;

const GET_POSTCODE = gql`
  query getPostcode(
    $district_id: Int
   ){
    getPostcode(
      district_id:$district_id
    ){
      id
      name
      value
      label 
      ref_district_id
    }
  }`;


const CREATE_STORE = gql`
  mutation createStore($input: CreateStoreInput) {
    createStore(input: $input) {
        success,
        message,
    }
  }
`;

const UPDATE_STORE = gql`
  mutation updateStore($input: UpdateStoreInput) {
    updateStore(input: $input) {
        success,
        message,
    }
  }
`;

const GET_BANK = gql`
  query getBanks(
    $status: String
   ){
    getBanks(
		status:$status
    ){
      id
      name
    }
  }`;
const TabsetUser = ({ slug, isPublic }) => {
	let navigate = useNavigate();
	const [cityList, setCityList] = useState([]);
	const [provinceList, setProvinceList] = useState([]);
	const [districtList, setDistrictList] = useState([]);
	const [postcodeList, setPostcodeList] = useState([]);
	const [marker, setMarker] = useState([]);
	const [values, setValues] = useState([{
		id: 0,
		name: '',
		description: '',
		email: '',
		phone: '',
		address: '',
		ref_cities_list_id: '',
		ref_province_list_id: '',
		image: '',
		file: '',
	}]);
	const [invalid, setInvalid] = useState([{
		name: '',
		email: '',
		phone: '',
		address: '',
		ref_cities_list_id: '',
		ref_province_list_id: '',
	}]);

	const [updateTodo] = useMutation(UPDATE_STORE);

	var { data } = useQuery(GET_INFO, {
		variables: {
			slug: slug ? slug : '',
		},
	});

	var { data: dataBank } = useQuery(GET_BANK);
	var { refetch: refetchCity } = useQuery(GET_CITY);
	var { refetch: refetchDistrict } = useQuery(GET_DISTRICT);
	var { refetch: refetchPostcode } = useQuery(GET_POSTCODE);

	useEffect(() => {
		let tmp_data = [...values];
		tmp_data[0]['geoloc'] = marker;
		setValues(tmp_data);
	}, [marker])
	
	useEffect(() => {
		if (data && data.store) {
			if (data.store.ref_province_list_id > 0) {
				async function fetchDataCity() {
					const result = await refetchCity({
						province_id: data.store.ref_province_list_id
					})
					if (result) {
						setCityList(result.data.getCity);
					}
				}
				fetchDataCity()
			}
			if (data.store.ref_cities_list_id > 0) {
				async function fetchDataDistrict() {
					const result = await refetchDistrict({
						city_id: data.store.ref_cities_list_id
					})
					if (result) {
						setDistrictList(result.data.getDistrict);
					}
				}
				fetchDataDistrict()
			}
			if (data.store.ref_district_id > 0) {
				async function fetchDataPostcode() {
					const result = await refetchPostcode({
						district_id: data.store.ref_district_id
					})
					if (result) {
						setPostcodeList(result.data.getPostcode);
					}
				}
				fetchDataPostcode()
			}
			const tmp_data = {
				id: data.store.id,
				name: data.store.name,
				description: data.store.description,
				email: data.store.email,
				phone: data.store.phone,
				address: data.store.address,
				ref_cities_list_id: data.store.ref_cities_list_id,
				ref_province_list_id: data.store.ref_province_list_id,
				ref_district_id: data.store.ref_district_id,
				ref_postcode_id: data.store.ref_postcode_id,
				no_rekening: data.store.no_rekening,
				nama_rekening: data.store.nama_rekening,
				ref_bank_id: data.store.ref_bank_id,
				cabang_bank: data.store.cabang_bank,
				image: '',
				file: '',
				geoloc: (JSON.parse(data.store.geoloc))
			}
			setValues([tmp_data])
		}
		if (data && data.getProvince) {
			setProvinceList(data.getProvince);
		}
	}, [data])

	const handleChangeSelect = async (event) => {
		let tmp_data = [...values];
		const { target } = event;
		const value = parseInt(target.value);
		const { name } = target;
		tmp_data[0][name] = value;

		if (name === 'ref_province_list_id') {
			const result = await refetchCity({
				province_id: value
			})
			if (result) {
				setCityList(result.data.getCity);
			}
			tmp_data[0]['ref_cities_list_id'] = '';
			tmp_data[0]['ref_district_id'] = '';
			tmp_data[0]['ref_postcode_id'] = '';

		}
		if (name === 'ref_cities_list_id') {
			const result = await refetchDistrict({
				city_id: value
			})
			if (result) {
				setDistrictList(result.data.getDistrict);
			}
			tmp_data[0]['ref_district_id'] = '';
			tmp_data[0]['ref_postcode_id'] = '';
		}
		if (name === 'ref_district_id') {
			const result = await refetchPostcode({
				district_id: value
			})
			if (result) {
				setPostcodeList(result.data.getPostcode);
			}
			tmp_data[0]['ref_postcode_id'] = '';

		}
		if (name === 'ref_postcode_id') {
			const city_name = cityList.filter(e => {
				return e.id === tmp_data[0]['ref_cities_list_id'];
			})
			const district_name = districtList.filter(e => {
				return e.id === tmp_data[0]['ref_district_id'];
			})
			const province_name = provinceList.filter(e => {
				return e.id === tmp_data[0]['ref_province_list_id'];
			})
			const postcode_name = postcodeList.filter(e => {
				return e.id === value;
			})
			if (!values[0].geoloc) {
				const geolog = await getGeolocFromAddress(tmp_data[0]['address'], district_name[0].name, city_name[0].name, province_name[0].name, postcode_name[0].name)
				setMarker(geolog);
			}
		}
		setValues(tmp_data);

	};

	const handleChange = (event) => {
		let tmp_data = [...values];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_data[0][name] = value;
		tmp_data[0]['file'] = target.type === 'file' ? target.files : tmp_data[0].file;
		setValues(tmp_data);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;
		if (!inputData[0]['name']) {
			valid += 1;
			invalidData[0]['name'] = 'invalid';
		} else {
			invalidData[0]['name'] = '';
		}
		var emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

		if (!inputData[0]['email'] || !emailRegex.test(inputData[0]['email'])) {
			valid += 1;
			invalidData[0]['email'] = 'invalid';
		} else {
			invalidData[0]['email'] = '';
		}
		if (!inputData[0]['phone'] || !Number.isInteger(parseInt(inputData[0]['phone']))) {
			valid += 1;
			invalidData[0]['phone'] = 'invalid';
		} else {
			invalidData[0]['phone'] = '';
		}
		if (!inputData[0]['address']) {
			valid += 1;
			invalidData[0]['address'] = 'invalid';
		} else {
			invalidData[0]['address'] = '';
		}
		if (!inputData[0]['ref_cities_list_id']) {
			valid += 1;
			invalidData[0]['ref_cities_list_id'] = 'invalid';
		} else {
			invalidData[0]['ref_cities_list_id'] = '';
		}
		if (!inputData[0]['ref_province_list_id']) {
			valid += 1;
			invalidData[0]['ref_province_list_id'] = 'invalid';
		} else {
			invalidData[0]['ref_province_list_id'] = '';
		}
		if (!inputData[0]['ref_district_id']) {
			valid += 1;
			invalidData[0]['ref_district_id'] = 'invalid';
		} else {
			invalidData[0]['ref_district_id'] = '';
		}

		if (!inputData[0]['ref_postcode_id']) {
			valid += 1;
			invalidData[0]['ref_postcode_id'] = 'invalid';
		} else {
			invalidData[0]['ref_postcode_id'] = '';
		}

		if (!inputData[0]['no_rekening']) {
			valid += 1;
			invalidData[0]['no_rekening'] = 'invalid';
		} else {
			invalidData[0]['no_rekening'] = '';
		}

		if (!inputData[0]['nama_rekening']) {
			valid += 1;
			invalidData[0]['nama_rekening'] = 'invalid';
		} else {
			invalidData[0]['nama_rekening'] = '';
		}

		if (!inputData[0]['cabang_bank']) {
			valid += 1;
			invalidData[0]['cabang_bank'] = 'invalid';
		} else {
			invalidData[0]['cabang_bank'] = '';
		}

		if (!inputData[0]['ref_bank_id']) {
			valid += 1;
			invalidData[0]['ref_bank_id'] = 'invalid';
		} else {
			invalidData[0]['ref_bank_id'] = '';
		}
		if (!inputData[0]['geoloc']) {
			valid += 1;
			invalidData[0]['geoloc'] = 'invalid';
		} else {
			invalidData[0]['geoloc'] = '';
		}

		if (valid > 0) {
			let invalidData = [...invalid];
			invalidData[0]['src'] = 'invalid';
			setInvalid(invalidData);
			return false;
		}
		inputData[0]['geoloc'] = JSON.stringify(inputData[0]['geoloc'])

		if (inputData[0]['file']) {
			inputData[0]['file'] = inputData[0]['file'] ? inputData[0]['file'][0] : ''
		} else {
			delete (inputData[0]['file']);
		}
		const result = await updateTodo({ variables: { input: inputData[0] } });
		if (result.data.updateStore.success) {
			toast.success(result.data.updateStore.message)
			return navigate("/profile", { replace: true });
		} else {
			toast.error(result.data.updateStore.message)
		}
	};

	return (
		<Fragment>
			<Tabs>
				<TabList className="nav nav-tabs tab-coupon">
					<Tab className="nav-link">Profil Toko</Tab>
				</TabList>
				<TabPanel>
					<Form className="needs-validation user-add" noValidate="" onSubmit={handleSubmit}>

						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Nama Toko
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="name"
									name="name"
									type="text"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].name}
									invalid={invalid[0].name ? 'invalid' : ''}
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Email
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="email"
									name="email"
									type="email"
									onChange={(e) => handleChange(e)}
									value={values[0].email}
									invalid={invalid[0].email ? 'invalid' : ''}
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Telp
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="phone"
									name="phone"
									type="text"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].phone}
									invalid={invalid[0].phone ? 'invalid' : ''}
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Alamat
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="address"
									name="address"
									type="textarea"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].address}
									invalid={invalid[0].address ? 'invalid' : ''}
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Provinsi
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="ref_province_list_id"
									name="ref_province_list_id"
									type="select"
									required=""
									onChange={(e) => handleChangeSelect(e)}
									value={values[0].ref_province_list_id}
									invalid={invalid[0].ref_province_list_id ? 'invalid' : ''}
								>
									<option value=''>
										Pilih Provinsi
									</option>
									{provinceList.map(e => {
										return (
											<option value={e.id}>
												{e.name}
											</option>
										)
									})}
								</Input>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Kota
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="ref_cities_list_id"
									name="ref_cities_list_id"
									type="select"
									required=""
									onChange={(e) => handleChangeSelect(e)}
									disabled={values[0].ref_province_list_id ? '' : 'disabled'}
									value={values[0].ref_cities_list_id}
									invalid={invalid[0].ref_cities_list_id ? 'invalid' : ''}
								>
									<option value=''>
										Pilih Kota
									</option>
									{cityList && cityList.map(e => {
										return (
											<option value={e.id}>
												{e.name}
											</option>
										)
									})}
								</Input>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>

						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Kecamatan
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="ref_district_id"
									name="ref_district_id"
									type="select"
									required=""
									onChange={(e) => handleChangeSelect(e)}
									disabled={values[0].ref_cities_list_id ? '' : 'disabled'}
									value={values[0].ref_district_id}
									invalid={invalid[0].ref_district_id ? 'invalid' : ''}
								>
									<option value=''>
										Pilih Kecamatan
									</option>
									{districtList && districtList.map(e => {
										return (
											<option value={e.id}>
												{e.name}
											</option>
										)
									})}
								</Input>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>

						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Kodepos
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="ref_postcode_id"
									name="ref_postcode_id"
									type="select"
									required=""
									onChange={(e) => handleChangeSelect(e)}
									disabled={values[0].ref_district_id ? '' : 'disabled'}
									value={values[0].ref_postcode_id}
									invalid={invalid[0].ref_postcode_id ? 'invalid' : ''}
								>
									<option value=''>
										Pilih Kodepos
									</option>
									{postcodeList && postcodeList.map(e => {
										return (
											<option value={e.id}>
												{e.name}
											</option>
										)
									})}
								</Input>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>
						
						<FormGroup className={values[0].ref_postcode_id ? '' : 'd-none'}>
							<Googlemaps className={`${invalid[0].geoloc ? 'error-body' : ''} `} marker={values[0].geoloc} setMarker={setMarker}>
							</Googlemaps>
							<div className={`error-text ${invalid[0].geoloc ? 'd-block' : ''}`} >
								This field is required
							</div>
						</FormGroup>

						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> No Rekening
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="no_rekening"
									name="no_rekening"
									type="text"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].no_rekening}
									invalid={invalid[0].no_rekening ? 'invalid' : ''}
									placeholder="No Rekening"
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>

						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Nama Rekening
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="nama_rekening"
									name="nama_rekening"
									type="text"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].nama_rekening}
									invalid={invalid[0].nama_rekening ? 'invalid' : ''}
									placeholder="Nama Rekening"
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>

						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Nama Bank
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="ref_bank_id"
									name="ref_bank_id"
									type="select"
									required=""
									onChange={(e) => handleChangeSelect(e)}
									value={values[0].ref_bank_id}
									invalid={invalid[0].ref_bank_id ? 'invalid' : ''}
								>
									<option value=''>
										Pilih Bank
									</option>
									{dataBank && dataBank.getBanks.map(e => {
										return (
											<option value={e.id}>
												{e.name}
											</option>
										)
									})}
								</Input>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>

						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Cabang Bank
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="cabang_bank"
									name="cabang_bank"
									type="text"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].cabang_bank}
									invalid={invalid[0].cabang_bank ? 'invalid' : ''}
									placeholder="Cabang Bank"
								/>
								<FormFeedback>
									This field is required
								</FormFeedback>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>*</span> Image
							</Label>
							<div className="col-xl-8 col-md-7">
								{data && data.store ? <div><img src={process.env.REACT_APP_IMAGE_URL + data.store.image} width="150px" /></div> : ''}
								<Input
									className="form-control"
									id="image"
									name="image"
									type="file"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].image ? values[0].image : ''}
									accept="image/png, image/gif, image/jpeg"
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-xl-3 col-md-4">
								<span>&nbsp;&nbsp;</span> Deskripsi
							</Label>
							<div className="col-xl-8 col-md-7">
								<Input
									className="form-control"
									id="description"
									name="description"
									type="textarea"
									required=""
									onChange={(e) => handleChange(e)}
									value={values[0].description}
									invalid={invalid[0].description ? 'invalid' : ''}
								/>
							</div>
						</FormGroup>
						<FormGroup className="row">
							<div className="pull-right">
								<Button type="submit" color="primary">
									Save
								</Button>
							</div>
						</FormGroup>
					</Form>
				</TabPanel>
			</Tabs>
		</Fragment>
	);
};

export default TabsetUser;
