import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { numberFormat } from "../../../helpers/custom";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

const GET_PRODUCTS = gql`
  query products(
    $indexFrom: Int
    $limit: Int
    $store: String
    $category: String
	$title: String
	$price: String
	$status: String
	$sortBy: String
  ) {
    products(
		indexFrom: $indexFrom
		limit: $limit
      	store: $store
      	category: $category
      	title: $title
      	price: $price
		status: $status
		sortBy: $sortBy
    ) {
      total
      hasMore
      items {
        id
        title
        description
        brand
        category
        subcategory
        price
        new
        sale
        stock
        discount
        slug
        status
		sold
		sisa
        variants {
          id
          title
          description
          stock
          price
          image
          weight
        }
        images {
          image_id
          id
          alt
          src
        }
        store {
          name
          slug
          ref_cities_list_name
        }
      }
    }
  }
`;

const columns = [
	{
		dataField: 'id',
		text: '#',
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '5%'};
		  }
	},
	{
		dataField: 'category',
		text: 'Kategori',
		formatter: (cell, row, rowIndex, extraData) => (
			row.category + (row.subcategory ? ' - ' + row.subcategory : '')
		),
		filter: textFilter(),
		sort: true,

	},
	{
		dataField: 'title',
		text: 'Nama',
		filter: textFilter(),
		sort: true,
	},
	{
		dataField: 'stock',
		text: 'Stok',
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '7%'};
		  }
	},
	{
		dataField: 'sold',
		text: 'Terjual',
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '8%'};
		  }
	},
	{
		dataField: 'sisa',
		text: 'Sisa',
		sort: true,
		headerStyle: (colum, colIndex) => {
			return { width: '7%'};
		  }
	},
	{
		dataField: 'price',
		text: 'Harga',
		filter: textFilter(),
		sort: true,
		formatter: (cell, row, rowIndex, extraData) => (
			numberFormat(parseInt(row.price))
		),
		headerStyle: (colum, colIndex) => {
			return { width: '12%'};
		  }
	},
	{
		dataField: 'status',
		text: 'Status',
		filter: selectFilter({
			options: {
				Active: 'Active',
				Inactive: 'Inactive',
			  },
		}),
		sort: true,
		  headerStyle: (colum, colIndex) => {
			  return { width: '12%'};
			}
	},
	{
		dataField: '',
		text: '',
		formatter: (cell, row, rowIndex, extraData) => (
			<div className="openbtn text-center">
				<Link to={`/produk/${row.slug}`} >
					<i className="fa fa-eye" title="Lihat" />
				</Link>
			</div>
		),
		headerStyle: (colum, colIndex) => {
			return { width: '5%'};
		  }
	}
];

const defaultSorted = [{
	dataField: 'id',
	order: 'desc'
}];
const List_vendors = ({slug}) => {
	const sizePerPage = 10;
	var { loading, data, refetch } = useQuery(GET_PRODUCTS, {
		variables: {
			store: slug,
			indexFrom: 0,
			limit: sizePerPage,
		},
	});
	const handleTableChange = (e, { filters, page, sizePerPage, sortField, sortOrder, data }) => {
		refetch({
				store: slug,
				indexFrom: (page*sizePerPage) - sizePerPage,
				limit: sizePerPage,
				category: filters && filters.category? filters.category.filterVal: '',
				title: filters && filters.title? filters.title.filterVal: '',
				price: filters && filters.price? filters.price.filterVal: '',
				status: filters && filters.status? filters.status.filterVal: '',
				sortBy: sortField+'-'+sortOrder
		})
	}
	return (
		<>
				<h4><b>Product</b></h4>
			<BootstrapTable
				keyField='id'
				hover
				condensed
				remote={{ sort: true, filter: true, pagination: true }}
				data={data && data.products ? data.products.items : []}
				columns={columns}
				bordered={false}
				noDataIndication="Table is Empty"
				pagination={paginationFactory(
					{
						sizePerPage: sizePerPage,
						hideSizePerPage: true,
						hidePageListOnlyOnePage: true,
						totalSize: data && data.products ? data.products.total: 0
					})}
				filter={filterFactory()}
				filterPosition="top"
				defaultSorted={defaultSorted}
				onTableChange={handleTableChange}
			/>
		</>
	);
};

export default List_vendors;
