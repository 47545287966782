/* eslint-disable valid-typeof */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Button, Form, FormGroup, Input, Label, FormFeedback, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { numberFormat } from "../../helpers/custom";
import ModalProducts from './common/modal-products'

const GET_INFO = gql`
  query ($id: Int!) {
	getCheckoutLink(id: $id) {
		id
		title
		status
		created_date
		items {	
			id
			checkout_link_id
			products_id
			products_title
			products_price
			products_img
			products_variant_id
			products_variant_title
			products_variant_price
			products_variant_img
			qty
		}
	}
  }
`;

const CREATE_DATA = gql`
  mutation ($input: CheckoutLinkInput) {
    createCheckoutLink(input: $input) {
        success,
        message,
		id
    }
  }
`;

const UPDATE_DATA = gql`
  mutation ($input: CheckoutLinkInput) {
    updateCheckoutLink(input: $input) {
        success,
        message,
    }
  }
`;

const TabsetUser = () => {
	let { id } = useParams();
	let navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('user'));
	const [openModal, setOpenModal] = useState();
	const [productKey, setProductKey] = useState();
	const [productValues, setProductValues] = useState([]);
	const [delProductValues, setDelProductValues] = useState([]);
	const [total, setTotal] = useState(0);
	const [values, setValues] = useState([{
		id: 0,
		title: '',
		status: 'active',
	}]);
	const [invalid, setInvalid] = useState([{
		title: '',
		status: '',
		product: '',
	}]);
	const [addTodo] = useMutation(CREATE_DATA);
	const [updateTodo] = useMutation(UPDATE_DATA);
	var { data } = useQuery(GET_INFO, {
		variables: {
			id: id > 0 ? parseInt(id) : parseInt(0),
		},
	});

	useEffect(() => {
		if (data && data.getCheckoutLink) {
			let tmp_values = [...values];
			tmp_values[0] = {
				id: parseInt(id),
				title: data.getCheckoutLink.title,
				status: data.getCheckoutLink.status,
			}
			handleGenerateProduct(data.getCheckoutLink.items);
			setValues(tmp_values);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	useEffect(() => {
		if (productValues) {
			let total = 0;
			let productValue = productValues;

			productValue.forEach((e, key) => {
				total += e.qty * parseInt(e.products_variant_price ? e.products_variant_price : e.products_price);
				/*productValue[key].total = e.qty * parseInt(e.products_variant_price ? e.products_variant_price : e.products_price);
				productValue[key].products_price = parseInt(e.products_price);
				productValue[key].products_variant_price = parseInt(e.products_variant_price);
				productValue[key].qty = parseInt(e.qty);*/
				//delete (productValue[key].__typename)
			})
			setTotal(total);
		}
	}, [productValues])

	const handleGenerateProduct = (data) => {
		let total = 0;
		data.forEach((e, key) => {
			total += e.qty * parseInt(e.products_variant_price ? e.products_variant_price : e.products_price);
			data[key].total = e.qty * parseInt(e.products_variant_price ? e.products_variant_price : e.products_price);
			data[key].products_price = parseInt(e.products_price);
			data[key].products_variant_price = parseInt(e.products_variant_price);
			data[key].qty = parseInt(e.qty);
			delete (data[key].__typename)
		})
		setProductValues(data);
		setTotal(total);
	};

	const handleDeleteProduct = (event) => {
		let returnData = [];
		let deleteData = [];
		productValues.forEach((e, key) => {
			if (key !== event) {
				returnData.push(e);
			} else {
				deleteData.push(e);
			}
		})
		setProductValues(returnData);
		setDelProductValues(deleteData);
	};
	const handleChange = (event) => {
		let tmp_data = [...values];
		const { target } = event;
		const value = target.value;
		const { name } = target;
		tmp_data[0][name] = value;
		setValues(tmp_data);
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		const inputData = values;
		let invalidData = [...invalid];
		let valid = 0;
		if (!inputData[0]['title']) {
			valid += 1;
			invalidData[0]['title'] = 'invalid';
		} else {
			invalidData[0]['title'] = '';
		}
		if (productValues.length > 0) {
			invalidData[0]['product'] = '';
		} else {
			valid += 1;
			invalidData[0]['product'] = 'invalid';
		}
		if (valid > 0) {
			let invalidData = [...invalid];
			setInvalid(invalidData);
			return false;
		}
		inputData[0].uid = user.id;
		inputData[0].items = productValues;
		inputData[0].items_del = delProductValues;
		if (inputData[0].id > 0) {
			const result = await updateTodo({ variables: { input: inputData[0] } });
			if (result.data.updateCheckoutLink.success) {
				toast.success(result.data.updateCheckoutLink.message)
				return navigate("../checkout-link", { replace: true });
			} else {
				toast.error(result.data.updateCheckoutLink.message)
			}
		} else {
			const result = await addTodo({ variables: { input: inputData[0] } });
			if (result.data.createCheckoutLink.success) {
				toast.success(result.data.createCheckoutLink.message)
				return navigate("../checkout-link", { replace: true });
			} else {
				toast.error(result.data.createCheckoutLink.message)
			}
		}
	};

	const handleOpenModal = (event) => {
		setProductKey(event)
		setOpenModal(true);
	};

	return (
		<Fragment>
			<Breadcrumb title='Checkout Link Form' parent="Checkout Link" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardBody>
								<Form className="needs-validation user-add" noValidate="" onSubmit={handleSubmit}>
									<FormGroup className="row">
										<Label className="col-xl-3 col-md-4">
											Title
										</Label>
										<div className="col-xl-8 col-md-7">
											<Input
												className="form-control"
												id="title"
												name="title"
												type="text"
												required=""
												onChange={(e) => handleChange(e)}
												value={values[0].title}
												invalid={invalid[0].title ? 'invalid' : ''}
											/>
											<FormFeedback>
												This field is required
											</FormFeedback>
										</div>
									</FormGroup>
									<FormGroup className="row">
										<div className="d-flex justify-content-between">
											<Label className="col-xl-3 col-md-4">
												Product List
											</Label>
											<Link onClick={() => handleOpenModal(0)} className="btn btn-primary btn-sm" >
												Add
											</Link>
										</div>
										<div className="px-2">
											<table className="w-100">
												<thead className="border-bottom fw-bold">
													<td className="col-4">Product</td>
													<td className="col-3">Qty</td>
													<td className="col-3">Price</td>
													<td className="col-3">Total</td>
													<td className="col"></td>
												</thead>
												<tbody>
													{productValues && productValues.map((e, key) => {
														return (
															<tr className="border-bottom">
																<td className="p-2 d-flex">
																	<img className="pr-2" style={{ height: "100px", width: 'auto', marginRight: '1rem' }} src={`${process.env.REACT_APP_IMAGE_URL}${e.products_variant_img ? e.products_variant_img : e.products_img}`} alt="" width="150px" />
																	<div className="my-auto">
																		{e.products_title}
																		<br />
																		{e.products_variant_title}
																	</div>
																</td>
																<td>{parseInt(e.qty)}</td>
																<td>{numberFormat(parseInt(e.products_variant_price ? e.products_variant_price : e.products_price))}</td>
																<td>{numberFormat(parseInt(e.qty) * parseInt(e.products_variant_price ? e.products_variant_price : e.products_price))}</td>
																<td className="w-10">
																	<div className="d-flex ">
																		<div className="openbtn text-center" onClick={() => handleOpenModal(key + 1)}>
																			<Link  >
																				<i className="fa fa-edit fs-5" />
																			</Link>
																		</div>
																		<div className="openbtn text-center px-2 " onClick={() => handleDeleteProduct(key)}>
																			<Link  >
																				<i className="fa fa-remove fs-5" />
																			</Link>
																		</div>
																	</div>
																</td>
															</tr>
														)
													})}
												</tbody>
												<tfoot className="border-bottom fw-bold">
													<tr>
														<td colspan="3" className="text-end py-2 pe-4">Total</td>
														<td >{numberFormat(parseInt(total))}</td>
														<td ></td>
													</tr>
												</tfoot>
											</table>
											<div className={`invalid-feedback ${invalid[0].product ? 'd-block':'d-hide'}`}>
												This field is required
											</div>
										</div>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-xl-3 col-md-4">
											Status
										</Label>
										<div className="col-xl-8 col-md-7">
											<Input
												className="form-control"
												id="status"
												name="status"
												type="select"
												required=""
												onChange={(e) => handleChange(e)}
												value={values[0].status}
												invalid={invalid[0].status ? 'invalid' : ''}
											>
												<option value="Active">
													Active
												</option>
												<option value="Inactive">
													Inactive
												</option>
											</Input>
											<FormFeedback>
												This field is required
											</FormFeedback>
										</div>
									</FormGroup>
									<FormGroup className="row">
										<div className="pull-right">
											<Button type="submit" color="primary">
												Save
											</Button>
										</div>
									</FormGroup>
								</Form>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			<ModalProducts productKey={productKey} setProductKey={setProductKey} openModal={openModal} setOpenModal={setOpenModal} productValues={productValues} setProductValues={setProductValues} />
		</Fragment>
	);
};

export default TabsetUser;
